import React, { useState, useCallback, useEffect, useRef } from "react";
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { Button, Form, FormLabel, Modal, Spinner, Tooltip, OverlayTrigger, ProgressBar } from "react-bootstrap";
import imgDefault from '../assets/Avatar.png';
import { useDropzone } from 'react-dropzone';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import AWS from 'aws-sdk';
import axios from 'axios';
import moment from 'moment';
import { Rating } from 'react-simple-star-rating'
import { Loader } from '../components/loader';
import { UserAuth } from '../context/AuthContext';
import { getAuth } from '../firebaseConfig';
import CryptoJS from 'crypto-js';
import searchIcon from '../assets/search.svg'
import ProfileCreated from '../cards/ProfileCreatedCard';
import InterviewScheduledcard from '../cards/InterviewScheduledCard';
import InterviewStartedcard from '../cards/InterviewStartedCard';
import Statuscard from "../cards/StatusCard";
// import ResultCard from '../cards/ResultCard';
import { useMyContext } from '../context/MyContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Avatar from '../assets/Avatar.png';
import Pagination from "../components/Pagination";
import { FFmpeg } from '@ffmpeg/ffmpeg';

export const MyRequisitionDetails = () => {
    const ffmpeg = new FFmpeg({ log: true });
    const { state } = useLocation();
    const navigate = useNavigate();
    const auth = getAuth();
    const { user, userInfoLoaded, currentUserVal } = UserAuth();
    const [scheduleClient, setScheduleClient] = useState([]);
    const [rating, setRating] = useState(0);
    const [culturalRating, setCulturalRating] = useState(0)
    const location = useLocation();
    const [decryptedData, setDecryptedData] = useState('');
    const [requisitionCandidates, setRequisitionCandidates] = useState([]);
    const [requisitionCards, setRequisitionCards] = useState([]);
    const [requisitionCardcount, setRequisitionCardcount] = useState('');
    const [requisitionDetailsCount, setRequisitionDetailsCount] = useState([]);
    const [cards, setMemberCards] = useState([]);
    const { values, handleChange } = useMyContext()
    const [searchValData, setSearchValdata] = useState(values.getReqDetailsSearch);
    const [registrationStatus, setRegistrationStatus] = useState(values.getReqDetailsStatus);
    const [gethrpics, sethrpics] = useState(values.getArrayValue)
    const [searchVal, setSearchVal] = useState('')
    const [changeCard, setChangeCard] = useState('')
    const [getaudiofileName, setaudiofileName] = useState(null)
    const [getaudiofileUrl, setaudiofileUrl] = useState(null)
    const [getaudiofileData, setaudiofileData] = useState(null)
    const [getAudioError, setAudioError] = useState(null)
    const [getackChecked, setackChecked] = useState(false);
    const [getJobstatus, setJobstatus] = useState('')
    // Initial
    const initialFormData = {
        user_name: '',
        email: '',
        contact_no: '',
        current_company: '',
        interview_date: '',
        interview_time: '',
        other_details: '',
        resume_url: null,
        added_by_email: '',
        added_by_name: '',
        job_status: '',
        notice_period: '',
        current_ctc: '',
        expected_ctc: '',
        experience: '',
        source_of_candidate: '',
        is_notice_buyout: '',
        job_role: '',
        reason_job_change: '',
        audio_note: '',
        salaryNegotiation: '',
        candidate_ques: '',
        interview_medium: '',
        technical_round: 0,
        one2one_round: 0,
        cultural_fit: '',
        request_id: '',
        requisition_id: '',
        relevant_years: '',
        relevant_months: '',
        total_exp_years: '',
        total_exp_months: '',
        current_ctc_scale: '',
        expected_ctc_scale: '',
        interaction_type: ''
    };
    // Initial
    const [formData, setFormData] = useState(initialFormData);
    const resetForm = () => {
        setFormData(initialFormData);  // Reset the state to its initial values
    };
    //     user_name: '',
    //     email: '',
    //     contact_no: '',
    //     current_company: '',
    //     interview_date: '',
    //     interview_time: '',
    //     other_details: '',
    //     resume_url: null,
    //     added_by_email: '',
    //     added_by_name: '',
    //     job_status: '',
    //     notice_period: '',
    //     current_ctc: '',
    //     expected_ctc: '',
    //     experience: '',
    //     source_of_candidate: '',
    //     is_notice_buyout: '',
    //     job_role: '',
    //     reason_job_change: '',
    //     audio_note: '',
    //     salaryNegotiation: '',
    //     candidate_ques: '',
    //     interview_medium: '',
    //     technical_round: 0,
    //     one2one_round: 0,
    //     cultural_fit: '',
    //     request_id: '',
    //     requisition_id: '',
    //     relevant_years: '',
    //     relevant_months: '',
    //     total_exp_years: '',
    //     total_exp_months: '',
    //     current_ctc_scale: '',
    //     expected_ctc_scale: '',
    //     interaction_type: ''
    // });

    // State to track condition checkbox to enable submit button
    const [isChecked, setIsChecked] = useState(false);
    // Handle checkbox change
    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked); // Update the state based on checkbox status
    };

    // React Bootstrap Model
    const [show, setShow] = useState(false);
    const [file, setFile] = useState(null);
    const acceptedFileTypes = ['docx', 'pdf', 'doc'];
    const [error, setError] = useState('');
    const [roundError, setRoundError] = useState('');

    const [partialloading, setPartialloading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [phone, setPhone] = useState("");
    const [isDisable, setDisable] = useState(true);
    const [checkMessage, setMessage] = useState("");
    const [otherVisibility, setOtherVisibility] = useState(true);
    const [isProgress, setisProgress] = useState(false);
    const [isChecking, setisChecking] = useState(false);
    const [storedVal, setStoredVal] = useState('')
    const [cardtype, setCardtype] = useState([])

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const itemsPerPage = 10;
    const [pagination, setPagination] = useState({});

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [isDateSet, setDateVal] = useState(false);
    const [debouncedSearchVal, setDebouncedSearchVal] = useState("");

    const [reqallvalues, setReqallvalues] = useState(null)
    useEffect(() => {

        // Extract encrypted data from URL
        const searchParams = new URLSearchParams(location.search);
        const encryptedData = searchParams.get('query');

        if (encryptedData) {
            // Decrypt the encrypted data
            const bytes = CryptoJS.AES.decrypt(encryptedData, 'secret_key');
            const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            // console.log(decryptedData)
            // console.log("encryptedDataghgh")
            filterList()
            setReqallvalues(decryptedData)
            setDecryptedData(decryptedData?.requisitionId);
            fetchRequisitionCandidates(decryptedData?.requisitionId);
            requisitionData(decryptedData?.requisitionId)
            if (((values.optionValdata == '') || (values.optionValdata == undefined) || (values.optionValdata == null)) && (values.detailId != decryptedData?.requisitionId)) {
                requisitionCandidateListing(decryptedData?.requisitionId)
                setStoredVal(' ')
            } else {
                // const optionVal = values.optionValdata
                // // console.log(optionVal)
                // onchangeFilter(optionVal, decryptedData)
                // setStoredVal(values.optionValdata)
                requisitionCandidateListing(decryptedData?.requisitionId)
            }

        }
    }, [location.search, currentUserVal, currentPage, startDate, endDate, registrationStatus, searchValData]);

    // getFilterOption
    const filterList = async () => {
        const refreshedToken = await currentUserVal?.getIdToken(true);
        const datasend = {
            dataget: "dataget"
        }
        var headers = {
            'Authorization': `Bearer ${refreshedToken}`,
            'Content-Type': 'application/json'
        };
        axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/requisitions/filter-options`, datasend, { headers })
            .then(function (response) {
                let dashDatares = response.data;
                let data = dashDatares.data ? dashDatares.data : {};
                setCardtype(dashDatares?.requisitionCandidateStatusOptions);
            }).catch(function (error) {
                // console.log(error);
                setIsLoading(false);
            });
    }
    // getFilterOption

    // fetchCandidate
    const fetchRequisitionCandidates = async (requisitionId, searchStr = "", resulttypeData = "", interviewStatus = "") => {
        try {
            setIsLoading(true);
            const refreshedToken = await currentUserVal?.getIdToken(true);
            let postData = {
                fromDate: startDate,
                toDate: endDate,
                searchContacts: searchStr != "" ? searchStr : "",
                test_type: "",
                page: currentPage,
                pageSize: itemsPerPage,
                requisitionId: requisitionId.toString(),
                //searchString: requisitionId.toString(),
                searchString: searchStr != "" ? searchStr : searchValData,
                // is_searchByRequisition: '1',
                is_registered: registrationStatus,
                result_status: resulttypeData,
                interview_status: interviewStatus
            };
            if (user?.userType == "HR") {
                postData.userType = "HR";
                postData.eMailId = user?.email;
            }
            if (user?.userType == "MGR" || user?.userType == "SMGR") {
                postData.userType = user?.userType;
                postData.managerEmail = user?.email;
            }
            // console.log(postData)
            // console.log("dataatatta")
            var headers = {
                'Authorization': `Bearer ${refreshedToken}`,
                'Content-Type': 'application/json'
            };
            // const response = await axios.post(`https://dev.virtualemployee.com/admin-panel/api/v3.0/requisitions/requisition-candidates-list`, postData, { headers })
            const response = await axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/candidates/get-schdule-candidate-list-new`, postData, { headers })
            // console.log(response.data)
            // console.log("response.data66")
            // setRequisitionCandidates(response.data?.requisitionCandidates);
            setRequisitionCandidates(response.data?.list)
            // console.log("response.data?.requisitionCandidates")
            setTotalPages(response.data?.totalPage)
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching requisitions:', error);
            setIsLoading(false);
            setRequisitionCandidates([]);
        }
    };
    // fetchCandidates

    // requisitionCandidateListing
    const requisitionCandidateListing = async (requisitionId) => {
        try {
            setIsLoading(true);
            const refreshedToken = await currentUserVal?.getIdToken(true);
            let postData = {
                requisitionId: requisitionId,
                page: currentPage,
                limit: itemsPerPage,
                isFilter: changeCard != '' ? true : false,
                filterOption: changeCard != '' ? changeCard : "Interview Scheduled | Profile Created | Interview Started"
            };
            if (user?.userType == "HR")
                postData.userType = "HR";
            var headers = {
                'Authorization': `Bearer ${refreshedToken}`,
                'Content-Type': 'application/json'
            };
            const response = await axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/requisitions/get-requisition-member-cards-by-id`, postData, { headers })
            setRequisitionCards(response.data?.memberCards);
            setRequisitionCardcount(response.data?.counts);
            setMemberCards(response?.data)
            // setTotalPages(response?.data.pagination?.totalPage)
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching requisitions:', error);
            setIsLoading(false);
            setRequisitionCards([]);
        }
    };

    // requisition details
    const requisitionData = async (requisitionId) => {
        try {
            setIsLoading(true);
            const refreshedToken = await currentUserVal?.getIdToken(true);
            let postData = {
                requisitionId: requisitionId,
                userType: user?.userType
            };

            var headers = {
                'Authorization': `Bearer ${refreshedToken}`,
                'Content-Type': 'application/json'
            };
            const response = await axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/requisitions/requisition-details-count-new`, postData, { headers })
            // const response = await axios.post(`https://dev.virtualemployee.com/admin-panel/api/v3.0/requisitions/requisition-details-count`, postData, { headers })
            // console.log("My Requisition count");
            // console.log(response.data);
            const ReqCount = response?.data;
            setRequisitionDetailsCount(response.data ? ReqCount : []);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching requisitions:', error);
            setIsLoading(false);
            setRequisitionDetailsCount([]);
        }
    }

    const handleRating = (rate) => {
        setRating(rate)
    }

    const handleCulturalRating = (rate) => {
        setCulturalRating(rate)
    }

    // For model
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // Alert Notification Modal
    const [showAlert, setShowAlert] = useState(true);

    const handleDrop = useCallback((acceptedFiles) => {
        setError("");
        const selectedFile = acceptedFiles[0];
        if (!acceptedFileTypes.includes(selectedFile.name.split('.').pop())) {
            setError(`File ${selectedFile.name} has an invalid file type. Only ${acceptedFileTypes.join(', ')} are accepted.`);
            return;
        }
        setFile(selectedFile);
    }, []);

    const handleaudioDrop = useCallback((acceptedFiles) => {
        console.log(acceptedFiles[0])
        console.log("acceptedFiles[0]")
        setError("");
        const selectedFile = acceptedFiles[0];
        setFile(selectedFile);
    }, [])

    const [errors, setErrors] = useState({
        expected_ctc: '',
        current_ctc: '',
    });
    const handleInputChange = (e) => {
        setJobstatus(e.target.value)
        // Error message state
        let newErrors = { ...errors };
        const { name, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? (checked ? 1 : 0) : value;
        // Check if the input field is "expected_ctc" and if the value is numeric
        if (name == 'expected_ctc') {
            // Check if the value is numeric (using parseFloat for decimal numbers)
            if (isNaN(newValue) || newValue.trim() == '') {
                newErrors.expected_ctc = 'Please enter a valid numeric value.';
                // newValue = ''; // Reset to empty if not valid
            } else {
                newErrors.expected_ctc = ''; // Clear error if valid
            }
        }
        if (name == 'current_ctc') {
            // Check if the value is numeric (using parseFloat for decimal numbers)
            if (isNaN(newValue) || newValue.trim() == '') {
                newErrors.current_ctc = 'Please enter a valid numeric value.';
                // newValue = ''; // Reset to empty if not valid
            } else {
                newErrors.current_ctc = ''; // Clear error if valid
            }
        }
        setFormData({ ...formData, [name]: newValue });
        setErrors(newErrors);
        // setRoundError("");
    };

    //Upload resume Function
    const uploadResume = async () => {
        if (file) {
            setisProgress(true);
            const fileVal = file || {};
            const fileName = file?.name;
            const fileType = file?.type;
            try {
                // Get the Firebase authentication token
                const refreshedToken = await currentUserVal?.getIdToken(true);
                // Include the token in the request headers
                const headers = {
                    'Authorization': `Bearer ${refreshedToken}`,
                    'Content-Type': 'multipart/form-data', // Set content type to form data
                };
                const formData = new FormData();
                formData.append('file', fileVal);
                formData.append('bucketName', 'interviewer-profile');
                formData.append('key', `ResumeByHR/${fileName}`);
                formData.append('contentType', fileType);
                formData.append('isEncoding', false);
                formData.append('ContentEncoding', '');
                const response = await axios.post(`https://dev.virtualemployee.com/admin-panel/api/v3.0/s3/upload`, formData, { headers });
                // const response = await axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/s3/upload`, formData, { headers });
                // console.log(response);
                setisProgress(false);
                return response.data.uploadResponse;
            } catch (error) {
                console.error(error);
                setisProgress(false);
            }
        }
        else {
            setisProgress(false);
            setError("Please select appropriate file type .");
            return;
        }
    };

    // Handle form submission (upload)
    const handleUploadaudiofile = async (audioFile) => {
        if (!audioFile) {
            setError('Please select an audio file.');
            return;
        }
        const fileName = audioFile?.name;
        const fileType = audioFile?.type;
        const currentDateTime = moment().format('YYYY-MM-DDHH:mm:ss');
        const reqId = reqallvalues?.requisitionId
        // setisProgress(true);
        try {
            const refreshedToken = await currentUserVal?.getIdToken(true);
            const headers = {
                'Authorization': `Bearer ${refreshedToken}`,
                'Content-Type': 'multipart/form-data',
            };
            const formData = new FormData();
            formData.append('file', audioFile);
            formData.append('bucketName', 'candidate-calls');
            formData.append('key', `audioFile/${user?.uid + reqId + currentDateTime + fileName}`);
            formData.append('contentType', fileType);
            formData.append('isEncoding', false);
            formData.append('ContentEncoding', '');
            const response = await axios.post(`https://dev.virtualemployee.com/admin-panel/api/v3.0/s3/upload`, formData, { headers });
            return response.data.uploadResponse;
            // setisProgress(false);
        } catch (err) {
            // setisProgress(false);
            console.error('Upload error:', err);
        }
        // finally {
        //     // setUploading(false);
        // }
    };
    // AudioUpload

    //Check email
    const checkEmail = async () => {
        const getEmailValue = formData?.email
        if (getEmailValue == "") {
            setOtherVisibility(true)
            return
        } else {
            const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
            if (!emailPattern.test(getEmailValue) == false) {
                setOtherVisibility(!emailPattern.test(getEmailValue))
            }
            else {
                setOtherVisibility(!emailPattern.test(getEmailValue))
                return
            }
        }
        try {
            setisChecking(true);
            const refreshedToken = await currentUserVal?.getIdToken(true);
            const data = JSON.stringify({
                "operation": "checkcandidate",
                "checkby": "email",
                "email": formData?.email
            });
            const headers = {
                'Authorization': `Bearer ${refreshedToken}`,
                'Content-Type': 'application/json'
            };
            // Make a POST request to the API endpoint
            const response = await axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/candidates/check-candidate`, data, { headers });
            let isuserExist = response.data?.data;
            // console.log(response.data?.isExist)
            if (response.data?.isExist == "1") {
                setisChecking(false);
                // const errorMsg = `Added by ${response.data?.added_by} on ${moment(response.data?.created).utc().format('YYYY-MM-DD')}`;
                const errorMsg = `The candidate with this email ID already exists.`;
                setOtherVisibility(true);
                toast.error(errorMsg, {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                return
            }
            else {
                setOtherVisibility(false);
                setMessage("");
                setisChecking(false);
            }
        } catch (error) {
            // Handle errors that occurred during the request
            // console.log(error);
            setisChecking(false);

            // You might want to throw the error or handle it differently based on your needs
            throw error;
        }
    }

    //Check contact Number exist or Not
    const checkCandidateContact = async (contact_no) => {
        try {
            setisChecking(true);
            const refreshedToken = await currentUserVal?.getIdToken(true);
            const data = JSON.stringify({
                "operation": "checkcandidate",
                "checkby": "number",
                "contact_no": contact_no
            });
            const headers = {
                'Authorization': `Bearer ${refreshedToken}`,
                'Content-Type': 'application/json'
            };

            // Make a POST request to the API endpoint
            const response = await axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/candidates/check-candidate`, data, { headers });
            setisChecking(false);
            // return response.data?.data;
            return response?.data;
        } catch (error) {
            setisChecking(false);
            throw error;
        }
    };

    const handleOnChangeNumber = (contact_val, country) => {
        const phoneInput = document.querySelector('.react-tel-input input');
        const parsedNumber = phoneInput?.value;
        setPhone(contact_val);
        if (parsedNumber) {
            const cleanNumber = parsedNumber.replace(/[\s()-]/g, '');
            const localNumber = cleanNumber.replace(`+${country.dialCode}`, '');
            // console.log('Local Number:', localNumber);
            if (localNumber?.length == 10) {
                checkCandidateContact(contact_val)
                    .then((response) => {
                        console.log("isValid:", response);
                        if (response?.isExist == 1) {
                            // setMessage(`Added by ${response?.added_by} on ${moment(response?.created).utc().format('YYYY-MM-DD')}`);
                            const errorMsg = `The candidate with this Phone number already exists.`;
                            setOtherVisibility(true);
                            toast.error(errorMsg, {
                                position: toast.POSITION.BOTTOM_CENTER
                            });
                            setDisable(true);
                        }
                        else {
                            setDisable(false);
                            setMessage("");
                        }
                        // Do something with the result
                    })
                    .catch((error) => {
                        console.error("Error checking candidate contact:", error);
                        // Handle the error
                    });
            }
        }


    };

    //Add Candidate Status working
    const addCandidateStatus = async () => {
        try {
            const refreshedToken = await currentUserVal?.getIdToken(true);
            let postData = {
                requisitionId: reqallvalues?.requisitionId,
                name: formData?.user_name,
                email: formData?.email,
                type: "candidate",
                status: "Candidate Added",
                note: `${formData?.user_name} has been added by ${user?.displayName} on ${moment().format('YYYY-MM-DD')} for the requisition.`,
                addedBy: user?.displayName,
                addedByEmail: user?.email,
                userType: user?.userType
            };
            var headers = {
                'Authorization': `Bearer ${refreshedToken}`,
                'Content-Type': 'application/json'
            };
            const response = await axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/requisitions/add-status`, postData, { headers })
            // console.log("Requisition details");
            // console.log(response.data);
            setIsLoading(false);
            toast.success('Candidate informations have been saved successfully.', {
                position: toast.POSITION.BOTTOM_CENTER
            });
        } catch (error) {
            console.error('Error fetching requisitions:', error);
            setIsLoading(false);
        }
    }

    //Submit Add Candidate Form
    // new add
    const handleSubmit = async (e) => {
        e.preventDefault();
        let candidateData = formData;
        if ((getaudiofileUrl == "" || getaudiofileUrl == null) && (audioDuration == "" || audioDuration == null)) {
            // setRoundError("Please select Audio.");
            return false;
        }
        // return
        if (candidateData?.technical_round == 0 && candidateData?.one2one_round == 0 && formData.interview_medium != 'In Person') {
            setRoundError("Please select Interview Rounds.");
            return false;
        }
        // // interviewRound
        // candidateData.technical_round = parseInt(candidateData.technical_round, 10);
        // candidateData.one2one_round = parseInt(candidateData.one2one_round, 10);
        // interviewRound
        candidateData.added_by_email = user?.email;
        candidateData.added_by_name = user?.displayName;
        candidateData.added_by_id = user?.uid;
        candidateData.hrType = user?.hrType;
        candidateData.contact_no = phone;
        candidateData.comm_skill_rating = rating;
        candidateData.cultural_fit_rating = culturalRating;
        candidateData.duration = audioDuration;
        candidateData.audio_url = getaudiofileUrl;
        candidateData.experience = formData?.total_exp_years + '.' + formData?.total_exp_months;
        candidateData.relevant_experience = formData?.relevant_years + '.' + formData?.relevant_months;
        candidateData.current_ctc = formData?.current_ctc.trim() + " " + formData?.current_ctc_scale;
        candidateData.expected_ctc = formData?.expected_ctc.trim() + " " + formData?.expected_ctc_scale;
        candidateData.technical_round = formData.interview_medium == 'In Person' ? 0 : formData?.technical_round;
        candidateData.one2one_round = formData.interview_medium == 'In Person' ? 0 : formData?.one2one_round;
        if (reqallvalues?.requisitionId != "") {
            candidateData.request_id = reqallvalues?.requisitionId;
            candidateData.requisition_id = reqallvalues?.requisitionId;
        }
        if (file) {
            setisProgress(true);
            const url = await uploadResume();
            // console.log(url);
            candidateData.resume_url = url;
            setFormData(candidateData);
            const refreshedToken = await currentUserVal?.getIdToken(true);
            candidateData.operation = "addcandidate";
            var data = JSON.stringify(candidateData);
            // console.log(data)
            var headers = {
                'Authorization': `Bearer ${refreshedToken}`,
                'Content-Type': 'application/json'
            };
            axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/candidates/add-candidate`, data, { headers })
                .then(function (response) {
                    setIsLoading(false);
                    setisProgress(false);
                    if (response?.data?.statusCode == 200) {
                        addCandidateStatus();
                        fetchRequisitionCandidates(reqallvalues?.requisitionId);
                        requisitionCandidateListing(reqallvalues?.requisitionId)
                        handleClose();
                        resetForm();
                        setPhone('')
                        setFile(null)
                        setaudiofileName(null)
                        setackChecked(false)
                    }
                    else {
                        toast.error(response?.data?.errorMessage, {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                    }
                }).catch(function (error) {
                    // console.log(error);
                    setisProgress(false);
                    setIsLoading(false);
                });
        }
        else {
            // console.log("formdata");
            // console.log(candidateData);
            setIsLoading(false);
            setisProgress(false);
            setError("Please select candidate Resume.");
        }
    };
    // new add
    //Submit Add Candidate Form

    function Dropzone({ onDrop }) {
        const onDropCallback = useCallback((acceptedFiles) => {
            onDrop(acceptedFiles);
        }, [onDrop]);

        const { getRootProps, getInputProps, isDragActive } = useDropzone({
            onDrop: onDropCallback,
            accept: acceptedFileTypes.join(','),
        });

        return (
            <div {...getRootProps()}>
                <input {...getInputProps()} />
                {
                    isDragActive ?
                        <p className="fw-medium">Drop the files here ...</p> :
                        <div className='text-muted'>
                            <p className="fw-medium mb-2">Drop your candidate resume here or Browse.<br /> File should be (DOCX, PDF, DOC ) only</p>
                            <button className='btn btn-sm btn-success rounded-pill px-3'>Browse File</button>
                        </div>
                }
            </div>
        );
    }
    const getFileExtension = (file) => {
        const fileName = file.name;
        return fileName.split('.').pop().toLowerCase();
    };

    // convert amr
    const convertAMRtoMP3 = async (amrFile) => {
        if (!(amrFile instanceof File || amrFile instanceof Blob)) {
            console.error('The provided file is not a valid Blob or File.');
            return;
        }

        await ffmpeg.load(); // Load ffmpeg.wasm
        const fileName = 'input.amr';
        const outputFileName = 'output.mp3';

        // Write AMR file to virtual file system
        ffmpeg.FS('writeFile', fileName, await readFileAsArrayBuffer(amrFile));

        // Run the conversion (AMR to MP3)
        await ffmpeg.run('-i', fileName, outputFileName);

        // Read the MP3 file from the virtual file system
        const mp3Data = ffmpeg.FS('readFile', outputFileName);

        // Create a URL for the converted MP3 file
        const mp3Blob = new Blob([mp3Data.buffer], { type: 'audio/mp3' });
        const mp3Url = URL.createObjectURL(mp3Blob);
        return mp3Url
    };
    const readFileAsArrayBuffer = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            // Make sure we are passing a valid Blob or File
            if (file instanceof Blob) {
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsArrayBuffer(file);
            } else {
                reject(new Error('The provided file is not a valid Blob or File.'));
            }
        });
    };
    // convert amr

    // checkfiletoupload
    const [getuploadingfile, setuploadingfile] = useState(false);
    const [gethideuploadfile, sethideuploadfile] = useState(false);
    const checkfiletoupload = async (audioFile) => {
        setAudioError('')
        if (!audioFile) {
            setError('Please select an audio file.');
            return;
        }
        // getProgressBar
        return new Promise((resolve, reject) => {
            const fileName = audioFile?.name;
            const fileType = audioFile?.type;
            const currentDateTime = moment().format('YYYY-MM-DDHH:mm:ss');
            const reqId = reqallvalues?.requisitionId
            // try {
            sethideuploadfile(true)
            setuploadingfile(true)
            const refreshedToken = currentUserVal?.getIdToken(true);
            const headers = {
                'Authorization': `Bearer ${refreshedToken}`,
                'Content-Type': 'multipart/form-data',
            };
            const formData = new FormData();
            formData.append('file', audioFile);
            formData.append('bucketName', 'candidate-calls');
            formData.append('contentType', fileType);
            formData.append('isEncoding', false);
            formData.append('ContentEncoding', '');
            formData.append('directories', 'audioFile');
            formData.append('filePrefix', '');
            const xhr = new XMLHttpRequest();
            xhr.open('POST', `${process.env.REACT_APP_AUDIOUPLOAD_API}/v3.0/audio/uploadAudio`);
            xhr.setRequestHeader('Authorization', `Bearer ${refreshedToken}`);
            // Track progress
            xhr.upload.addEventListener('progress', (event) => {
                if (event.lengthComputable) {
                    const progress = (event.loaded / event.total) * 100;
                    setUploadProgress(progress);
                }
            });
            // Handle when upload completes
            xhr.onload = () => {
                if (xhr.status === 200) {
                    // const returnData = xhr?.response;
                    const returnData = JSON.parse(xhr.responseText)
                    resolve(returnData);
                } else {
                    reject('File upload failed');
                }
                setuploadingfile(false);
            };
            // Handle errors
            xhr.onerror = () => {
                reject('An error occurred during the upload');
                setuploadingfile(false);
            };
            // Start uploading
            setuploadingfile(true);
            xhr.send(formData);
        });
        // getProgressBar
        // }
    }
    // checkfiletoupload

    const AudioDropzone = () => {
        const onDrop = async (acceptedFiles, rejectedFiles) => {
            setAudioError('')
            if (acceptedFiles) {
                setaudiofileName(acceptedFiles[0].name)
            }
            const getfileresponse = await checkfiletoupload(acceptedFiles[0])
            console.log(getfileresponse)
            if (getfileresponse?.statusCode == 200) {
                await getaudioDuration(acceptedFiles[0])
                setaudiofileData(acceptedFiles[0])
                // uploadFileprogress(acceptedFiles[0])
                const getaudiouploadedUrl = getfileresponse?.uploadResponse
                setaudiofileUrl(getaudiouploadedUrl)
                // setUploadProgress(100)
            } else {
                setAudioError(getfileresponse?.errorMessage);
                sethideuploadfile(false)
                setuploadingfile(false)
            }
        };
        const { getRootProps, getInputProps, isDragActive } = useDropzone({
            onDrop,
            accept: 'audio/*',
            maxFiles: 5,
            maxSize: 50 * 1024 * 1024,
            onDropRejected: (rejectedFiles) => {
                console.log(rejectedFiles)
                rejectedFiles.forEach(file => {
                    if (file.code === "file-too-large") {
                        // setAudioError(`The file is too large. Please select a file under 50MB.`);
                    }
                });
            },
        });
        return (
            <div className="text-muted" {...getRootProps()}>
                <input {...getInputProps()} />
                {
                    isDragActive ?
                        <p className="fw-medium">Drop the audio files here ...</p> :
                        <div className='info'>
                            <p className="fw-medium mb-2">Drop audio file here or browse.</p>
                            <button className='btn btn-sm btn-success rounded-pill px-3'>Browse File</button>
                        </div>
                }
            </div>
        );
    };
    const [audioDuration, setAudioDuration] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const audioUrlRef = useRef(null);
    const formatDuration = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = Math.round(seconds % 60);
        return `${minutes} minute${minutes !== 1 ? 's' : ''} ${remainingSeconds} second${remainingSeconds !== 1 ? 's' : ''}`;
    };
    const getaudioDuration = async (file) => {
        if (file && file.type.startsWith('audio/')) {
            const audio = new Audio();
            const objectURL = URL.createObjectURL(file);
            audio.src = objectURL;
            audio.onloadedmetadata = async () => {
                const durationInSeconds = audio.duration;
                const getExacttime = await formatDuration(durationInSeconds)
                setAudioDuration(getExacttime);
                URL.revokeObjectURL(objectURL);
            };
        } else {
            setError('Please select a valid audio file.');
        }
    };

    const uploadFileprogress = (file) => {
        const reader = new FileReader();
        // Simulate upload by listening to progress
        reader.onprogress = (e) => {
            if (e.lengthComputable) {
                // const progress = Math.round((e.loaded / e.total) * 100);
                const progress = Math.round((e.loaded / e.total) * 98);
                setUploadProgress(progress); // Update the upload progress
            }
        };
        reader.onloadend = () => {
            // Once file is "uploaded", load the file URL
            audioUrlRef.current = URL.createObjectURL(file);
        };
        reader.readAsDataURL(file); // Start reading the file as a data URL
    };

    useEffect(() => {

    }, [uploadProgress])
    // ne changes
    let memberCard;
    if (cards?.memberCards && cards?.memberCards?.length > 0) {
        memberCard = cards?.memberCards?.map((item, index) => {
            if (item.card_type === "Profile Created") {
                return (
                    <>
                        <ProfileCreated profileData={item.candidateInfo} otherData={item.user_other_info} />
                    </>
                )
            }
            else if (item.card_type === "Interview Scheduled") {
                return (
                    <>
                        <InterviewScheduledcard scheduleData={item.interview_schedule} />
                    </>
                )
            }
            else if (item.card_type === "Interview Started") {
                return (
                    <>
                        <InterviewStartedcard startedData={item.nminterviewdatum} />
                    </>
                )
            } else if (item.card_type === "Status") {
                return (
                    <>
                        <Statuscard statusdata={item.requisition_status} candidateValues={item.candidateInfo} />
                    </>
                )
            }
        })
    } else {
        memberCard = <div className="noData">No Data to show.</div>
    }

    const onchangeFilter = async (optionVal, senddata) => {
        setChangeCard(optionVal)
        setPartialloading(true)
        handleChange('optionValdata', optionVal)
        handleChange('detailId', reqallvalues?.requisitionId)
        var reqidData = ''
        if (senddata != 'dataTo') {
            reqidData = senddata
        } else {
            reqidData = reqallvalues?.requisitionId
        }
        var filterCon = ''
        if (optionVal == 'All') {
            filterCon = false
        } else {
            filterCon = true
        }
        const refreshedToken = await currentUserVal?.getIdToken(true);
        const postData = {
            // requisitionId:state.requisitionId, 
            requisitionId: reqidData,
            page: currentPage,
            limit: itemsPerPage,
            isFilter: optionVal == "All" ? false : true,  //filterCon,
            filterOption: optionVal
        };
        var headers = {
            'Authorization': `Bearer ${refreshedToken}`,
            'Content-Type': 'application/json'
        };
        // const response = await axios.post(`https://dev.virtualemployee.com/admin-panel/api/v3.0/requisitions/get-requisition-member-cards-by-id`, postData, { headers })
        const response = await axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/requisitions/get-requisition-member-cards-by-id`, postData, { headers })
        setMemberCards(response.data)
        setPartialloading(false)
        // setTotalPages(response?.data.pagination?.totalPage)
        // console.log(response.data)
    }
    // new changes

    // searchValue
    const searchFunc = async (e) => {
        // setPartialloading(true)
        // console.log(optionVal.length)
        const optionVal = e
        // console.log(optionVal)
        var filterCon = ''
        if (optionVal == '') {
            filterCon = false
        } else {
            filterCon = true
        }
        const refreshedToken = await currentUserVal?.getIdToken(true);
        const postData = {
            // requisitionId:state.requisitionId, 
            requisitionId: reqallvalues?.requisitionId,
            page: currentPage,
            limit: itemsPerPage,
            isFilter: false,
            isFilterByName: filterCon,
            filterOption: optionVal
        };
        var headers = {
            'Authorization': `Bearer ${refreshedToken}`,
            'Content-Type': 'application/json'
        };
        // const response = await axios.post(`https://dev.virtualemployee.com/admin-panel/api/v3.0/requisitions/get-requisition-member-cards-by-id`, postData, { headers })
        const response = await axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/requisitions/get-requisition-member-cards-by-id`, postData, { headers })
        setMemberCards(response.data)
        // console.log(response.data)
        // setPartialloading(false)
    }
    // searchValue

    // buttonClick
    const handleClick = (type) => {
        if (type === 'prev' && currentPage > 1) {
            setCurrentPage(currentPage - 1);
            const currentPagenum = currentPage - 1
            handlePagination(currentPagenum)
        } else if (type === 'next' && currentPage < totalPages) {
            setCurrentPage(currentPage + 1)
            const nextPagenum = currentPage + 1
            handlePagination(nextPagenum)
        }
    }
    // buttonClick

    // Function to handle pagination
    const handlePagination = async (page) => {
        try {
            setIsLoading(true);
            const refreshedToken = await currentUserVal?.getIdToken(true);
            let postData = {
                requisitionId: reqallvalues?.requisitionId,
                page: currentPage,
                limit: itemsPerPage,
                isFilter: changeCard != '' && changeCard != 'All' ? true : false,
                filterOption: changeCard != '' ? changeCard : "Interview Scheduled | Profile Created | Interview Started"
            };
            if (user?.userType == "HR")
                postData.userType = "HR";
            var headers = {
                'Authorization': `Bearer ${refreshedToken}`,
                'Content-Type': 'application/json'
            };
            // const response = await axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/requisitions/get-requisition-member-cards-by-id`, postData, { headers })
            const response = await axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/requisitions/get-requisition-member-cards-by-id`, postData, { headers })
            setRequisitionCards(response.data?.memberCards);
            setRequisitionCardcount(response.data?.counts);
            // setTotalPages(response?.data.pagination?.totalPage)
            setMemberCards(response?.data)
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching requisitions:', error);
            setIsLoading(false);
            setRequisitionCards([]);
        }
    };

    const getUserStatus = (is_registered, is_registration_completed, is_interviewGiven) => {
        // if (is_interviewGiven === 1) {
        //     return "Interview Conducted";
        // } 
        if (is_registered === 1 && is_registration_completed === 1) {
            return "Registration Completed";
        } else if (is_registered === 1 && is_registration_completed === 0) {
            return "Partial Registration";
        } else if (is_registered === 0) {
            return "Not Registered";
        }
    };


    const getStatusClassName = (status) => {
        switch (status) {
            case "Interview Conducted":
                return "interview-conducted";
            case "Registration Completed":
                return "registered";
            case "Partial Registration":
                return "partially-registered";
            case "Not Registered":
                return "not-registered";
            default:
                return "";
        }
    };

    const timeFormat = (dateData) => {
        const originalDateString = dateData;
        const formattedDate = moment(originalDateString).format('YYYY-MM-DD');
        return formattedDate
    }

    const handleStartDateChange = (event) => {
        setCurrentPage(1);
        setError(null);
        setStartDate(event.target.value);
    };

    const handleEndDateChange = (event) => {
        setError(null);
        setCurrentPage(1);
        if (!startDate && event.target.value != "") {
            // setError("Please select start Date before end Date.");
            toast.error("Please select start Date before end Date.", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            setDateVal(false);
            return;
        }
        if (startDate > event.target.value && event.target.value != "") {
            // setError("End Date cannot be less than the start Date.");
            toast.error("End Date cannot be less than the start Date.", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            setDateVal(false);
            return;
        }
        setEndDate(event.target.value);
        setDateVal(event.target.value != "" ? true : false);
    };

    const resetDateFilters = () => {
        setStartDate('');
        setEndDate('');
    };

    const getdetails = async (info) => {
        const encryptedQuery = CryptoJS.AES.encrypt(JSON.stringify(info), 'secret_key').toString();
        navigate(`/candidate-details?query=${encodeURIComponent(encryptedQuery)}`);
    }

    // pagination
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    // pagination

    const divStyle = {
        bottom: "0px",
        right: "0px"
    };

    const registrationStatusChange = (event) => {
        setCurrentPage(1);
        setError(null);
        handleChange('getReqDetailsStatus', event.target.value);
        setRegistrationStatus(event.target.value);
    }

    // reschedule Interview
    const [resheduleshow, setrescheduleshow] = useState(false)
    const [getrescheduledata, setrescheduledata] = useState('')
    const [rescheduleformData, setRescheduleformData] = useState({
        interview_date: '',
        interview_time: '',
        technical_round: 0,
        one2one_round: 0
    });

    const openresModal = (info) => {
        // setShow(true)
        setrescheduleshow(true)
        setrescheduledata(info)
        // console.log(info)
    }

    const closeresModal = () => {
        // setShow(false)
        setrescheduleshow(false)
    }

    const handlerescheduleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? (checked ? 1 : 0) : value;
        setRescheduleformData({ ...rescheduleformData, [name]: newValue });
    };

    const handlerescheduleSubmit = async (e) => {
        e.preventDefault();
        let rescheduleData = rescheduleformData;
        setIsLoading(true);
        try {
            let sendData = {
                "email": getrescheduledata?.email,
                "candidate_id": getrescheduledata?.id,
                "technical_round": rescheduleData?.interview_round == 3 || rescheduleData?.interview_round == 1 ? 1 : getrescheduledata?.is_technical,
                "one2one_round": rescheduleData?.interview_round == 3 || rescheduleData?.interview_round == 2 ? 1 : getrescheduledata?.is_one2one,
                "reschdule_for": rescheduleData?.interview_round,
                "interview_date": rescheduleData?.interview_date,
                "interview_time": rescheduleData?.interview_time
            }
            const refreshedToken = await currentUserVal?.getIdToken(true);
            var headers = {
                'Authorization': `Bearer ${refreshedToken}`,
                'Content-Type': 'application/json'
            };
            const response = await axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/candidates/reschedule-candidate`, sendData, { headers })
            const responseData = response.data
            closeresModal()
            setIsLoading(false);
            toast.success('Interview has been rescheduled.', {
                position: toast.POSITION.BOTTOM_CENTER
            });
        }
        catch (error) {
            console.error('Error: ', error);
            setIsLoading(false);
        }
    }
    // reschedule Interview

    // Search
    const searchFunction = (event) => {
        // console.log(event.target.value)
        // console.log("event.target.value")
        let searchText = event.target.value;
        // setSearchVal(searchText);
        setSearchValdata(searchText)
        if (searchText.length == 0 || searchText.length >= 2) {
            setCurrentPage(1);
            const reqId = reqallvalues?.requisitionId
            handleChange('getReqDetailsSearch', searchText);
            fetchRequisitionCandidates(reqId, searchText)
        }
    }
    // Search

    // resulttype
    const resulttype = async (getData) => {
        // console.log("resulttype")
        const reqId = reqallvalues?.requisitionId
        const searchText = ""
        if (getData == 'started' || getData == 'finished') {
            fetchRequisitionCandidates(reqId, searchText, '', getData)
        } else {
            fetchRequisitionCandidates(reqId, searchText, getData)
        }
    }
    // resulttype
    // audioWork
    const [duration, setDuration] = useState(0);
    const [progress, setProgress] = useState(0);
    const [isProgressVisible, setIsProgressVisible] = useState(false);
    useEffect(() => {
        const audio = audioRef.current;
        // Ensure that the audio element is available before adding event listeners
        if (audio) {
            // When the audio metadata is loaded, set the duration
            const handleLoadedMetadata = () => {
                setDuration(audio.duration);
            };
            // Attach event listener for loadedmetadata event
            audio.addEventListener('loadedmetadata', handleLoadedMetadata);
            // Clean up event listeners when the component is unmounted
            return () => {
                audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
            };
        }
    }, []);
    const updateProgress = () => {
        const audio = audioRef.current;
        if (audio) {
            setProgress((audio.currentTime / duration) * 100);
        }
    };
    const [isPlaying, setIsPlaying] = useState(false);
    const audioRef = useRef(null);
    const togglePlayPause = () => {
        if (isPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play();
        }
        setIsPlaying(!isPlaying); // Toggle the state
        setIsProgressVisible(true);
    };
    const handleProgressBarChange = (event) => {
        const audio = audioRef.current;
        if (audio) {
            const newTime = (event.target.value / 100) * duration;
            audio.currentTime = newTime;
            setProgress(event.target.value);
        }
    };
    // audioWork
    // Handle checkbox state change
    const handleInputCheck = (event) => {
        setackChecked(event.target.checked);
    };

    const months = [];
    for (let i = 1; i <= 11; i++) {
        months.push(i.toString());
    }

    const years = [];
    for (let i = 1; i <= 40; i++) {
        years.push(i.toString());
    }

    const removeFile = () => {
        console.log('datataa')
        setaudiofileName(null)
        setAudioDuration(null)
        setaudiofileUrl(null)
        sethideuploadfile(false)
        setuploadingfile(false)
    }

    // getCurrentPathname
    useEffect(() => {
        const currentUrl = window.location.href;
        const currentPath = window.location.pathname
        handleChange("getPagePath", currentPath)
    }, []);
    // getCurrentPathname


    return (
        <div className="hr-candi-details py-3">
            {isLoading ? (
                <Loader />
            ) : ""}
            <div className="container-fluid">
                {/* Heading and filter section */}
                <div className='d-flex flex-column flex-md-row flex-wrap align-items-md-center justify-content-between gap-2 mb-2 pb-1'>
                    <div className='left-sec'>
                        <div className='fs-4 fw-semibold lh-sm'>Requisition Detail</div>
                    </div>

                    <div className="right-sec">
                        <div className="date-filter d-flex flex-column flex-sm-row flex-wrap align-items-sm-center gap-2">
                            <div className="searchfield-wrapper">
                                <input type="search" className="form-control h-auto fs-14 bg-white bg-opacity-50 border-0 rounded-3 shadow-none px-3 py-2" id="search" value={searchValData} placeholder="Search Here..." onChange={searchFunction} />
                                <img src={searchIcon} alt="Search Icon" className="position-absolute top-50 end-0 translate-middle" />
                            </div>
                            <Form.Group className="status-type d-flex flex-column flex-md-row align-items-md-center gap-1 gap-md-2 me-xl-4" controlId="statusType">
                                {/* <Form.Label className="fs-6 fw-normal text-body m-0">Status</Form.Label> */}
                                <Form.Select className="h-auto bg-white bg-opacity-50 border-0 rounded-3 shadow-none px-3 py-2" value={registrationStatus} aria-label="Default select example" onChange={registrationStatusChange} required>
                                    <option value="">Select Type</option>
                                    <option value="1">Registered Candidate</option>
                                    <option value="2">Incomplete Registered</option>
                                    <option value="0">Not Registered</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="start_date d-flex flex-column flex-sm-row align-items-sm-center gap-1 gap-md-2" controlId="startDate">
                                <Form.Label className="fs-6 fw-normal text-body m-0 p-0">From</Form.Label>
                                <Form.Control type="date" className="form-control h-auto bg-white bg-opacity-50 border-0 rounded-3 shadow-none px-3 py-2"
                                    max={new Date().toJSON().slice(0, 10)}
                                    value={startDate} onChange={handleStartDateChange}
                                    required
                                />
                            </Form.Group>
                            <Form.Group className="end_date d-flex flex-column flex-sm-row align-items-sm-center gap-1 gap-md-2" controlId="endDate">
                                <Form.Label className="fs-6 fw-normal text-body m-0 p-0">To</Form.Label>
                                <Form.Control type="date" className="form-control h-auto bg-white bg-opacity-50 border-0 rounded-3 shadow-none px-3 py-2"
                                    max={new Date().toJSON().slice(0, 10)}
                                    value={endDate} onChange={handleEndDateChange}
                                    required
                                />
                            </Form.Group>
                            <div className="btn-wrapper text-center me-xl-4">
                                <div className="bg-white bg-opacity-50 border-0 rounded-3 shadow-none px-3 py-2 cursor-pointer lh-sm" onClick={() => resetDateFilters()}>
                                    <i class="bi bi-arrow-counterclockwise"></i> <span className="fw-medium d-sm-none">Reset</span>
                                </div>
                            </div>
                            {/* <Button variant='primary' className="text-nowrap rounded mt-1 mt-md-0 px-3 py-2">
                                <i className="bi bi-plus-circle text-white me-1"></i>
                            </Button> */}
                            {/* {user?.userType == "HR" ? */}
                            <Button variant="success" className="px-3 focus-ring focus-ring-success lh-sm" title="Schedule Interview" onClick={handleShow}>
                                <i className="bi bi-person-plus-fill"></i>
                            </Button>
                            {/* : " "} */}
                            {error && <div className="small text-danger text-md-end mt-1 position-absolute text-end" style={divStyle}>{error}</div>}
                            {/* new */}
                        </div>
                    </div>
                </div>

                {/* Requisition info section */}
                <div className="requisition-info-cards fs-14 bg-white rounded overflow-auto mb-2 p-3">
                    <div className="row g-3">
                        <div className="col-12 col-xl-3 item-wrapper d-flex flex-column gap-2">
                            <div className="info-box">
                                <div className="fs-6 fw-bold text-body text-capitalize text-truncate lh-sm">{reqallvalues?.requisition.jobTitle}</div>
                                <div className="fw-bold text-danger text-uppercase">VEREQ{reqallvalues?.requisitionId}</div>
                            </div>
                            <div className="info-box d-flex flex-wrap flex-xl-nowrap align-items-center gap-2">
                                <div className="recruiters-imgages d-flex">
                                    {reqallvalues?.otherHR?.map((hrlist, index) => (
                                        <img src={gethrpics[hrlist?.email] != "" && gethrpics[hrlist?.email] != null ? gethrpics[hrlist?.email] : imgDefault} alt="Recruiters" className="recruiter-img img-fluid rounded-circle" style={{ height: '30px', width: '30px', minWidth: '30px' }} />
                                    ))}
                                </div>
                                <OverlayTrigger
                                    placement={'bottom'}
                                    overlay={
                                        <Tooltip id="" className="custom-tooltip" data-bs-theme="dark">
                                            <div className="recruiters-list d-flex flex-column gap-2 p-2">
                                                {reqallvalues?.otherHR?.map((hrlist, index) => (
                                                    <div className="recruiter-items d-flex align-items-center gap-2">
                                                        <img src={gethrpics[hrlist?.email] != "" && gethrpics[hrlist?.email] != null ? gethrpics[hrlist?.email] : imgDefault} alt="Recruiters" className="hr-profiles img-fluid rounded-circle" style={{ height: '35px', width: '35px' }} />
                                                        <div className="recruiter-info d-block text-start">
                                                            {/* <div className="recruiter-name fs-14 fw-medium text-capitalize text-truncate">{hrlist?.name}</div>
                                                            <div className="assign-candidate fs-12 text-capitalize text-truncate">{hrlist?.name}</div> */}
                                                            {
                                                                user?.userType == "HR" && user.hrType == '1' ?
                                                                    hrlist.hrType != 1 ?
                                                                        <>
                                                                            <div className="recruiter-name fs-14 fw-medium text-capitalize text-truncate">External HR</div>
                                                                            <div className="assign-candidate fs-12 text-capitalize text-truncate">External HR</div>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <div className="recruiter-name fs-14 fw-medium text-capitalize text-truncate">{hrlist?.name}</div>
                                                                            <div className="assign-candidate fs-12 text-capitalize text-truncate">{hrlist?.name}</div>
                                                                        </>
                                                                    :
                                                                    <>
                                                                        <div className="recruiter-name fs-14 fw-medium text-capitalize text-truncate">{hrlist?.name}</div>
                                                                        <div className="assign-candidate fs-12 text-capitalize text-truncate">{hrlist?.name}</div>
                                                                    </>
                                                            }
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </Tooltip>
                                    }
                                >
                                    <Button variant="link" className="recruiters-label fs-14 fw-medium text-primary text-truncate text-decoration-none p-0">
                                        {reqallvalues?.otherHR?.length > 1 ? `${reqallvalues?.otherHR?.length} others recruiters are working` : `${reqallvalues?.otherHR?.length} recruiter is working`}
                                    </Button>
                                </OverlayTrigger>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-xl-2 item-wrapper d-flex flex-column gap-2">
                            <div className="info-box">
                                <div className="info-label text-clr1 fs-12">Requested By</div>
                                <div className="text-capitalize">{reqallvalues?.requisition.requested_by}</div>
                            </div>
                            <div className="info-box">
                                <div className="info-label text-clr1 fs-12">Department</div>
                                <div className="department_name text-capitalize">{reqallvalues?.requisition.department}</div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-xl-3 item-wrapper d-flex flex-column gap-2">
                            <div className="info-box">
                                <div className="info-label text-clr1 fs-12">Created By</div>
                                <div className="created_date">{reqallvalues?.requisition.approvalDate ? timeFormat(reqallvalues?.requisition.approvalDate) : ""} <span className="ms-1">({reqallvalues?.timeAgo})</span></div>
                            </div>
                            <div className="info-box">
                                <div className="info-label text-clr1 fs-12">Required By</div>
                                <div className="Required_date">{reqallvalues?.requisition.reqiredBy ? timeFormat(reqallvalues?.requisition.reqiredBy) : ""} <span className="text-danger ms-1">({reqallvalues?.time_remaining})</span></div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-xl-2 item-wrapper d-flex flex-column gap-2">
                            <div className="info-box">
                                <div className="info-label text-clr1 fs-12">Minimum Experience</div>
                                <div className="min_exp text-capitalize">{reqallvalues?.requisition.minimumExp}</div>
                            </div>

                            <div className="info-box">
                                <div className="info-label text-clr1 fs-12">Job Location</div>
                                <div className="location text-capitalize">{reqallvalues?.requisition.location}</div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-12 col-xl-2 item-wrapper">
                            <div className="info-box text-xl-end">
                                <Button type="button" variant="outline-success" className="border-2 fw-semibold rounded-pill focus-ring focus-ring-success text-uppercase px-5 py-2">{reqallvalues?.requisition.status}</Button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Requisition Info Cards Listing */}
                <div className="dashboard-home mb-2 pt-1">
                    <div className="info-card-sec">
                        <div className="row g-2">
                            <div className="col-sm-6 col-lg-4 col-xxl-2 cursor-pointer" onClick={() => fetchRequisitionCandidates(reqallvalues?.requisitionId)}>
                                <div className="bg-primary bg-opacity-25 border-bottom border-4 border-primary rounded-3 pointer h-100 p-3">
                                    <div className="d-flex align-items-center justify-content-between gap-2">
                                        <div className="card-details">
                                            <div className="label-value fs-3 fw-bold lh-1 mb-1">{requisitionDetailsCount.interviewScheduleStatusCount ? requisitionDetailsCount.interviewScheduleStatusCount : 0}</div>
                                            <div className="label-title small text-muted lh-sm">Interview schedule</div>
                                        </div>
                                        <div className="card-iconn text-primary lh-1">
                                            <i className="bi bi-calendar-check-fill fs-4"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-6 col-lg-4 col-xxl-2">
                                <div className="bg-info bg-opacity-25 border-bottom border-4 border-info rounded-3 pointer h-100 p-3">
                                    <div className="d-flex align-items-center justify-content-between gap-2">
                                        <div className="card-details">
                                            <div className="label-value fs-3 fw-bold lh-1 mb-1">{requisitionDetailsCount.profileCreateStatusCount ? requisitionDetailsCount.profileCreateStatusCount : 0}</div>
                                            <div className="label-title small text-muted lh-sm">Profile created</div>
                                        </div>
                                        <div className="card-iconn text-info lh-1">
                                            <i className="bi bi-person-bounding-box fs-4"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-6 col-lg-4 col-xxl-2 cursor-pointer" onClick={() => resulttype('started')}>
                                <div className="bg-warning bg-opacity-25 border-bottom border-4 border-warning rounded-3 pointer h-100 p-3">
                                    <div className="d-flex align-items-center justify-content-between gap-2">
                                        <div className="card-details">
                                            <div className="label-value fs-3 fw-bold lh-1 mb-1">{requisitionDetailsCount.interviewStartedCount ? requisitionDetailsCount.interviewStartedCount : 0}</div>
                                            <div className="label-title small text-muted lh-sm">Interview started</div>
                                        </div>
                                        <div className="card-iconn text-warning lh-1">
                                            <i className="bi bi-play-circle-fill fs-3"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-6 col-lg-4 col-xxl-2 cursor-pointer" onClick={() => resulttype('finished')}>
                                <div className="bg-secondary bg-opacity-25 border-bottom border-4 border-secondary rounded-3 pointer h-100 p-3">
                                    <div className="d-flex align-items-center justify-content-between gap-2">
                                        <div className="card-details">
                                            <div className="label-value fs-3 fw-bold lh-1 mb-1">{requisitionDetailsCount.interviewFinishedCount ? requisitionDetailsCount.interviewFinishedCount : 0}</div>
                                            <div className="label-title small text-muted lh-sm">Interview finished</div>
                                        </div>
                                        <div className="card-iconn text-secondary lh-1">
                                            <i className="bi bi-stop-circle-fill fs-3"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-6 col-lg-4 col-xxl-2 cursor-pointer" onClick={() => resulttype('fail')}>
                                <div className="bg-danger bg-opacity-25 border-bottom border-4 border-danger rounded-3 pointer h-100 p-3">
                                    <div className="d-flex align-items-center justify-content-between gap-2">
                                        <div className="card-details">
                                            <div className="label-value fs-3 fw-bold lh-1 mb-1">{requisitionDetailsCount.resultsFailCount ? requisitionDetailsCount.resultsFailCount : 0}</div>
                                            <div className="label-title small text-muted lh-sm">Fail</div>
                                        </div>
                                        <div className="card-iconn text-danger lh-1">
                                            <i className="bi bi-person-fill-slash fs-3"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-6 col-lg-4 col-xxl-2 cursor-pointer" onClick={() => resulttype('pass')}>
                                <div className="bg-success bg-opacity-25 border-bottom border-4 border-success rounded-3 pointer h-100 p-3">
                                    <div className="d-flex align-items-center justify-content-between gap-2">
                                        <div className="card-details">
                                            {/* <div className="label-value fs-3 fw-bold lh-1 mb-1">{requisitionDetailsCount.offerGiven ? requisitionDetailsCount.offerGiven : 0}</div> */}
                                            {/* <div className="label-title small text-muted lh-sm">Offer given</div> */}
                                            <div className="label-value fs-3 fw-bold lh-1 mb-1">{requisitionDetailsCount.passCount ? requisitionDetailsCount.passCount : 0}</div>
                                            <div className="label-title small text-muted lh-sm">Pass</div>
                                        </div>
                                        <div className="card-iconn text-success lh-1">
                                            <i className="bi bi-currency-exchange fs-3"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="candidate-list d-flex flex-column gap-3">
                    {requisitionCandidates?.length >= 1 ?
                        requisitionCandidates?.map((info, index) => {
                            const status = getUserStatus(info.is_registered, info.is_registration_completed, info.is_interviewGiven);
                            const statusClassName = getStatusClassName(status);
                            // const statusClassName = getStatusClassName(info?.registration_status);
                            return (
                                <div className={`candidateStatusCard bg-white small rounded-3 pointer overflow-auto p-3 ${statusClassName}`}>
                                    <div class="info-header border-bottom pb-2 mb-2">
                                        <div class="row align-items-centerr g-2">
                                            <div className="col-sm-6">
                                                <div className="d-flex align-items-center gap-2 gap-md-3 mt-1">
                                                    <img src={info?.user_photo != "" && info?.user_photo != null ? info?.user_photo : Avatar} alt="Recruiter" className='img-fluid rounded-circle' style={{ height: '65px', width: '65px' }} />
                                                    <div className="candidate-info text-truncate">
                                                        <div className="candidate-name fs-5 text-truncate text-capitalize lh-sm">{info?.user_name}</div>
                                                        {/* {formData.email &&  */}
                                                        <div className="cand-email text-truncate text-lowercase">{info?.email}</div>
                                                        {/* } */}
                                                        <div className="cand-email text-truncate text-capitalize">{info?.contact_no}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="btn-wrapper d-flex flex-wrap justify-content-sm-end gap-2">
                                                    {
                                                        user?.userType == "HR" ?
                                                            <Button variant="warning" className="rounded-pill px-3 py-2" onClick={() => openresModal(info)}>Reschedule</Button>
                                                            : ""
                                                    }
                                                    <Button variant="light" className="custom-button fw-medium rounded-pill py-2">
                                                        {status}
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row g-3 g-xl-2">
                                        <div className="col-6 col-md-3 item-wrapper d-flex flex-column">
                                            <div className="info-box">
                                                <div className="info-label text-clr1 fs-12">Recruiter</div>
                                                <div className="d-flex align-items-center gap-2 mt-1">
                                                    <img src={gethrpics[info?.added_by_email] != "" && gethrpics[info?.added_by_email] != null ? gethrpics[info?.added_by_email] : imgDefault} alt="Recruiter" className='img-fluid rounded-circle' style={{ height: '35px', width: '35px' }} />
                                                    <div className="recruiter-name text-capitalize text-truncate">{info?.added_by_name}</div>
                                                </div>
                                            </div>
                                            <div className="info-box">
                                                <div className="info-label text-clr1 fs-12">Time Since Added</div>
                                                <div className="text-capitalize">{info?.timeAgo}</div>
                                            </div>
                                            <div className="info-box">
                                                <div className="info-label text-clr1 fs-12">Registration Status</div>
                                                <div className="text-capitalize">{status}</div>
                                            </div>
                                            <div className="info-box">
                                                <div className="info-label text-clr1 fs-12">Source of Candidate</div>
                                                <div className="text-capitalize">{info?.source_of_candidate}</div>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-3 item-wrapper d-flex flex-column">
                                            <div className="info-box">
                                                <div className="info-label text-clr1 fs-12">Current Company</div>
                                                <div className="cand-company text-capitalize">{info?.current_company}</div>
                                            </div>
                                            <div className="info-box">
                                                <div className="info-label text-clr1 fs-12">Job Status</div>
                                                <div className="text-capitalize">{info?.job_status}</div>
                                            </div>
                                            <div className="info-box">
                                                <div className="info-label text-clr1 fs-12">Job Role Awareness</div>
                                                <div className="text-capitalize">{info?.is_job_role_aware}</div>
                                            </div>
                                            <div className="info-box">
                                                <div className="info-label text-clr1 fs-12">Notice Period</div>
                                                <div className="text-capitalize">{info?.notice_period}</div>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-3 item-wrapper d-flex flex-column">
                                            <div className="info-box">
                                                <div className="info-label text-clr1 fs-12">Current CTC</div>
                                                <div className="text-capitalize">{info?.current_ctc}</div>
                                            </div>
                                            <div className="info-box">
                                                <div className="info-label text-clr1 fs-12">Expected CTC</div>
                                                <div className="text-capitalize">{info?.expected_ctc}</div>
                                            </div>
                                            <div className="info-box">
                                                <div className="info-label text-clr1 fs-12">Experience</div>
                                                <div className="text-capitalize">{info?.experience}</div>
                                            </div>
                                            <div className="info-box">
                                                <div className="info-label text-clr1 fs-12">Salary Negotiation</div>
                                                <div className="text-capitalize">{info?.is_salary_negotiation}</div>
                                            </div>
                                            <div className="info-box">
                                                <div className="info-label text-clr1 fs-12">Interview Medium</div>
                                                <div className="text-capitalize">{info?.interview_medium}</div>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-3 item-wrapper d-flex flex-column">
                                            <div className="info-box">
                                                <div className="info-label text-clr1 fs-12">Comm. Skill Rating</div>
                                                <div className="text-capitalize">{info?.comm_skill_rating}/5</div>
                                            </div>
                                            <div className="info-box">
                                                <div className="info-label text-clr1 fs-12">Cultural Fit Rating</div>
                                                <div className="text-capitalize">{info?.cultural_fit_rating}/5</div>
                                            </div>
                                            <div className="info-box">
                                                <div className="info-label text-clr1 fs-12">Interview Date & Time</div>
                                                <div className="regis-date text-capitalize">{info?.interview_date ? timeFormat(info?.interview_date) : ""}, {info?.interview_time}</div>
                                            </div>
                                            <div className="action-wrapper d-flex flex-wrap gap-2 mt-1">
                                                <Button type='button' variant="light" size="sm" className="border px-3" onClick={() => getdetails(info)}>View Details</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>)
                        })
                        : <div className="no-data-wrapper bg-white rounded px-3 py-5 mt-2">
                            <div className="text-center mx-auto" style={{ maxWidth: '350px' }}>
                                <img src={require('../assets/no-data.jpg')} alt="Candidate" className="img-fluid mt-2" />
                                <div className='fs-4 fw-semibold text-danger mt-3'>Sorry no record Found !</div>
                                {/* <div className='small text-muted'>Whoops... this information is not available for a moment</div> */}
                            </div>
                        </div>}
                </div>
                {requisitionCandidates?.length >= 1 ?
                    <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} /> : ""}
                {/* Related Candidates List */}
                <div className='listing-sec d-none'>
                    <div className='d-flex align-items-sm-center justify-content-sm-between flex-column flex-sm-row gap-2 mb-3'>
                        <div className='left-sec fs-5 fw-medium'>
                            <i className="bi bi-journal-album"></i> Requisition Candidates List
                        </div>
                        {user?.userType == "HR" ?
                            <Button variant="success" className="px-3 focus-ring focus-ring-success" onClick={handleShow}>
                                <i className="bi bi-person-plus-fill"></i> Schedule Interview
                            </Button> : " "}
                    </div>
                    {/* Filter Section */}
                    <div className='filter-sec mb-3'>
                        <div className="d-flex flex-column flex-sm-row align-items-sm-center justify-content-between gap-2">
                            <div className="filter-label fw-medium pointer"></div>
                            <div className="d-flex flex-column flex-sm-row gap-2">
                                <div className="searchfield-wrapper">
                                    <input type="search" onChange={(e) => searchFunc(e.target.value)} className="form-control rounded-2" id="search" placeholder="Search here..." autoComplete='off' />
                                    <img src={searchIcon} alt="Search Icon" className="position-absolute top-50 end-0 translate-middle" />
                                </div>
                                <div className="Result-filter">
                                    <select className="form-select" value={changeCard} onChange={(e) => onchangeFilter(e.target.value, 'dataTo')}>
                                        {/* <option value="" selected disabled>Choose here...</option> */}
                                        <option value="All">All</option>
                                        {cardtype?.map((info, index) => (
                                            <option value={info?.card_type}>{info?.card_type != "Status" ? info?.card_type : "Other Cards"}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* All Requisition Candidate Listing */}
                    <div className='cards-listing'>
                        {partialloading ? (
                            <Loader />
                        ) : ""}
                        <div>{memberCard}</div>
                        {/* } */}
                    </div>

                    {/* Add New Candidate Model */}
                    <Modal show={show} onHide={handleClose} centered backdrop="static" keyboard={false} size={otherVisibility ? "lg" : "xl"} contentClassName="border-0 border-5 border-top border-primary rounded-0 rounded-bottom">
                        <Modal.Body className="modal-body px-3 px-xl-5 py-4">
                            <h1 className="modal-title fs-4 fw-fw-semibold text-center mb-3" id="addCandidate">Add New Candidate</h1>
                            <Form onSubmit={handleSubmit}>
                                <div className="row g-3">
                                    <div className="col-sm-6">
                                        <Form.Group>
                                            <Form.Label>Contact</Form.Label>
                                            <PhoneInput
                                                country={"in"}
                                                countryCodeEditable={false}
                                                autoComplete={'false'}
                                                value={phone}
                                                onChange={handleOnChangeNumber}
                                                inputProps={{
                                                    name: 'phone',
                                                    autoComplete: 'off',
                                                    required: true,
                                                }}
                                                inputClass="w-100 h-100 border"
                                                style={{ height: '45px' }}
                                            />
                                            {checkMessage &&
                                                <div className="text-danger fs-14 mt-1">{checkMessage}</div>
                                            }
                                        </Form.Group>
                                    </div>
                                    <div className="col-sm-6">
                                        <Form.Group>
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control type="email" placeholder="Email"
                                                name="email"
                                                value={formData.email}
                                                onChange={handleInputChange}
                                                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                                                autoComplete="off"
                                                disabled={isDisable}
                                                onBlur={checkEmail}
                                                required />
                                            {/* {newErrorsEmail.email &&
                                                <div className="text-danger fs-14 mt-1">{newErrorsEmail.email}</div>
                                            } */}
                                        </Form.Group>
                                    </div>
                                </div>

                                <div className={otherVisibility ? "d-none" : ""}>
                                    {/* Verification of Basic Details Section */}
                                    <div id="basicDetails" className="row g-3 mt-0">
                                        <div className="col-sm-6">
                                            <Form.Group>
                                                <Form.Label>Source Of Candidate</Form.Label>
                                                <Form.Select name="source_of_candidate" onChange={handleInputChange} required disabled={otherVisibility}>
                                                    <option value={""}>Select Source</option>
                                                    <option value={"job_boards"}>Job Boards (e.g., Indeed, Monster need text box)</option>
                                                    <option value={"company_website"}>Company Website</option>
                                                    <option value={"social_media"}>Social Media (e.g., LinkedIn, Twitter, Facebook)</option>
                                                    <option value={"employee_referral"}>Employee Referral</option>
                                                    <option value={"recruitment_agencies"}>Recruitment Agencies</option>
                                                    <option value={"campus_recruitment"}>Campus Recruitment</option>
                                                    <option value={"networking_events"}>Networking Events</option>
                                                    <option value={"internal_application"}>Internal Application</option>
                                                    <option value={"headhunting"}>Headhunting</option>
                                                    <option value={"others"}>Others</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </div>
                                        <div className="col-sm-6">
                                            <Form.Group>
                                                <Form.Label>Full Name</Form.Label>
                                                <Form.Control type="text" name="user_name" placeholder="Candidate Full Name" value={formData.user_name} onChange={handleInputChange} autoComplete="off" required />
                                            </Form.Group>
                                        </div>
                                        <div className="col-12">
                                            <Form.Group className="d-flex flex-wrap align-items-center column-gap-4 mt-2">
                                                <Form.Label>Job Status</Form.Label>
                                                <div className="radio-wrapper fw-medium">
                                                    <Form.Check type="radio" inline className="me-xl-4"
                                                        label="In Job"
                                                        value="In Job"
                                                        name="job_status"
                                                        checked={formData.job_status == 'In Job' ? true : false}
                                                        onChange={handleInputChange}
                                                        id="inline-radio-1"
                                                        required
                                                    />
                                                    <Form.Check type="radio" inline className="me-xl-4"
                                                        label="Unemployed"
                                                        name="job_status"
                                                        value="Unemployed"
                                                        checked={formData.job_status == 'Unemployed' ? true : false}
                                                        id="inline-radio-3"
                                                        onChange={handleInputChange}
                                                    />
                                                    <Form.Check type="radio" inline className="me-xl-4"
                                                        label="Fresher"
                                                        name="job_status"
                                                        value="Fresher"
                                                        checked={formData.job_status == 'Fresher' ? true : false}
                                                        id="inline-radio-2"
                                                        onChange={handleInputChange}
                                                    />

                                                </div>
                                            </Form.Group>
                                        </div>
                                        {/* In Job */}
                                        {formData.job_status != "Fresher" ?
                                            <>
                                                <div className="col-sm-6">
                                                    <Form.Group>
                                                        <Form.Label>{`${formData.job_status == "Unemployed" ? "Previous" : "Current"} Company`}</Form.Label>
                                                        <Form.Control type="text" name="current_company" placeholder={`${formData.job_status == "Unemployed" ? "Previous" : "Current"} Company`} value={formData.current_company} onChange={handleInputChange} autoComplete="off" required />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-sm-6">
                                                    <Form.Group>
                                                        <Form.Label>{`${formData.job_status == "Unemployed" ? "Previous" : "Current"}`} Role/Designation</Form.Label>
                                                        <Form.Control type="text"
                                                            name="job_role"
                                                            placeholder="Role/Designation"
                                                            value={formData.job_role}
                                                            onChange={handleInputChange}
                                                            autoComplete="off" required />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-sm-6">
                                                    <Form.Group>
                                                        <Form.Label>Relevant Experience</Form.Label>
                                                        <div className="d-flex gap-2">
                                                            <Form.Select name="relevant_years" onChange={handleInputChange} required>
                                                                <option value="">Years</option>
                                                                {years.map((number, index) => (
                                                                    <>
                                                                        <option value={number}>{number}</option>
                                                                    </>
                                                                ))}
                                                            </Form.Select>
                                                            <Form.Select name="relevant_months" onChange={handleInputChange} required>
                                                                <option value="">Months</option>
                                                                {months.map((number, index) => (
                                                                    <>
                                                                        <option value={number}>{number}</option>
                                                                    </>
                                                                ))}
                                                            </Form.Select>
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-sm-6">
                                                    <Form.Group>
                                                        <Form.Label>Total Experience</Form.Label>
                                                        <div className="d-flex gap-2">
                                                            <Form.Select name="total_exp_years" onChange={handleInputChange} required>
                                                                <option value="">Years</option>
                                                                {years.map((number, index) => (
                                                                    <>
                                                                        <option value={number}>{number}</option>
                                                                    </>
                                                                ))}
                                                            </Form.Select>
                                                            <Form.Select name="total_exp_months" onChange={handleInputChange} required>
                                                                <option value="">Months</option>
                                                                {months.map((number, index) => (
                                                                    <>
                                                                        <option value={number}>{number}</option>
                                                                    </>
                                                                ))}
                                                            </Form.Select>
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                            </>
                                            : ""}
                                        {/* In Job */}
                                    </div>

                                    {/* Suitability and Expectations Section */}
                                    <div id="interviewSchedule" className="row g-3 mt-0">
                                        {formData.job_status != "Fresher" ?
                                            <div className="col-sm-6">
                                                <Form.Group>
                                                    <Form.Label>Current CTC</Form.Label>
                                                    <div className="d-flex gap-2">
                                                        <Form.Control type="text" name="current_ctc" maxLength={3} placeholder="CTC" value={formData.current_ctc} onChange={handleInputChange} autoComplete="off" required />
                                                        <Form.Select name="current_ctc_scale" onChange={handleInputChange} required>
                                                            <option value="">Select</option>
                                                            <option value="Thousands">Thousands</option>
                                                            <option value="Lakhs">Lakhs</option>
                                                            <option value="Crore">Crore</option>
                                                        </Form.Select>
                                                    </div>
                                                </Form.Group>
                                                {errors.current_ctc && <div className="text-danger fs-14 mt-1">{errors.current_ctc}</div>}
                                            </div>
                                            : ""}
                                        <div className="col-sm-6">
                                            <Form.Group>
                                                <Form.Label>Expected CTC</Form.Label>
                                                <div className="d-flex gap-2">
                                                    <Form.Control type="text" name="expected_ctc" maxLength={3} placeholder="CTC" value={formData.expected_ctc} onChange={handleInputChange} autoComplete="off" required />
                                                    <Form.Select name="expected_ctc_scale" onChange={handleInputChange} required>
                                                        <option value="">Select</option>
                                                        <option value="Thousands">Thousands</option>
                                                        <option value="Lakhs">Lakhs</option>
                                                        <option value="Crore">Crore</option>
                                                    </Form.Select>
                                                </div>
                                            </Form.Group>
                                            {errors.expected_ctc && <div className="text-danger fs-14 mt-1">{errors.expected_ctc}</div>}
                                        </div>
                                        <div className={`${formData.job_status == "Fresher" ? "col-sm-6 pt-4" : "col-12"} `}>
                                            <Form.Group className="d-flex align-items-center flex-wrap column-gap-4 pt-2">
                                                <Form.Label>Negotiation Possible?</Form.Label>
                                                <div className="radio-wrapper fw-medium">
                                                    <Form.Check type="radio" inline className="me-xl-4"
                                                        name="salaryNegotiation"
                                                        label="Yes"
                                                        value="Yes"
                                                        checked={formData.salaryNegotiation == 'Yes' ? true : false}
                                                        onChange={handleInputChange}
                                                        id="negotiationPossible1"
                                                        required
                                                    />
                                                    <Form.Check type="radio" inline className="me-xl-4"
                                                        name="salaryNegotiation"
                                                        label="No"
                                                        value="No"
                                                        checked={formData.salaryNegotiation == 'No' ? true : false}
                                                        onChange={handleInputChange}
                                                        id="negotiationPossible2"
                                                    />
                                                </div>
                                            </Form.Group>
                                        </div>

                                        {/* Show according to the condition */}
                                        {formData.job_status == "In Job" ?
                                            <>
                                                <div className="col-sm-6">
                                                    <Form.Group>
                                                        <Form.Label>Notice Period</Form.Label>
                                                        <Form.Select name="notice_period" onChange={handleInputChange} required>
                                                            <option value="">Select Notice Period</option>
                                                            <option value="Immidiate Joiner">Immidiate Joiner</option>
                                                            <option value="15 Days">15 Days</option>
                                                            <option value="1 Month">1 Month</option>
                                                            <option value="2 Month">2 Month</option>
                                                            <option value="3 Month">3 Month</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-sm-6">
                                                    <Form.Group className="d-flex flex-wrap align-items-center column-gap-4 mt-lg-4 pt-sm-3">
                                                        <Form.Label>Buyout Possible?</Form.Label>
                                                        <div className="radio-wrapper fw-medium">
                                                            <Form.Check type="radio" inline className="me-xl-4"
                                                                label="Yes"
                                                                value="Yes"
                                                                name="is_notice_buyout"
                                                                checked={formData.is_notice_buyout == 'Yes' ? true : false}
                                                                onChange={handleInputChange}
                                                                id="buyoutPossible1"
                                                                required
                                                            />
                                                            <Form.Check type="radio" inline className="me-xl-4"
                                                                label="No"
                                                                value="No"
                                                                name="is_notice_buyout"
                                                                checked={formData.is_notice_buyout == 'No' ? true : false}
                                                                onChange={handleInputChange}
                                                                id="buyoutPossible2"
                                                            />
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                            </>
                                            : ""}
                                        {/* Show according to the condition */}
                                        {formData.job_status != "Fresher" &&
                                            <div className="col-lg-6">
                                                <Form.Group>
                                                    <Form.Label className="w-100">Reason For Job Change <span className="float-end text-muted">100 Characters Min</span></Form.Label>
                                                    <Form.Control as="textarea" rows={5} minLength={100} placeholder="Write Here..."
                                                        name="reason_job_change"
                                                        value={formData.reason_job_change}
                                                        onChange={handleInputChange}
                                                        autoComplete="off" required />
                                                </Form.Group>
                                            </div>
                                            // : ""
                                        }
                                        <div className={`${formData.job_status == "Fresher" ? "col-12" : "col-lg-6"}`}>
                                            <Form.Group>
                                                <Form.Label>Upload File</Form.Label>
                                                <label htmlFor="uploadResume" className="w-100 bg-warning bg-opacity-10 border-warning rounded-3 small d-flex flex-column align-items-center justify-content-center text-center p-3" style={{ border: '1.5px dashed', minHeight: '133px' }}>
                                                    <Dropzone acceptedFiles={['application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/pdf', 'application/msword']} onDrop={handleDrop} />
                                                    {file ?
                                                        <p key={file.name} className='bg-light border py-2 px-3 rounded-pill small text-truncate mx-auto mt-3' style={{ maxWidth: '300px' }}>
                                                            {file.name} ({file.size} bytes)
                                                        </p> : null
                                                    }
                                                </label>
                                                {error && <p className="text-danger fs-14 mt-1">{error}</p>}
                                            </Form.Group>
                                        </div>
                                    </div>

                                    {/* Upload Section */}
                                    {/* <div id="quesAndResume" className="row g-3 mt-0">
                                        <div className="col-sm-6">
                                            <Form.Group>
                                                <Form.Label>Candidate Questions</Form.Label>
                                                <Form.Control as="textarea" rows={5} name="candidate_ques"
                                                    value={formData.candidate_ques}
                                                    onChange={handleInputChange}
                                                    autoComplete="off" required />
                                            </Form.Group>
                                        </div>
                                    </div> */}

                                    {/* Rating Section */}
                                    <div id="culturalFit" className="row align-items-center g-3 mt-0">
                                        <div className="col-sm-6 col-lg-3">
                                            <Form.Group>
                                                <Form.Label>Communication Skills</Form.Label>
                                                <div className='start-rating d-flex align-items-center gap-3 ps-2 ms-1'>
                                                    <Rating onClick={handleRating} size={35}
                                                        initialValue={rating}
                                                        allowFraction
                                                        // showTooltip 
                                                        fillColorArray={['#f14f45', '#f16c45', '#f18845', '#f1b345', '#f1d045']}
                                                    />
                                                </div>
                                            </Form.Group>
                                        </div>

                                        <div className="col-sm-6 col-lg-3">
                                            <Form.Group>
                                                <Form.Label>Cultural Fit</Form.Label>
                                                <div className='start-rating d-flex align-items-center gap-3 ps-2 ms-1'>
                                                    <Rating onClick={handleCulturalRating} size={35}
                                                        initialValue={culturalRating}
                                                        allowFraction
                                                        // showTooltip 
                                                        fillColorArray={['#f14f45', '#f16c45', '#f18845', '#f1b345', '#f1d045']}
                                                    />
                                                </div>
                                            </Form.Group>
                                        </div>

                                        <div className="col-lg-6">
                                            <Form.Group>
                                                <Form.Label className="w-100">Reason for Cultural Fit<span className="float-end text-muted">100 Characters Min</span></Form.Label>
                                                <Form.Control as="textarea" minLength={100} placeholder="Write Here..."
                                                    rows={4} name="cultural_fit"
                                                    autoComplete="off"
                                                    onChange={handleInputChange}
                                                    required />
                                            </Form.Group>
                                        </div>
                                    </div>

                                    {/* Interview Schedule Section */}
                                    <div id="interviewSchedule" className="row g-3 mt-0">
                                        <div className="col-12">
                                            <Form.Group className="d-flex align-items-center flex-wrap column-gap-3 pt-2">
                                                <Form.Label>Interview Medium</Form.Label>
                                                <div className="radio-wrapper fw-medium">
                                                    <Form.Check type="radio" inline className="me-xl-4"
                                                        label="NM Interview"
                                                        value="NM Interview"
                                                        name="interview_medium"
                                                        checked={formData.interview_medium == 'NM Interview' ? true : false}
                                                        onChange={handleInputChange}
                                                        id="interviewMedium1"
                                                        required
                                                    />
                                                    <Form.Check type="radio" inline className="me-xl-4"
                                                        label="In Person"
                                                        value="In Person"
                                                        name="interview_medium"
                                                        checked={formData.interview_medium == 'In Person' ? true : false}
                                                        id="interviewMedium2"
                                                        onChange={handleInputChange}
                                                    />
                                                    <Form.Check type="radio" inline className="me-xl-4"
                                                        label="Other Interview Tool"
                                                        value="Other Interview Tool"
                                                        name="interview_medium"
                                                        checked={formData.interview_medium == 'Other Interview Tool' ? true : false}
                                                        id="interviewMedium3"
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </Form.Group>
                                        </div>
                                        {formData.interview_medium != 'In Person' ?
                                            <div className="col-12">
                                                <Form.Group className="d-flex align-items-center flex-wrap column-gap-4">
                                                    <Form.Label>Interview rounds</Form.Label>
                                                    <div className="checks-wrapper">
                                                        <Form.Check type="checkbox" inline className="me-xl-4"
                                                            label="Coding Round"
                                                            id="interviewRounds1"
                                                            name="technical_round"
                                                            checked={formData.technical_round == 1}
                                                            onChange={handleInputChange}
                                                        />
                                                        <Form.Check type="checkbox" inline className="me-xl-4"
                                                            label="One to One with Sheela"
                                                            id="interviewRounds2"
                                                            name="one2one_round"
                                                            checked={formData.one2one_round == 1}
                                                            onChange={handleInputChange}
                                                        />
                                                        {roundError && <p className="text-danger fs-14 mt-1">{roundError}</p>}
                                                    </div>
                                                </Form.Group>
                                            </div>
                                            : ""}
                                        <div className="col-6 col-lg-3">
                                            <Form.Group>
                                                <Form.Label>Interview Date</Form.Label>
                                                <Form.Control type="date" name="interview_date" min={new Date().toJSON().slice(0, 10)} value={formData.interview_date} onChange={handleInputChange} required />
                                            </Form.Group>
                                        </div>
                                        <div className="col-6 col-lg-3">
                                            <Form.Group>
                                                <Form.Label>Interview Time</Form.Label>
                                                <Form.Control type="time" name="interview_time" value={formData.interview_time} onChange={handleInputChange} required />
                                            </Form.Group>
                                        </div>
                                    </div>

                                    <div className="call-recording-section border-top mt-4">
                                        <div className="fs-6 fw-bold mb-3 pt-3">Call Recording</div>
                                        <div className="row g-3">
                                            <div className="col-12">
                                                <Form.Group>
                                                    <Form.Label>Type of Call</Form.Label>
                                                    <Form.Select name="interaction_type" onChange={handleInputChange} required>
                                                        <option value="">Select call type</option>
                                                        <option value="outgoing">Outgoing</option>
                                                        <option value="incoming">Incoming</option>
                                                    </Form.Select>
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-6">
                                                <Form.Group>
                                                    <Form.Label>Please upload the call recording of your conversation with this candidate.</Form.Label>
                                                    <label htmlFor="uploadAudioFile" className="w-100 bg-warning bg-opacity-10 border-warning rounded-3 small text-center d-flex flex-column align-items-center justify-content-center px-3 py-2" style={{ border: '1.5px dashed', minHeight: '133px', }}>
                                                        {gethideuploadfile == false ?
                                                            <AudioDropzone />
                                                            : ""}
                                                        {/* {getuploadingfile ? */}
                                                        <div key={getaudiofileName} className="audioFile-cover w-100">
                                                            {getuploadingfile ?
                                                                <>
                                                                    <div className="fs-14 fw-bold d-flex flex-wrap align-items-center justify-content-between gap-1 mb-2">
                                                                        <div className="file-name">{getaudiofileName}</div>
                                                                    </div>
                                                                    <ProgressBar variant="success" animated now={uploadProgress} className="bg-secondary rounded-pill" style={{ height: '20px' }} />
                                                                    <span className="text-danger fw-bold p-0 mt-3 cursor-pointer" onClick={removeFile}>Stop Uploading</span>
                                                                </>
                                                                : ""}
                                                            {getaudiofileUrl != "" && getaudiofileUrl != null ?
                                                                <>
                                                                    <div className="recordingWrapper">
                                                                        <audio controls className="w-100">
                                                                            <source src={getaudiofileUrl} type="audio/mpeg" />
                                                                            Your browser does not support the audio element.
                                                                        </audio>
                                                                    </div>
                                                                    <div className="text-center mt-2">
                                                                        <Link className="text-danger fw-bold p-0" onClick={removeFile}>Remove File</Link>
                                                                    </div>
                                                                </>
                                                                : ""}
                                                        </div>
                                                        {/* : null} */}
                                                    </label>
                                                    {getAudioError && <p className="text-danger fs-14 mt-1">{getAudioError}</p>}
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-6">
                                                <Form.Group>
                                                    <Form.Label className="w-100">Notes About Call <span className="float-end text-muted">100 Characters Min</span></Form.Label>
                                                    <Form.Control as="textarea" rows={5} minLength={100} placeholder="Write Here..."
                                                        name="audio_note"
                                                        value={formData.audio_note}
                                                        onChange={handleInputChange}
                                                        autoComplete="off" required />
                                                </Form.Group>
                                            </div>
                                        </div>
                                        {getaudiofileUrl != "" && getaudiofileUrl != null ?
                                            <div className="check-condition mt-3">
                                                <Form.Check type="checkbox"
                                                    label="I acknowledge of uploading right audio file."
                                                    id="checkAcknowledement"
                                                    checked={getackChecked}
                                                    onChange={handleInputCheck}
                                                />
                                            </div>
                                            : ""}
                                    </div>
                                </div>

                                {isChecking ? <Spinner className="ms-2 border-3" as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : null}

                                <div className="btn-wrapper d-flex flex-wrap justify-content-center gap-3 mt-4">
                                    <Button variant="secondary" className="btn-custom btn px-5 py-2" onClick={handleClose}>Cancel</Button>
                                    <Button type="submit" variant="primary" className="btn-custom btn px-5 py-2" disabled={!getackChecked ? true : false}>Submit
                                        {isProgress ? <Spinner className="ms-2 border-3" as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : null}
                                    </Button>
                                </div>
                            </Form>
                        </Modal.Body>
                    </Modal>
                </div>
                {/* Related Candidates List */}

                {/* Reschedule modal */}
                <Modal show={resheduleshow} backdrop="static" keyboard={false} size="lg" centered scrollable contentClassName="border-0">
                    <Modal.Header closeVariant="white" className="bg-primary text-light px-md-4">
                        <Modal.Title className="fs-6 fw-medium" closeVariant="btn-close-white">Reschedule Interview</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="px-md-4 py-4">
                        {isLoading ? (
                            <Loader />
                        ) : ""}
                        <Form onSubmit={handlerescheduleSubmit}>
                            {/* Start */}
                            <div id="interviewSchedule" className="row g-2 gx-sm-3 mt-1">
                                <div className="col-sm-6 col-lg-4">
                                    <Form.Group>
                                        <Form.Label>Interview Date</Form.Label>
                                        <Form.Control type="date" name="interview_date" min={new Date().toJSON().slice(0, 10)} value={rescheduleformData.interview_date} onChange={handlerescheduleInputChange} required />
                                    </Form.Group>
                                </div>
                                <div className="col-sm-6 col-lg-4">
                                    <Form.Group>
                                        <Form.Label>Interview Time</Form.Label>
                                        <Form.Control type="time" name="interview_time" value={rescheduleformData.interview_time} onChange={handlerescheduleInputChange} required />
                                    </Form.Group>
                                </div>
                                <div className="col-sm-6 col-lg-4">
                                    <Form.Group>
                                        <Form.Label>Interview Round</Form.Label>
                                        <Form.Select name="interview_round" onChange={handlerescheduleInputChange} required>
                                            <option value="">Select Round</option>
                                            <option value="1">Reschedule for Technical Round</option>
                                            <option value="2">Reschedule for One to One with Sheela</option>
                                            <option value="3">Reschedule for Both</option>
                                        </Form.Select>
                                    </Form.Group>
                                </div>
                            </div>
                            {/* End */}
                            <div className="d-flex py-3 px-2 justify-content-center">
                                <Button type="button" variant="secondary" className='focus-ring focus-ring-secondary px-4' onClick={() => closeresModal('outerModal')}>Cancel</Button>
                                <Button type="submit" variant="danger" className='focus-ring focus-ring-danger px-4 mx-2'>Submit</Button>
                            </div>
                        </Form>
                    </Modal.Body>
                </Modal>
                {/* Reschedule modal */}

            </div>
            {/* } */}
            <ToastContainer />
        </div>
    )
}
