import React, {useState} from 'react';
import Avatar from '../../assets/Avatar.png';
import { useMyContext } from '../../context/MyContext';

const InterviewScheduled = ({ scheduleData, senddata, inttype, user }) => {
    // console.log(scheduleData, senddata);
    const { values, handleChange } = useMyContext()
    const [gethrpics, sethrpics] = useState(values.getArrayValue)
    const formatDateandtime = (isoString) => {
        const date = new Date(isoString);
        const options = { day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
        return date.toLocaleString('en-US', options);
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getUTCDate()).padStart(2, '0');
        const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const year = date.getUTCFullYear();

        return `${day}-${month}-${year}`;
    };

    return (
        <>
            <div className="candActivityStatus status-interview-scheduled bg-white small rounded-3 pointer overflow-auto">
                <div class="info-header text-white d-flex flex-wrap justify-content-between column-gap-2 px-3 py-2 py-md-1">
                    <div className="left-sec text-uppercase">
                        <span>REQ ID: VEREQ{scheduleData?.requisition_id}</span>
                        <span className='mx-2'>|</span>
                        <span>TEST ID: {'VEIS' + scheduleData?.id}</span>
                    </div>
                    <div className="right-sec">
                        <div className="test-round fw-semibold">Interview {inttype == "reschedule" ? "Re-scheduled" : "Scheduled"}</div>
                    </div>
                </div>
                <div className="info-body p-3">
                    <div className="row g-3 g-xl-2">
                        <div className="col-12 col-xl-3 item-wrapper d-flex flex-column gap-2">
                            <div className="info-box">
                                <div className="d-flex align-items-center gap-2">
                                    <img src={senddata?.user_photo != "" && senddata?.user_photo != null ? senddata?.user_photo : Avatar} alt="Recruiter" className='img-fluid rounded-circle' style={{ height: '40px', width: '40px' }} />
                                    <div className="cand-info text-capitalize text-truncate">
                                        <div className="candi-name fw-semibold text-truncate">{scheduleData?.user_name}</div>
                                        <div className="candi-company text-truncate">{scheduleData?.current_company}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-3 col-xl item-wrapper d-flex flex-column gap-2">
                            <div className="info-box">
                                <div className="info-label text-clr1 fs-12">{inttype == "reschedule" ? "Re-schedule" : "Schedule"} Date & Time</div>
                                <div className="cand-company text-capitalize">{scheduleData?.interview_date ? formatDate(scheduleData?.interview_date) : ""} {scheduleData?.interview_time}</div>
                            </div>
                        </div>
                        <div className="col-6 col-md-3 col-xl item-wrapper d-flex flex-column gap-2">
                            <div className="info-box">
                                <div className="info-label text-clr1 fs-12">Interview Medium</div>
                                <div className="cand-company text-capitalize">{scheduleData?.interview_medium}</div>
                            </div>
                        </div>
                        <div className="col-6 col-md-3 col-xl item-wrapper d-flex flex-column gap-2">
                            <div className="info-box">
                                <div className="info-label text-clr1 fs-12">Interview Scheduled By</div>
                                <div className="d-flex align-items-center gap-2 mt-1">
                                    <img src={gethrpics[scheduleData?.added_by_email] != "" && gethrpics[scheduleData?.added_by_email] != null ? gethrpics[scheduleData?.added_by_email] : Avatar} alt="Recruiter" className='img-fluid rounded-circle' style={{ height: '30px', width: '30px' }} />
                                    {
                                        user?.userType == "HR" && user.hrType == '1' ?
                                            scheduleData.hrType != 1 ?
                                            <div className="recruiter-name text-capitalize text-truncate">External HR</div>
                                            :
                                            <div className="recruiter-name text-capitalize text-truncate">{scheduleData?.added_by_name}</div>
                                        :
                                        <div className="recruiter-name text-capitalize text-truncate">{scheduleData?.added_by_name}</div>
                                    }
                                    {/* <div className="recruiter-name text-capitalize text-truncate">{scheduleData?.added_by_name}</div> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-3 col-xl item-wrapper d-flex flex-column gap-2">
                            <div className="info-box">
                                <div className="info-label text-clr1 fs-12">Reason</div>
                                <div className="text-capitalize">{scheduleData?.reason_job_change}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


export default InterviewScheduled