import React, { useEffect, useState, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { Rating } from "react-simple-star-rating"
import { UserAuth } from '../context/AuthContext';
import { getAuth } from '../firebaseConfig';
import CryptoJS from 'crypto-js';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import ExportToExcel from '../components/ExportToExcel';
import { Loader } from '../components/loader';
import { useDropzone } from 'react-dropzone';
import PhoneInput from 'react-phone-input-2'
import moment from 'moment';

// import tostify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const HRCandidateDetails = () => {
  const { user, userInfoLoaded, currentUserVal } = UserAuth();
  // const [loading, setIsLoading] = useState(false);
  const [candidateData, setCandidateDetails] = useState([])
  const [userData, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const auth = getAuth();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const encryptedData = searchParams.get('query');
  const bytes = CryptoJS.AES.decrypt(encryptedData, 'secret_key');
  const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  const [getCallData, setCallData] = useState('')
  //new added 
  const [isDisable, setDisable] = useState(true);
  const [selectedCandidate, setCandidate] = useState("");
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [isChecking, setisChecking] = useState(false);
  const [culturalRating, setCulturalRating] = useState(0)
  const [rating, setRating] = useState(0);
  const [error, setError] = useState('');
  const acceptedFileTypes = ['docx', 'pdf', 'doc'];
  const [file, setFile] = useState(null);
  const [show, setShow] = useState(false);
  const [isProgress, setisProgress] = useState(false);
  const [nextStep, setNextStep] = useState('');
  const [note, setNote] = useState('');
  const [statuses] = useState([
    { value: 'Chase Up', label: 'Chase up Call' },
    { value: 'Not Picked', label: 'Not Picked up Call' },
    { value: 'Manager Round', label: 'Promoted for managerial Round' },
    { value: 'Offer Given', label: 'Offer Given' },
    { value: 'Selected', label: 'Got Selected' },
    { value: 'Not Interested', label: 'Not Interested' },
    { value: 'Too Much Salary Expectation', label: 'Too Much Salary Expectation' },
    { value: 'Rejected in Second Round', label: 'Rejected in Second Round' },
  ]);
  const [formData, setFormData] = useState({})
  const handleClose = () => setShow(false);
  const closeStatusModal = () => setShowStatusModal(false);
  const openStatusModal = () => setShowStatusModal(true);

  const updateCTC = (ReqCount) => {
    const splitCTC = (ctcvalue) => {
      let numberPart = '';
      let unitPart = '';
      if (ctcvalue) {
        [numberPart, unitPart] = ctcvalue.trim().split(/\s+/);
      }
      return { numberPart, unitPart };
    };
    const { numberPart: currentNumberPart, unitPart: currentUnitPart } = splitCTC(ReqCount.current_ctc);
    const { numberPart: expectedNumberPart, unitPart: expectedUnitPart } = splitCTC(ReqCount.expected_ctc);
    ReqCount.current_ctc = currentNumberPart;
    ReqCount.current_ctc_scale = currentUnitPart;
    ReqCount.expected_ctc = expectedNumberPart;
    ReqCount.expected_ctc_scale = expectedUnitPart;
    return ReqCount;
  };

  const splitExp = async (getExp) => {
    if (getExp) {
      const splitdata = getExp.split('.')
      return splitdata
    }
  }

  const splitExpView = (getExp) => {
    if (getExp) {
      const splitdata = getExp.split('.')
      const dataYears = splitdata[0] !== undefined && splitdata[0] !== null ? splitdata[0] : '0';
      const dataMonths = splitdata[1] !== undefined && splitdata[1] !== null ? splitdata[1] : '0';
      return dataYears + " years " + dataMonths + " months"
    }
  }

  // new added
  const candidateDetails = async () => {
    const requisitionEmail = decryptedData.email
    try {
      setIsLoading(true);
      const refreshedToken = await currentUserVal?.getIdToken(true);
      let postData = {
        filterBy: "byEmail",
        data: requisitionEmail
      };
      var headers = {
        'Authorization': `Bearer ${refreshedToken}`,
        'Content-Type': 'application/json'
      };
      const response = await axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/requisitions/interview-schecule-search`, postData, { headers })
      const ReqCount = response.data?.interviewScheculeData;
      setCandidateDetails(response.data ? ReqCount : []);
      const getUpdatedValue = await updateCTC(ReqCount);
      const splittotalExp = await splitExp(ReqCount?.experience);
      if (splittotalExp) {
        ReqCount.total_exp_years = splittotalExp[0]
        ReqCount.total_exp_months = splittotalExp[1]
      }
      const splitrelevantExp = await splitExp(ReqCount?.relevant_experience);
      if (splitrelevantExp) {
        ReqCount.relevant_years = splitrelevantExp[0]
        ReqCount.relevant_months = splitrelevantExp[1]
      }
      setFormData(response.data ? ReqCount : [])
      setUsers(response.data ? [ReqCount] : [])
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching requisitions:', error);
      setIsLoading(false);
      setCandidateDetails([]);
      setFormData([])
    }
  }

  useEffect(() => {
    if (auth)
      candidateDetails();
  }, [auth])

  const convertDate = (dateValue) => {
    let date2 = new Date(dateValue).toLocaleDateString()
    return date2
  }

  const InterviewerDetails = async () => {
    setShow(true)
    // getData
    // const requisitionId = decryptedData?.requisitionId
    // try {
    //   setIsLoading(true);
    //   const refreshedToken = await currentUserVal?.getIdToken(true);
    //   let postData = {
    //     interview_schedule_id: formData?.id,
    //     requisition_id: requisitionId
    //   };
    //   var headers = {
    //     'Authorization': `Bearer ${refreshedToken}`,
    //     'Content-Type': 'application/json'
    //   };
    //   const response = await axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/candidates/get-intraction`, postData, { headers })
    //   console.log(response?.data)
    //   console.log("response")
    //   setIsLoading(false);
    // } catch (error) {
    //   console.error('Error fetching requisitions:', error);
    //   setIsLoading(false);
    // }
    // getData
  }
  //Upload resume Function
  const uploadResume = async () => {
    if (error === "") {
      if (file) {
        setIsLoading(true);
        const fileVal = file || {};
        const fileName = file?.name;
        const fileType = file?.type;

        try {
          // Get the Firebase authentication token
          const refreshedToken = await user?.getIdToken(true);

          // Include the token in the request headers
          const headers = {
            'Authorization': `Bearer ${refreshedToken}`,
            'Content-Type': 'multipart/form-data', // Set content type to form data
          };

          const sendformData = new FormData();
          sendformData.append('file', fileVal);
          sendformData.append('bucketName', 'interviewer-profile');
          sendformData.append('key', `/ResumeByHR/${fileName}`);
          sendformData.append('contentType', fileType);
          sendformData.append('isEncoding', false);
          sendformData.append('ContentEncoding', '');
          const response = await axios.post(process.env.REACT_APP_S3UPLOAD, sendformData, { headers });
          setIsLoading(false);
          return response.data.uploadResponse;
        } catch (error) {
          console.error(error);
          setIsLoading(false);
        }
      }
    } else {
      setIsLoading(false);
      setError("Please select appropriate file type and mark agree to upload.");
      return;
    }
  };
  // submit Data
  const handleSubmit = async (e) => {
    e.preventDefault();
    // let candidateData = formData;
    let candidateData = formData
    candidateData.candidate_id = formData.id;
    candidateData.added_by_email = user?.email;
    candidateData.added_by_name = user?.displayName;
    candidateData.added_by_id = user?.uid;
    candidateData.candidate_ques = formData.candidate_query;
    candidateData.job_role = formData.is_job_role_aware;
    // candidateData.contact_no = phone;
    candidateData.comm_skill_rating = rating;
    candidateData.cultural_fit_rating = culturalRating;
    candidateData.resume_url = formData.resume_url
    candidateData.relevant_experience = formData.relevant_years + '.' + formData.relevant_months
    candidateData.experience = formData.total_exp_years + '.' + formData.total_exp_months
    candidateData.current_ctc = formData.current_ctc.trim() + ' ' + formData.current_ctc_scale
    candidateData.expected_ctc = formData.expected_ctc.trim() + ' ' + formData.expected_ctc_scale
    candidateData.salaryNegotiation = formData.is_salary_negotiation;
    candidateData.is_notice_buyout = formData.can_notice_buyout;
    // candidateData.technical_round = formData.interview_medium != 'In Person' ? formData.is_technical : 0;
    // candidateData.one2one_round = formData.interview_medium != 'In Person' ? formData.is_one2one : 0;
    if (file == null) {
      setFile("dataTest")
    }
    if (file) {
      // console.log(file)
      setisProgress(true);
      setFormData(candidateData);
      setCandidateDetails(candidateData)
      //let getToken = await auth?.currentUser?.getIdToken();
      // let getToken = user?.accessToken;
      const refreshedToken = await currentUserVal?.getIdToken(true);
      candidateData.operation = "addcandidate";
      var data = JSON.stringify(candidateData);
      var headers = {
        'Authorization': `Bearer ${refreshedToken}`,
        'Content-Type': 'application/json'
      };
      axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/candidates/update-candidate`, data, { headers })
        .then(function (response) {
          candidateDetails();
          setIsLoading(false);
          setisProgress(false);
          handleClose();
          toast.success("Candidate details updated !!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }).catch(function (error) {
          setisProgress(false);
          setIsLoading(false);
          toast.error("network problem occured!!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        });
    }
  };

  // commonFunction
  const handleDrop = useCallback((acceptedFiles) => {
    setError("");
    const selectedFile = acceptedFiles[0];
    if (!acceptedFileTypes.includes(selectedFile.name.split('.').pop())) {
      setError(`File ${selectedFile.name} has an invalid file type. Only ${acceptedFileTypes.join(', ')} are accepted.`);
      return;
    }

    setFile(selectedFile);
  }, []);

  const handleInputChange = (e) => {
    const { name, type, checked, value } = e.target;
    if (type === 'checkbox') {
      setFormData({
        ...formData,
        [name]: checked ? 1 : 0,
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };
  const handleRating = (rate) => {
    setRating(rate)
  }
  const handleCulturalRating = (rate) => {
    setCulturalRating(rate)
  }
  function Dropzone({ onDrop }) {
    const onDropCallback = useCallback((acceptedFiles) => {
      onDrop(acceptedFiles);
    }, [onDrop]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop: onDropCallback,
      accept: acceptedFileTypes.join(','),
    });

    return (
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <div className='upload-icon mb-2'><i className="bi bi-cloud-arrow-up-fill fs-3 text-success lh-1"></i></div>
        {
          isDragActive ?
            <p>Drop the files here ...</p> :
            <div className='text-muted'>
              <p>Drop your candidate resume here or Browse.<br /> File should be (DOCX, PDF, DOC ) only</p>
              <button className='btn btn-sm btn-success rounded-pill px-3'>Browse File</button>
            </div>
        }
      </div>
    );
  }

  // resumeDld
  const handleOpenUrl = () => {
    const url = userData[0]?.resume_url; // Replace with your desired URL
    window.open(url, '_blank'); // Opens in a new tab
  };
  // resumeDld

  const numbers = [];
  for (let i = 1; i <= 40; i++) {
    numbers.push(i.toString());
  }

  return (
    <div className="requisition-candidate-details py-3">
      <ToastContainer />
      {isLoading ? (
        <Loader />
      ) : ''}
      <div className="container-fluid">
        {/* Details Section */}
        <div className="row g-3 g-xxl-4">
          <div className="col-md-5 col-xl-4 col-xxl-3">
            <div className="left-section card border-0 p-3">
              <div className="main-info text-center mt-2">
                <div className="profile-img mb-2">
                  <img src={require("../assets/Avatar.png")} alt="Candidate Profile" className="img-fluid rounded-pill" style={{ width: "100px", width: "100px" }} />
                </div>
                <div className="candidate-name fs-18 fw-semibold text-capitalize mb-1">{candidateData.user_name ? candidateData.user_name : ''}</div>
                {/* dummy-user.jpeg <div className="applied-date small text-muted mb-2">Applied on 10/12/2023</div> */}
                <div className="social-wrapper">
                  <ul className="list-group list-group-horizontal list-inline justify-content-center gap-2">
                    <li className="">
                      <a href="#" className="btn btn-link d-flex align-items-center justify-content-center bg-secondary text-secondary bg-opacity-10 rounded-pill" style={{ height: "40px", width: "40px" }}><i className="bi bi-envelope fs-18"></i></a>
                    </li>
                    <li>
                      <a href="#" className="btn btn-link d-flex align-items-center justify-content-center bg-secondary text-secondary bg-opacity-10 rounded-pill" style={{ height: "40px", width: "40px" }}><i className="bi bi-calendar2"></i></a>
                    </li>
                    <li>
                      <a href="#" className="btn btn-link d-flex align-items-center justify-content-center bg-secondary text-secondary bg-opacity-10 rounded-pill" style={{ height: "40px", width: "40px" }}><i className="bi bi-telephone"></i></a>
                    </li>
                  </ul>
                </div>
              </div>

              <hr className="divider mx-n3 my-0 border-secondary border-opacity-75" />

              <div className="contact-detail py-3">
                <div className="fw-semibold mb-1">Contact Details</div>
                <div className="d-flex align-items-center gap-2 mt-2 pt-1">
                  <div className="icon">
                    <div className="icon d-flex align-items-center justify-content-center bg-secondary text-secondary bg-opacity-10 rounded-pill" style={{ height: "40px", width: "40px" }}><i className="bi bi-envelope-fill"></i>
                    </div>
                  </div>
                  <div className="info small overflow-hidden">
                    <div className="label-title text-secondary">Email</div>
                    <div className="label-value text-truncate">{candidateData.email ? candidateData.email : ''}</div>
                  </div>
                </div>
                <div className="d-flex align-items-center gap-2 mt-2 pt-1">
                  <div className="icon">
                    <div className="icon d-flex align-items-center justify-content-center bg-secondary text-secondary bg-opacity-10 rounded-pill" style={{ height: "40px", width: "40px" }}><i className="bi bi-telephone-fill"></i>
                    </div>
                  </div>
                  <div className="info small overflow-hidden">
                    <div className="label-title text-secondary">Phone</div>
                    <div className="label-value text-truncate">{candidateData.contact_no ? candidateData.contact_no : ''}</div>
                  </div>
                </div>

              </div>

              <hr className="divider mx-n3 my-0 border-secondary border-opacity-75" />

              <div className="contact-detail py-3">
                <div className="fw-semibold mb-1">Resume</div>
                <div className="d-flex flex-wrap align-items-center gap-2 mt-2 pt-1">
                  <span className="resume-file">
                    <Button type="button" variant="success" class="focus-ring focus-ring-success px-3 btn btn-success" onClick={handleOpenUrl}><i className="bi bi-file-earmark-pdf"></i> View Resume</Button>
                  </span>
                  {/* <span></span>
                  {userData?.length > 0 ?
                    <ExportToExcel data={userData} filename="candidate_data" /> :
                    null
                  } */}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-7 col-xl-8 col-xxl-9">
            <div className="right-section card border-0 p-3">
              <div className="personal-info">
                <div className="d-flex flex-wrap align-items-center justify-content-between gap-2 mb-2">
                  <div className="fs-6 fw-semibold"><i className="bi bi-file-text-fill text-muted"></i> Personal Details</div>
                  <button className="btn btn-sm btn-warning" onClick={() => InterviewerDetails()}><i className="bi bi-pencil-square fs-6"></i> Edit Profile</button>
                </div>
                <div className="row g-3">
                  <div className="col-6 col-xl-4 col-xxl-3">
                    <div className="label-title text-secondary">Full Name</div>
                    <div className="label-value text-capitalize">{candidateData.user_name ? candidateData.user_name : ''}</div>
                  </div>
                  <div className="col-6 col-xl-4 col-xxl-3">
                    <div className="label-title text-secondary">Current Company</div>
                    <div className="label-value text-capitalize">{candidateData.current_company ? candidateData.current_company : ''}</div>
                  </div>
                  <div className="col-6 col-xl-4 col-xxl-3">
                    <div className="label-title text-secondary">Job Status</div>
                    <div className="label-value text-capitalize">{candidateData.job_status ? candidateData.job_status : ''}</div>
                  </div>
                  <div className="col-6 col-xl-4 col-xxl-3">
                    <div className="label-title text-secondary">Current CTC</div>
                    <div className="label-value text-capitalize">{candidateData.current_ctc && candidateData.current_ctc}{candidateData.current_ctc_scale && candidateData.current_ctc_scale}
                      {/* {candidateData.current_ctc ? candidateData.current_ctc+' '+candidateData.current_ctc_scale != undefined : ''} */}
                    </div>
                  </div>
                  <div className="col-6 col-xl-4 col-xxl-3">
                    <div className="label-title text-secondary">Expected CTC</div>
                    <div className="label-value text-capitalize">{candidateData.expected_ctc && candidateData.expected_ctc}{candidateData.expected_ctc_scale && candidateData.expected_ctc_scale}
                      {/* {candidateData.expected_ctc ? candidateData.expected_ctc+' '+candidateData.expected_ctc_scale : ''} */}
                    </div>
                  </div>
                  <div className="col-6 col-xl-4 col-xxl-3">
                    <div className="label-title text-secondary">Total Experience</div>
                    <div className="label-value text-capitalize">{candidateData.experience && splitExpView(candidateData?.experience)}
                      {/* {candidateData.experience ? splitExpView(candidateData?.experience) : ''} */}
                    </div>
                  </div>
                  <div className="col-6 col-xl-4 col-xxl-3">
                    <div className="label-title text-secondary">Relevant Experience</div>
                    <div className="label-value text-capitalize">{candidateData.relevant_experience && splitExpView(candidateData?.relevant_experience)}
                      {/* {candidateData.relevant_experience ? splitExpView(candidateData?.relevant_experience) : ''} */}
                    </div>
                  </div>
                  <div className="col-6 col-xl-4 col-xxl-3">
                    <div className="label-title text-secondary">Notice Period</div>
                    <div className="label-value text-capitalize">{candidateData.notice_period ? candidateData.notice_period : ''}</div>
                  </div>
                  <div className="col-6 col-xl-4 col-xxl-3">
                    <div className="label-title text-secondary">Job Role</div>
                    <div className="label-value text-capitalize">{candidateData.is_job_role_aware ? candidateData.is_job_role_aware : ''}</div>
                  </div>
                  {/* <div className="col-6 col-xl-4 col-xxl-3">
                                        <div className="label-title text-secondary">Date of Birth</div>
                                        <div className="label-value text-capitalize">Feb 18, 1996 <span className="text-secondary">(28 yrs old)</span></div>
                                    </div> */}
                  <div className="col-6 col-xl-4 col-xxl-3">
                    <div className="label-title text-secondary">Possibility of Notice BuyOut</div>
                    <div className="label-value text-capitalize">{candidateData.can_notice_buyout ? candidateData.can_notice_buyout : ''}</div>
                  </div>
                  <div className="col-6 col-xl-4 col-xxl-3">
                    <div className="label-title text-secondary">Possibility of Salary Negotiation</div>
                    <div className="label-value text-capitalize">{candidateData.is_salary_negotiation ? candidateData.is_salary_negotiation : ''}</div>
                  </div>
                  <div className="col-6 col-xl-4 col-xxl-3">
                    <div className="label-title text-secondary">Interview Date</div>
                    <div className="label-value text-capitalize">{candidateData.interview_date ? convertDate(candidateData.interview_date) : ''}</div>
                  </div>
                  <div className="col-6 col-xl-4 col-xxl-3">
                    <div className="label-title text-secondary">Interview Time</div>
                    <div className="label-value text-capitalize">{candidateData.interview_time ? candidateData.interview_time : ''}</div>
                  </div>
                  <div className="col-6 col-xl-4 col-xxl-3">
                    <div className="label-title text-secondary">Interview Medium</div>
                    <div className="label-value text-capitalize">{candidateData.interview_medium ? candidateData.interview_medium : ''}</div>
                  </div>
                  <div className="col-6 col-xl-4 col-xxl-3">
                    <div className="label-title text-secondary">Communication Skills</div>
                    <div className="label-value text-capitalize">
                      <Rating size={22} tooltipClassName="bg-secondary text-light fs-12 rounded-pill lh-1 ms-2 py-1" className="my-1"
                        initialValue={candidateData.comm_skill_rating ? candidateData.comm_skill_rating : ''}
                        allowFraction
                        showTooltip
                        readonly
                        fillColorArray={["#f14f45", "#f16c45", "#f18845", "#f1b345", "#f1d045"]}
                      />
                    </div>
                  </div>
                  <div className="col-6 col-xl-4 col-xxl-3">
                    <div className="label-title text-secondary">Cultural Fit</div>
                    <div className="label-value text-capitalize">
                      <Rating size={22} tooltipClassName="bg-secondary text-light fs-12 rounded-pill lh-1 ms-2 py-1" className="my-1"
                        initialValue={candidateData.cultural_fit_rating ? candidateData.cultural_fit_rating : ''}
                        allowFraction
                        showTooltip
                        readonly
                        fillColorArray={["#f14f45", "#f16c45", "#f18845", "#f1b345", "#f1d045"]}
                      />
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="label-title text-secondary">Reason for Cultural Fit</div>
                    <div className="label-value">
                      {candidateData.cultural_fit ? candidateData.cultural_fit : ''}
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="label-title text-secondary">Reason For Job Change</div>
                    <div className="label-value">
                      {candidateData.reason_job_change ? candidateData.reason_job_change : ''}
                    </div>
                  </div>
                </div>
              </div>

              <hr className="divider border-secondary border-opacity-75" />

              <div className="description-info">
                <div className="fs-6 fw-semibold mb-2"><i className="bi bi-question-circle-fill text-muted"></i> Candidate Questions</div>
                <div className="label-value text-muted">
                  {candidateData.candidate_query ? candidateData.candidate_query : ''}
                </div>
              </div>

              <hr className="divider border-secondary border-opacity-75" />


            </div>
          </div>
        </div>
        {/* Edit Candidate Details */}
        <Modal show={show} onHide={handleClose} centered backdrop="static" keyboard={false} size="xl" contentClassName="border-0 border-5 border-top border-primary rounded-0 rounded-bottom">
          <Modal.Body className="modal-body px-3 px-xl-5 py-4">
            <h1 className="modal-title fs-4 fw-fw-semibold text-center mb-3" id="addCandidate">Update Candidate Details</h1>
            <Form onSubmit={handleSubmit}>
              <div className="row g-2 gx-sm-3">
                <div className="col-sm-6 col-lg-4">
                  <Form.Group>
                    <Form.Label>Contact</Form.Label>
                    <PhoneInput
                      country={"in"}
                      countryCodeEditable={false}
                      inputclassName="w-100 h-100 border"
                      autoComplete={'false'}
                      value={formData?.contact_no}
                      disabled={isDisable}
                      // onChange={handleOnChangeNumber}
                      inputProps={{
                        name: 'phone',
                        autoComplete: 'off',
                        required: true
                      }}
                      style={{ height: '45px' }}
                    />
                    {/* <span className="d-block">{candidateData.contact_no}</span> */}
                    {/* <div className="small text-danger mt-1">{checkMessage}</div> */}
                  </Form.Group>
                </div>
                <div className="col-sm-6 col-lg-4">
                  <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email"
                      name="email"
                      value={formData?.email}
                      onChange={handleInputChange}
                      pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                      autoComplete="off"
                      disabled={isDisable}
                      // onBlur={checkEmail}
                      required />
                  </Form.Group>
                </div>
                <div className="col-lg-4">
                  <Form.Group>
                    <Form.Label>Source Of Candidate </Form.Label>
                    <Form.Select name="source_of_candidate" onChange={handleInputChange} required
                    // disabled={otherVisibility}
                    >
                      {/* <option value={""}>Select Source</option> */}
                      <option value={formData?.source_of_candidate}>{formData?.source_of_candidate}</option>
                      <option value={"job_boards"}>Job Boards (e.g., Indeed, Monster need text box)</option>
                      <option value={"company_website"}>Company Website</option>
                      <option value={"social_media"}>Social Media (e.g., LinkedIn, Twitter, Facebook)</option>
                      <option value={"employee_referral"}>Employee Referral</option>
                      <option value={"recruitment_agencies"}>Recruitment Agencies</option>
                      <option value={"campus_recruitment"}>Campus Recruitment</option>
                      <option value={"networking_events"}>Networking Events</option>
                      <option value={"internal_application"}>Internal Application</option>
                      <option value={"headhunting"}>Headhunting</option>
                      <option value={"others"}>Others</option>
                    </Form.Select>
                  </Form.Group>
                </div>
              </div>

              <div className={""}>
                {/* Verification of Basic Details Section */}
                <div id="basicDetails" className="row g-2 gx-sm-3 mt-1">
                  <div className="col-sm-6 col-lg-4">
                    <Form.Group>
                      <Form.Label>Full Name</Form.Label>
                      <Form.Control type="text" name="user_name" value={formData?.user_name} onChange={handleInputChange} autoComplete="off" required />
                    </Form.Group>
                  </div>
                  <div className="col-sm-6 col-lg-4">
                    <Form.Group>
                      <Form.Label>Current Company</Form.Label>
                      <Form.Control type="text" name="current_company" value={formData?.current_company} onChange={handleInputChange} autoComplete="off" required />
                    </Form.Group>
                  </div>
                  <div className="col-lg-4">
                    <Form.Group>
                      <Form.Label>Current Role/Designation</Form.Label>
                      <Form.Control type="text"
                        name="is_job_role_aware"
                        placeholder="Role/Designation"
                        value={formData?.is_job_role_aware}
                        onChange={handleInputChange}
                        autoComplete="off" required />
                    </Form.Group>
                  </div>
                  <div className="col-sm-6 col-xl-4">
                    <Form.Group>
                      <Form.Label>Relevant Experience</Form.Label>
                      <div className="d-flex gap-2">
                        <Form.Select name="relevant_years" onChange={handleInputChange} required>
                          <option value={formData?.relevant_years}>{formData?.relevant_years}</option>
                          {numbers.map((number, index) => (
                            <>
                              {number != formData?.relevant_years ?
                                <option value={number}>{number}</option> : ""
                              }
                            </>
                          ))}
                        </Form.Select>
                        <Form.Select name="relevant_months" onChange={handleInputChange} required>
                          <option value={formData?.relevant_months}>{formData?.relevant_months}</option>
                          {numbers.map((number, index) => (
                            <>
                              {number != formData?.relevant_months && number <= 11 ?
                                <option value={number}>{number}</option> : ""
                              }
                            </>
                          ))}
                        </Form.Select>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-sm-6 col-xl-4">
                    <Form.Group>
                      <Form.Label>Total Experience</Form.Label>
                      <div className="d-flex gap-2">
                        <Form.Select name="total_exp_years" onChange={handleInputChange} required>
                          <option value={formData?.total_exp_years}>{formData?.total_exp_years}</option>
                          {numbers.map((number, index) => (
                            <>
                              {number != formData?.total_exp_years ?
                                <option value={number}>{number}</option> : ""
                              }
                            </>
                          ))}
                        </Form.Select>
                        <Form.Select name="total_exp_months" onChange={handleInputChange} required>
                          <option value={formData?.total_exp_months}>{formData?.total_exp_months}</option>
                          {numbers.map((number, index) => (
                            <>
                              {number != formData?.total_exp_months && number <= 11 ?
                                <option value={number}>{number}</option> : ""
                              }
                            </>
                          ))}
                        </Form.Select>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-12">
                    <Form.Group className="d-flex flex-wrap align-items-center column-gap-4 mt-2">
                      <Form.Label>Job Status</Form.Label>
                      <div className="radio-wrapper ms-2 ps-1">
                        <Form.Check type="radio" inline className="me-xl-4"
                          label="In Job"
                          value="In Job"
                          name="job_status"
                          checked={formData?.job_status == 'In Job' ? true : false}
                          onChange={handleInputChange}
                          id="inline-radio-1"
                          required
                        />
                        <Form.Check type="radio" inline className="me-xl-4"
                          label="Notice Period"
                          name="job_status"
                          value="Notice Period"
                          checked={formData?.job_status == 'Notice Period' ? true : false}
                          id="inline-radio-2"
                          onChange={handleInputChange}
                        />
                        <Form.Check type="radio" inline className="me-xl-4"
                          label="Nowhere Working"
                          name="job_status"
                          value="Nowhere Working"
                          checked={formData?.job_status == 'Nowhere Working' ? true : false}
                          id="inline-radio-3"
                          onChange={handleInputChange}
                        />
                      </div>
                    </Form.Group>
                  </div>
                </div>

                {/* Suitability and Expectations Section */}
                <div id="interviewSchedule" className="row g-2 gx-sm-3 mt-1">
                  <div className="col-sm-6 col-xl-4">
                    <Form.Group>
                      <Form.Label>Current CTC</Form.Label>
                      <div className="d-flex gap-2">
                        <Form.Control type="text" name="current_ctc" placeholder="CTC" value={formData.current_ctc} onChange={handleInputChange} autoComplete="off" required />
                        <Form.Select name="current_ctc_scale" onChange={handleInputChange} required>
                          <option value={formData.current_ctc_scale}>{formData.current_ctc_scale}</option>
                          {["Thousands", "Lakhs", "Crore"].map((option) => {
                            if (option === formData?.current_ctc_scale) return null;
                            return <option key={option} value={option}>{option}</option>;
                          })}
                        </Form.Select>
                      </div>
                    </Form.Group>
                    {/* {errors.current_ctc && <div className="text-danger">{errors.current_ctc}</div>} */}
                  </div>
                  <div className="col-sm-6 col-xl-4">
                    <Form.Group>
                      <Form.Label>Expected CTC</Form.Label>
                      <div className="d-flex gap-2">
                        <Form.Control type="text" name="expected_ctc" placeholder="CTC" value={formData.expected_ctc} onChange={handleInputChange} autoComplete="off" required />
                        <Form.Select name="expected_ctc_scale" onChange={handleInputChange} required>
                          <option value={formData.expected_ctc_scale}>{formData.expected_ctc_scale}</option>
                          {["Thousands", "Lakhs", "Crore"].map((option) => {
                            if (option === formData?.expected_ctc_scale) return null;
                            return <option key={option} value={option}>{option}</option>;
                          })}
                        </Form.Select>
                      </div>
                    </Form.Group>
                    {/* {errors.expected_ctc && <div className="text-danger">{errors.expected_ctc}</div>} */}
                  </div>
                  {/* <div className="col-sm-6 col-lg-4">
                    <Form.Group>
                      <Form.Label>Current CTC</Form.Label>
                      <Form.Control type="text" name="current_ctc" value={formData?.current_ctc} onChange={handleInputChange} autoComplete="off" required />
                    </Form.Group>
                  </div>
                  <div className="col-sm-6 col-lg-4">
                    <Form.Group>
                      <Form.Label>Expected CTC</Form.Label>
                      <Form.Control type="text" name="expected_ctc" value={formData?.expected_ctc} onChange={handleInputChange} autoComplete="off" required />
                    </Form.Group>
                  </div> */}
                  {/* <div className="col-sm-6 col-lg-4">
                    <Form.Group>
                      <Form.Label>Experience</Form.Label>
                      <Form.Control type="text" name="experience" value={formData?.experience} onChange={handleInputChange} autoComplete="off" required />
                    </Form.Group>
                  </div> */}
                  <div className="col-12 col-xl-4">
                    <Form.Group>
                      <Form.Label>Notice Period</Form.Label>
                      <Form.Select name="notice_period" onChange={handleInputChange} required>
                        <option value={formData?.notice_period}>{formData?.notice_period}</option>
                        {["Immidiate Joiner", "15 Days", "1 Month", "2 Month", "3 Month"].map((option) => {
                          if (option === formData?.notice_period) return null;
                          return <option key={option} value={option}>{option}</option>;
                        })}
                      </Form.Select>
                    </Form.Group>
                  </div>
                  {/* <div className="col-sm-6 col-lg-4">
                    <Form.Group>
                      <Form.Label>Notice Period</Form.Label>
                      <Form.Select name="notice_period" onChange={handleInputChange} required>
                        <option value={formData?.notice_period}>{formData?.notice_period}</option>
                        <option value="Immidiate Joiner">Immidiate Joiner</option>
                        <option value="15 Days">15 Days</option>
                        <option value="1 Month">1 Month</option>
                        <option value="2 Month">2 Month</option>
                        <option value="3 Month">3 Month</option>
                      </Form.Select>
                    </Form.Group>
                  </div> */}
                  <div className="col-12">
                    <Form.Group className="d-flex align-items-center flex-wrap column-gap-4 pt-2">
                      <Form.Label>Negotiation Possible?</Form.Label>
                      <div className="">
                        <Form.Check type="radio" inline className="me-xl-4"
                          name="is_salary_negotiation"
                          label="Yes"
                          value="Yes"
                          checked={formData.is_salary_negotiation == 'Yes' ? true : false}
                          onChange={handleInputChange}
                          id="negotiationPossible1"
                          required
                        />
                        <Form.Check type="radio" inline className="me-xl-4"
                          name="is_salary_negotiation"
                          label="No"
                          value="No"
                          checked={formData.is_salary_negotiation == 'No' ? true : false}
                          onChange={handleInputChange}
                          id="negotiationPossible2"
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-12">
                    <Form.Group className="d-flex align-items-center flex-wrap column-gap-4">
                      <Form.Label>Buyout Possible?</Form.Label>
                      <div className="">
                        <Form.Check type="radio" inline className="me-xl-4"
                          label="Yes"
                          value="Yes"
                          name="can_notice_buyout"
                          checked={formData.can_notice_buyout == 'Yes' ? true : false}
                          onChange={handleInputChange}
                          id="buyoutPossible1"
                          required
                        />
                        <Form.Check type="radio" inline className="me-xl-4"
                          label="No"
                          value="No"
                          name="can_notice_buyout"
                          checked={formData.can_notice_buyout == 'No' ? true : false}
                          onChange={handleInputChange}
                          id="buyoutPossible2"
                        />
                      </div>
                    </Form.Group>
                  </div>
                </div>

                {/* Upload Section */}
                <div id="quesAndResume" className="row g-2 gx-sm-3 mt-1">
                  {/* <div className="col-sm-6">
                    <Form.Group>
                      <Form.Label>Candidate Questions</Form.Label>
                      <Form.Control as="textarea" rows={5} name="candidate_query"
                        value={formData?.candidate_query}
                        onChange={handleInputChange}
                        autoComplete="off" required />
                    </Form.Group>
                  </div> */}
                  <div className="col-lg-6">
                    <Form.Group>
                      <Form.Label className="w-100">Reason For Job Change <span className="float-end text-muted">100 Words Min</span></Form.Label>
                      <Form.Control as="textarea" rows={5} placeholder="Write Here..."
                        name="reason_job_change"
                        value={formData.reason_job_change}
                        onChange={handleInputChange}
                        autoComplete="off" required />
                    </Form.Group>
                  </div>
                  <div className="col-sm-6">
                    <Form.Group>
                      <Form.Label>Upload File</Form.Label>
                      <label htmlFor="uploadResume" className="w-100 bg-warning bg-opacity-10 border-warning rounded-3 small text-center py-2" style={{ border: '1.5px dashed' }}>
                        <Dropzone acceptedFiles={['application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/pdf', 'application/msword']} onDrop={handleDrop} />
                        {file?.name != undefined ?
                          <p key={file.name} className='bg-light border py-2 px-3 rounded-pill small text-truncate mx-auto mt-3' style={{ maxWidth: '300px' }}>
                            {file.name} ({file.size} bytes)
                          </p> : null
                        }
                      </label>
                      {error && <p className="small text-danger mt-2">{error}</p>}
                    </Form.Group>
                  </div>
                </div>
                {/* Rating Section */}
                <div id="culturalFit" className="row g-2 gx-sm-3 mt-1">

                  <div className="col-sm-6 col-lg-3">
                    <Form.Group>
                      <Form.Label>Communication Skills</Form.Label>
                      <div className='start-rating d-flex align-items-center gap-3 ps-2 ms-1'>
                        <Rating onClick={handleRating} size={35}
                          // initialValue={rating}
                          initialValue={formData?.comm_skill_rating}
                          allowFraction
                          // showTooltip 
                          fillColorArray={['#f14f45', '#f16c45', '#f18845', '#f1b345', '#f1d045']}
                        />
                      </div>
                    </Form.Group>
                  </div>

                  <div className="col-sm-6 col-lg-3">
                    <Form.Group>
                      <Form.Label>Cultural Fit</Form.Label>
                      <div className='start-rating d-flex align-items-center gap-3 ps-2 ms-1'>
                        <Rating onClick={handleCulturalRating} size={35}
                          // initialValue={culturalRating}
                          initialValue={formData?.cultural_fit_rating}
                          allowFraction
                          // showTooltip 
                          fillColorArray={['#f14f45', '#f16c45', '#f18845', '#f1b345', '#f1d045']}
                        />
                      </div>
                    </Form.Group>
                  </div>

                  <div className="col-lg-6">
                    <Form.Group>
                      <Form.Label>Reason for Cultural Fit</Form.Label>
                      <Form.Control as="textarea" placeholder="Write Here..."
                        rows={4} name="cultural_fit"
                        autoComplete="off"
                        onChange={handleInputChange}
                        value={formData?.cultural_fit}
                        required />
                    </Form.Group>
                  </div>
                </div>
                {/* Interview Schedule Section */}
                <div id="interviewSchedule" className="row g-2 gx-sm-3 mt-1">
                  {/* <div className="col-12">
                    <Form.Group className="d-flex align-items-center flex-wrap column-gap-3 pt-2">
                      <Form.Label>Interview Medium</Form.Label>
                      <div className="radio-wrapper ms-2 ps-1">
                        <Form.Check type="radio" inline className="me-xl-4"
                          label="NM Interview"
                          value="NM Interview"
                          name="interview_medium"
                          checked={formData.interview_medium == 'NM Interview' ? true : false}
                          onChange={handleInputChange}
                          id="interviewMedium1"
                          required
                        />
                        <Form.Check type="radio" inline className="me-xl-4"
                          label="In Person"
                          value="In Person"
                          name="interview_medium"
                          checked={formData.interview_medium == 'In Person' ? true : false}
                          id="interviewMedium2"
                          onChange={handleInputChange}
                        />
                        <Form.Check type="radio" inline className="me-xl-4"
                          label="Other Interview Tool"
                          value="Other Interview Tool"
                          name="interview_medium"
                          checked={formData.interview_medium == 'Other Interview Tool' ? true : false}
                          id="interviewMedium3"
                          onChange={handleInputChange}
                        />
                      </div>
                    </Form.Group>
                  </div> */}
                  {/* {formData.interview_medium != 'In Person' ?
                    <div className="col-12">
                      <Form.Group className="d-flex align-items-center flex-wrap column-gap-4">
                        <Form.Label>Interview rounds</Form.Label>
                        <div className="checks-wrapper ms-2 ps-1">
                          <Form.Check type="checkbox" inline className="me-xl-4"
                            label="Coding Round"
                            id="interviewRounds1"
                            name="is_technical"
                            checked={formData.is_technical == 1 ? true : false}
                            onChange={handleInputChange}
                          />
                          <Form.Check type="checkbox" inline className="me-xl-4"
                            label="One to One with Sheela"
                            id="interviewRounds2"
                            name="is_one2one"
                            checked={formData.is_one2one == 1 ? true : false}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Form.Group>
                    </div>
                    : ""} */}
                  {/* <div className="col-sm-6 col-lg-4">
                    <Form.Group>
                      <Form.Label>Interview Date</Form.Label>
                      <Form.Control type="date" name="interview_date" value={moment(formData?.interview_date).format('YYYY-MM-DD')} onChange={handleInputChange} />
                    </Form.Group>
                  </div>
                  <div className="col-sm-6 col-lg-4">
                    <Form.Group>
                      <Form.Label>Interview Time</Form.Label>
                      <Form.Control type="time" name="interview_time" value={formData?.interview_time} onChange={handleInputChange} required />
                    </Form.Group>
                  </div> */}

                </div>

                {/* call Recording */}
                {/* <div className="call-recording-section border-top mt-4 d-none">
                  <div className="fs-6 fw-bold mb-3 pt-3">Call Recording</div>
                  <div className="row g-3">
                    <div className="col-12">
                      <Form.Group>
                        <Form.Label>Type of Call</Form.Label>
                        <Form.Select name="interaction_type" onChange={handleInputChange} required>
                          <option value="">Select call type</option>
                          <option value="outgoing">Outgoing</option>
                          <option value="incoming">Incoming</option>
                        </Form.Select>
                      </Form.Group>
                    </div>
                    <div className="col-lg-6">
                      <Form.Group>
                        <Form.Label className="w-100">Notes About Call <span className="float-end text-muted">25 Words Min</span></Form.Label>
                        <Form.Control as="textarea" rows={5} placeholder="Write Here..."
                          name="audio_note"
                          value={formData.audio_note}
                          onChange={handleInputChange}
                          autoComplete="off" required />
                      </Form.Group>
                    </div>
                  </div>
                </div> */}
                {/* call Recording */}
              </div>
              {isChecking ? <Spinner className="ms-2 border-3" as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : null}
              <div className="btn-wrapper text-center mt-4">
                <Button variant="secondary" className="px-3 me-2" onClick={handleClose}>Cancel</Button>
                <Button variant="primary" type="submit" className="px-3">Submit
                  {isProgress ? <Spinner className="ms-2 border-3" as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : null}
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
        {/* Edit Candidate Details */}
      </div>
    </div>
  )
}
