import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import makeAnimated from 'react-select/animated';
import { useLocation } from 'react-router-dom';

// Quill Editor
import "react-quill/dist/quill.snow.css";
import { SubmitRequisitionModal } from "./SubmitRequisitionModal";
import { Technicaldata } from "./Components/Technical";
import { NonTechnical } from "./Components/NonTechnical";
import { Jobdescription } from "./Components/JobDescription";

export const NewRequisition = () => {
    const animatedComponents = makeAnimated();
    // For Selected Test Round Option
    const [selectedTest, setSelectedTest] = useState('Technical');
    const [getallowFunc, setallowFunc] = useState('')
    const [getPreflang, setPreflang] = useState('')
    const [updateallow, setUpdateallow] = useState(false)
    const [updatePreflang, setUpdatePreflang] = useState("")
    const location = useLocation();
    const [info, setInfo] = useState(null)
    const query = new URLSearchParams(location.search);
    const encodedInfo = query.get('q');
    // let info = null;
    const getValueforcreate = async () => {
        try {
            const infodata = encodedInfo ? JSON.parse(atob(encodedInfo)) : null;
            setInfo(infodata);
            // setPreflang(infodata?.skills)
            // console.log(infodata)
        } catch (error) {
            console.error("Error decoding query parameter:", error);
        }
    }

    const handleOptionChange = (event) => {
        setSelectedTest(event.target.value);
        allowFunc(false)
        setPreflang('')
    };

    const allowFunc = (data) => {
        setallowFunc(data)
        setUpdateallow(data)
    }

    const prefLang = (data) => {
        setPreflang(data)
        setUpdatePreflang(data)
    }

    const dataSubmitted = () => {
        setallowFunc(false)
        setPreflang('')
        setUpdateallow("allow")
        setUpdatePreflang("pref")
    }

    useEffect(() => {

    }, [updateallow, updatePreflang])

    useEffect(() => {
        getValueforcreate()
    }, [encodedInfo])

    return (
        <div className="NewRequisition-page mt-3 mb-4">
            <div className="container-fluid px-xxl-5">
                <div className="fs-3 fw-bold lh-sm mb-3">New Requisition</div>
                <div className="bg-light rounded p-4 p-xl-5">
                    <div className="mx-auto" style={{ maxWidth: '1200px' }}>
                        <Form method="post">
                            <div className="check-wrapper fs-5 fw-medium mb-3">
                                <Form.Check inline id="Technical" className="me-4 pe-3">
                                    <Form.Check.Input type="radio" name="intType" className="border border-secondary-subtle" value="Technical" checked={selectedTest === 'Technical'} onChange={handleOptionChange} />
                                    <Form.Check.Label>Technical</Form.Check.Label>
                                </Form.Check>
                                <Form.Check inline id="NonTechnical" className="me-4 pe-3">
                                    <Form.Check.Input type="radio" name="intType" className="border border-secondary-subtle" value="Non-Technical" checked={selectedTest === 'Non-Technical'} onChange={handleOptionChange} />
                                    <Form.Check.Label>Non Technical</Form.Check.Label>
                                </Form.Check>
                            </div>

                            {selectedTest === 'Technical' ? (
                                <Technicaldata allowFuncData={allowFunc} getprefLang={prefLang} updateallow={updateallow} updatePreflang={updatePreflang} />
                            ) : (
                                <NonTechnical />
                            )}
                            <Jobdescription getallowFuncdata={getallowFunc} getSelectedTest={selectedTest} getprefLangdata={getPreflang} getdataSubmitted={dataSubmitted}  info={info} />
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    )
}
