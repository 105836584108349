import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import 'react-medium-image-zoom/dist/styles.css';
import ModalImage from "react-modal-image";
import Modal from 'react-bootstrap/Modal';
import { Tab, Tabs } from 'react-bootstrap';
import { Loader } from '../components/loader';
import CryptoJS from 'crypto-js';
import { getAuth } from '../firebaseConfig';
import axios from 'axios';
import AWS from 'aws-sdk';
import DocumentViewer from '../components/DocumentViewer';
import moment from 'moment';
import { UserAuth } from '../context/AuthContext';
import useImage from '../assets/Avatar.png'
import { useMyContext } from '../context/MyContext';

export const UserDetails = () => {
  const { values, handleChange } = useMyContext()
  const [show, setShow] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [quesList, setQuesList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [userParam, setUserParam] = useState(null);
  const [is_profile_verified, setProfileInfo] = useState(null);
  const [videoUrl, setVideoUrl] = useState('');
  const [loginToken, setLoginToken] = useState('');
  const [fileType, setFileType] = useState('');
  const [candidateTestList, setCandidateTestList] = useState([]);


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate();
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search);
  const encryptedQuery = queryParams.get('query');
  const { user, userInfoLoaded, currentUserVal } = UserAuth();

  const auth = getAuth();
  const lambda = new AWS.Lambda({
    region: 'us-east-1',
    accessKeyId: process.env.REACT_APP_ACCESSKEYID,
    secretAccessKey: process.env.REACT_APP_ACCESSKEY,
  });
  // Decrypt the query string
  const bytes = CryptoJS.AES.decrypt(encryptedQuery, 'secret_key');
  const decryptedQuery = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));


  // Fetch Test Questions and Reason
  const fetchTestInfo = async (candidateEmail) => {
    setIsLoading(true);
    try {
      const refreshedToken = await currentUserVal?.getIdToken(true);
      var collectdata = {
        "candidateEmail": candidateEmail
      };
      if (user?.userType == "MGR" || user?.userType == "SMGR") {
        collectdata.userType = user?.userType;
        collectdata.managerEmail = user?.email;
      }
      var data = JSON.stringify(collectdata);
      var headers = {
        'Authorization': `Bearer ${refreshedToken}`,
        'Content-Type': 'application/json'
      };
      const response = await axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/interviews/get-candidate-Allinterview`, data, { headers });
      let testDatares = response.data;
      let dataRes = testDatares.interviews?.length > 0 ? testDatares.interviews : [];
      // console.log("Test All Data List");
      // console.log(dataRes);
      setCandidateTestList(dataRes);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }


  useEffect(() => {
    // console.log(decryptedQuery)
    // console.log("decryptedQuery")
    setUserDetails(decryptedQuery);
    if (decryptedQuery?.email) {
      fetchTestInfo(decryptedQuery?.email)
    }
  }, [])

  const InterviewerDetails = (info) => {
    if (info?.test_type == "2")
      navigate('/sheela-test-details', { state: info });
    else
      navigate('/test-details', { state: info });
  }

  function getFileExtensionFromUrl(url) {
    const parts = url.split('.');
    const extension = parts[parts.length - 1];
    setFileType(extension);
  }
  const fetchUserInfo = async (email) => {
    setIsLoading(true);
    const refreshedToken = await currentUserVal?.getIdToken(true);
    var data = JSON.stringify({
      "operation": "fetchUserInfo",
      "email": email,
    });
    var headers = {
      'Authorization': `Bearer ${refreshedToken}`,
      'Content-Type': 'application/json'
    };
    axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/candidates/get-user-details`, data, { headers })
      .then(function (response) {
        let userDatares = response.data;
        let data = userDatares.data?.length > 0 ? userDatares?.data[0] : {};
        if (data?.resume_url != "")
          getFileExtensionFromUrl(data?.resume_url);
        let complitionCount = data?.is_number_verified + data?.is_video_verified + data?.is_profile_verified + data?.is_employement_added + data?.is_edu_added + data?.resume_headline_added
        if (complitionCount == 6) {
          setProfileInfo(true);
        }
        else {
          setProfileInfo(false);
        }
        setIsLoading(false);
        setLoginToken(data?.login_token);

        setUserDetails(data);
      }).catch(function (error) {
        // console.log(error);
        setIsLoading(false);
      });
  }


  useEffect(() => {
    setUserParam(decryptedQuery);
    if (decryptedQuery?.email) {
      fetchUserInfo(decryptedQuery?.email);
      let updatedFilterData = {
        candidateEmail: decryptedQuery?.email
      };
    }
  }, [])


  const [key, setKey] = useState('home');

  // getCurrentPathname
  useEffect(() => {
    const currentUrl = window.location.href;
    const currentPath = window.location.pathname
    handleChange("getPagePath",currentPath)
  }, []);
  // getCurrentPathname

  return (
    <div className='interview-det-page user-details py-3'>
      <div className="container-fluid">

        {/* Candidate Profile Details */}
        <div className='card p-3 p-lg-4'>
          <div className="candidate-details">
            <div className="row align-items-center">
              <div className="col-md-7 mt-2 mt-md-0">
                <div className="profile-img">
                  <img src={userDetails?.user_photo != undefined && userDetails?.user_photo != '' ? userDetails?.user_photo : useImage} referrerPolicy="no-referrer" alt="Candidate" className="img-fluid border border-3 border-primary rounded-circle object-fit-cover shadow mb-2" style={{ width: '110px', height: '110px' }} />
                </div>
                <div className="cand-name text-capitalize mb-1">
                  <span className='fs-18 fw-medium'>{userParam?.candidate_name}</span>
                  <span className='small text-muted ms-1'>{userDetails?.job_title}</span>
                </div>

                <div className='d-flex flex-wrap row-gap-2 column-gap-3 mb-1'>
                  <div className="cand-email d-flex align-items-center gap-2 text-muted" title='Email'>
                    <i className="bi bi-envelope-fill text-secondary"></i>{userDetails?.email}
                  </div>
                  <div className='cand-contact d-flex align-items-center gap-2 text-muted' title='Contact Number'>
                    <i className="bi bi-telephone-fill text-secondary"></i> {userDetails?.contact_no}
                  </div>
                  <div className='cand-date d-flex align-items-center gap-2 text-muted' title='Registration Date'>
                    <i className="bi bi-calendar-check-fill text-secondary"></i>{moment(userDetails?.created_at).utc().format('YYYY-MM-DD HH:mm:ss A')}
                  </div>
                  <div className='cand-duration d-flex align-items-center gap-2 text-muted' title='Last login date'>
                    <i className="bi bi-person-fill-exclamation fs-4 text-secondary lh-1"></i> {moment(userDetails?.login_datetime).utc().format('YYYY-MM-DD HH:mm:ss A')}
                  </div>
                </div>

                {/* <div className='d-flex flex-wrap align-items-center row-gap-1 column-gap-3 mb-2'>
                  <div className='fw-medium'>Attempt | Correct | Incorrect:</div>
                  <div className='d-flex align-items-center gap-4'>
                    <div className='fw-medium'><i className="bi bi-clipboard-check-fill text-muted"></i>{totalQuesattemp}</div>
                    <div className='fw-medium'><i className="bi bi-check-circle-fill text-success"></i>{rightCount}</div>
                    <div className='fw-medium'><i className="bi bi-x-circle-fill text-danger"></i>{wrongCount}</div>
                  </div>
                </div> */}

                <div className='d-flex flex-wrap gap-2 border-top pt-2'>
                  <div className='working-status'>
                    <div className='form-label'>Registration Status</div>
                    <span className={`badge fs-6 fw-normal border border-opacity-50 border-2 rounded-pill text-capitalize px-3 ${is_profile_verified === true ? 'text-bg-success' : 'text-bg-secondary' && is_profile_verified === false ? 'text-bg-danger' : 'text-bg-success'}`} style={{ minWidth: '90px' }}>{is_profile_verified == true ? "Completed" : "Incomplete"}</span>
                  </div>
                  <div className="vr mx-3"></div>

                </div>
              </div>

              <div className='col-md-5 mt-3 mt-md-0'>
                <div className="fs-18 fw-medium text-capitalize mb-2"><i className="bi bi-film small text-primary"></i> Registration video recording -</div>
                <div className="zoom-wrapper ratio ratio-16x9 overflow-hidden" style={{ maxWidth: '500px' }}>
                  <video className='border rounded w-100 d-block object-fit-cover' height="240" controls autoPlay>
                    <source src={`https://interviewer-profile.s3.amazonaws.com/${decryptedQuery?.login_token}/videos/${decryptedQuery?.login_token}.webm`} type="video/webm" />
                    Your browser does not support the video tag.
                  </video>
                  <div className="relative-content bg-dark bg-opacity-75" onClick={handleShow}>
                    <i className="bi bi-arrows-fullscreen me-1"></i> Inlarge View
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Video Fullscreen Model Popup */}
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size="xl" scrollable centered contentClassName="border-0 shadow">
          <Modal.Header closeButton closeVariant="white" className='bg-primary bg-gradient fs-18 text-white text-capitalize lh-sm'>
            <div className='text-white'><i className="bi bi-film small"></i> Registration Video Recording</div>
          </Modal.Header>
          <Modal.Body>
            <div className="ratio ratio-16x9 overflow-hidden">
              <video className='border rounded w-100 d-block object-fit-cover' controls autoPlay>
                <source src={`https://interviewer-profile.s3.amazonaws.com/${decryptedQuery?.login_token}/videos/${decryptedQuery?.login_token}.webm`} type="video/webm" />
                Your browser does not support the video tag.
              </video>


            </div>
          </Modal.Body>
        </Modal>

        {/* Nav & Tabs Section Code For Details */}
        <div className="tab-sec-wrapper interviewList-page bg-white mt-3">
          <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)} justify className="custom-tabs small fw-medium border-bottom" variant="pills">
            <Tab eventKey="home" title={<><i className="bi bi-file-earmark-text"></i> Basic Details</>}>
              <div className="row g-3">
                <div className="col-md-5">
                  <div className="card h-100 overflow-hidden mb-3">
                    <div className='card-title bg-light fw-medium border-bottom m-0'><i className="bi bi-person-badge me-1"></i> Personal Information</div>
                    <div className="card-desc small p-3">
                      <div className='row row-cols-2 g-3'>
                        <div className="info-box col-12">
                          <div className="label text-black-50">Name</div>
                          <div className="info text-text-capitalize">{userDetails?.display_name}</div>
                        </div>
                        <div className="info-box col-12">
                          <div className="label text-black-50">Email</div>
                          <div className="info text-lowercase">{userDetails?.email}</div>
                        </div>
                        <div className="info-box">
                          <div className="label text-black-50">Contact number</div>
                          <div className="info text-capitalize">{userDetails?.contact_no}</div>
                        </div>

                        <div className="info-box">
                          <div className="label text-black-50">Registration date</div>
                          <div className="info text-capitalize">{moment(userDetails?.created_at).utc().format('YYYY-MM-DD HH:mm:ss A')}</div>
                        </div>
                        <div className="info-box">
                          <div className="label text-black-50">Last login</div>
                          <div className="info text-capitalize">{moment(userDetails?.login_datetime).utc().format('YYYY-MM-DD HH:mm:ss A')}</div>
                        </div>
                        {/* <div className="info-box">
                          <div className="label text-black-50">Key Skills</div>
                          <div className="info">English, Hindi, French</div>
                        </div>
                        <div className="info-box">
                          <div className="label text-black-50">Martial status</div>
                          <div className="info">Single</div>
                        </div>
                        <div className="info-box">
                          <div className="label text-black-50">Hometown</div>
                          <div className="info text-capitalize">Bangluru</div>
                        </div>
                        <div className="info-box">
                          <div className="label text-black-50">Nationality</div>
                          <div className="info text-capitalize">Indian</div>
                        </div>
                        <div className="col-12 info-box">
                          <div className="label text-black-50">Permanent address</div>
                          <div className="info">C 53, Nelco Ltd, Mahakali Caves Rd, Andheri East Mumbai.</div>
                        </div> */}
                        <div className="col-12 info-box">
                          <div className="label text-black-50">City</div>
                          <div className="info">{userDetails?.current_city}</div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="d-flex flex-column gap-3 h-100">
                    <div className="card overflow-hidden">
                      <div className='card-title bg-light fw-medium border-bottom m-0'><i className="bi bi-mortarboard-fill me-1"></i> Educational Information</div>
                      <div className="card-desc small d-flex flex-column gap-3 p-3">
                        <div className='d-flex flex-wrap justify-content-between gap-2'>
                          <div className="info-box w-75">
                            <div className="label fw-medium">{userDetails?.course}</div>
                            <div className="info text-black-50 text-capitalize">{userDetails?.university}</div>
                          </div>
                          {/* <div className="edu_year fw-medium">{`${userDetails?.start_year} - ${userDetails?.pass_year}`}</div> */}
                          <div className="edu_year fw-medium">{userDetails?.start_year} - {userDetails?.pass_year}</div>
                        </div>
                        {/* <div className='d-flex flex-wrap justify-content-between gap-2'>
                          <div className="info-box w-75">
                            <div className="label fw-medium">Bachelor in Business Administration</div>
                            <div className="info text-black-50 text-capitalize">CSJM University Kanpur (U.P)</div>
                          </div>
                          <div className="edu_year fw-medium">2015-2018</div>
                        </div>
                        <div className='d-flex flex-wrap justify-content-between gap-2'>
                          <div className="info-box w-75">
                            <div className="label fw-medium">Certificate of Web Designing</div>
                            <div className="info text-black-50 text-capitalize">Ducat Institute Gurugram (H.R)</div>
                          </div>
                          <div className="edu_year fw-medium">2021-2022</div>
                        </div> */}
                      </div>
                    </div>
                    <div className="card overflow-hidden">
                      <div className='card-title bg-light fw-medium border-bottom m-0'><i className="bi bi-briefcase-fill me-1"></i> Employement and Other Details</div>
                      <div className="card-desc small p-3">
                        <div className='row row-cols-2 g-3'>
                          <div className="info-box">
                            <div className="label text-black-50">Current company</div>
                            <div className="info text-capitalize">{userDetails?.current_company}</div>
                          </div>
                          <div className="info-box">
                            <div className="label text-black-50">Job role</div>
                            <div className="info text-capitalize">{userDetails?.job_title}</div>
                          </div>
                          <div className="info-box">
                            <div className="label text-black-50">Total experience</div>
                            <div className="info text-capitalize">{userDetails?.total_exp} Years</div>
                          </div>
                          <div className="info-box">
                            <div className="label text-black-50">Annual package</div>
                            <div className="info text-capitalize">{userDetails?.annual_salary} LPA</div>
                          </div>
                          <div className="info-box">
                            <div className="label text-black-50">Notice period</div>
                            <div className="info text-capitalize">{userDetails?.notice_period}</div>
                          </div>
                          <div className="info-box">
                            <div className="label text-black-50">Working since</div>
                            <div className="info text-capitalize">{userDetails?.working_from}</div>
                          </div>
                          <div className="info-box col-12">
                            <div className="label text-black-50">Key skills</div>
                            <div className="info text-uppercase">{userDetails?.key_skill}</div>
                          </div>
                          <div className="info-box">
                            <div className="label text-black-50">Job Status</div>
                            <div className="info text-capitalize">{userDetails?.job_status}</div>
                          </div>
                          <div className="info-box">
                            <div className="label text-black-50">specialization</div>
                            <div className="info text-capitalize">{userDetails?.specialization}</div>
                          </div>
                          <div className="info-box col-12">
                            <div className="label text-black-50">Resume heading</div>
                            <div className="info text-uppercase">{userDetails?.resume_heading}</div>
                          </div>
                          {userDetails?.resume_url != "" ? <div>
                            <DocumentViewer documentUrl={userDetails?.resume_url} fileType={fileType} />
                          </div> : null}

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>

            <Tab eventKey="allTest" title={<><i className="bi bi-person-workspace me-1"></i> User All Test</>} disabledd>
              {/* Table listing code */}
              <div className="total-tests-wrapper">
                {candidateTestList?.map((candidateTest, index) => {
                  let startMoment = moment(candidateTest?.testStarttime).utc();
                  let endMoment = moment(candidateTest?.testEndtime).utc();
                  let duration = moment.duration(endMoment.diff(startMoment));
                  const formattedDate = startMoment.format('YYYY-MM-DD HH:mm:ss');
                  const formattedEndDate = endMoment.format('YYYY-MM-DD HH:mm:ss');

                  return (
                    <div className={`workingStatusCard card small rounded pointer overflow-auto mt-2 ${candidateTest?.test_type == '1' ? 'no-where-working' : 'on-notice-period'}`} key={index}>
                      <div className="info-header fs-6 text-white d-flex flex-wrap justify-content-between column-gap-2 text-capitalize text-end px-3 py-2">
                        <div className="workinStatuslabel text-black">Interview Type</div>
                        <div className="workinStatus text-black fw-medium">{candidateTest?.test_type == '1' ? "Coding Round" : "One-to-One Interview By Sheela"}</div>
                      </div>
                      <div className="info-cards overflow-auto p-3">
                        <div className="row g-2">

                          <div className="col-sm-6 col-sm-6 col-md-4 col-xl-3 item-wrapper d-flex flex-column gap-2">
                            <div className="info-box">
                              <div className="cand-name fw-medium text-truncate text-capitalize">{candidateTest?.displayName}</div>
                              {userDetails?.contactNo && <div className="cand-contact text-muted text-truncate text-capitalize"><i className="bi bi-telephone-fill text-body"></i> {userDetails?.contactNo}</div>}
                            </div>
                            <div className="info-box">
                              <div className="info-label fw-medium text-body">Current Company</div>
                              <div className="info-value text-muted text-capitalize">{userDetails?.currentCompany}</div>
                            </div>
                            <div className="info-box">
                              <div className="info-label fw-medium text-body">Language</div>
                              <div className="info-value text-muted text-capitalize">{candidateTest?.selectedLanguage}</div>
                            </div>
                          </div>
                          <div className="col-sm-6 col-sm-6 col-md-4 col-xl-3 item-wrapper d-flex flex-column gap-2">
                            <div className="info-box">
                              <div className="info-label fw-medium text-body">Total Questions Attempted</div>
                              <div className="info-value text-muted text-capitalize">{candidateTest?.ques_attempt}</div>
                            </div>
                            <div className="info-box">
                              <div className="info-label fw-medium text-body">Total Questions Skipped</div>
                              <div className="info-value text-muted text-capitalize">{candidateTest?.skip_count}</div>
                            </div>
                            <div className="info-box">
                              <div className="info-label fw-medium text-body">Total Interview Time</div>
                              <div className="info-value text-muted text-capitalize">{isNaN(duration.minutes()) ? "end time not saved" : `${duration.minutes()} Min`}</div>
                            </div>
                          </div>
                          <div className="col-sm-6 col-sm-6 col-md-4 col-xl-3 item-wrapper d-flex flex-column gap-2">
                            <div className="info-box">
                              <div className="info-label fw-medium text-body">When User started this round</div>
                              <div className="info-value text-muted text-capitalize">{formattedDate}</div>
                            </div>
                            <div className="info-box action-wrapper">
                              <div className="info-label fw-medium text-body">When user completed This round</div>
                              <div className="info-value text-muted text-capitalize">{formattedEndDate}</div>
                            </div>
                            <div className="info-box action-wrapper">
                              <div className="info-label fw-medium text-body">Result</div>
                              <div className={`${candidateTest?.result === 'pass' ? 'btn-success' : 'btn-danger'}`}>{candidateTest?.result}</div>
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 col-md-12 col-xl-3 item-wrapper">
                            <div className='action-wrapper d-xl-flex align-items-xl-end justify-content-xl-end gap-2 mt-2 mt-xl-0'>
                              <button type='button' className='btn btn-light text-nowrap border px-3' onClick={() => InterviewerDetails(candidateTest)}>View Details</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )

                }
                )}
              </div>
            </Tab>
            {/* <Tab eventKey="profile" title={<><i className="bi bi-images"></i> Other Details</>}>
              Tab content for others
            </Tab> */}
          </Tabs>
        </div>
      </div>
    </div >
  )
}
