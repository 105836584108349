import { Button } from 'react-bootstrap';
import { useNavigate, Link, useLocation } from 'react-router-dom';

const InterviewHistory = ({ interviewData }) => {
    const navigate = useNavigate();
    const InterviewerDetails = (info) => {
        if (info?.test_type == "2")
            navigate('/sheela-test-details', { state: info });
        else
            navigate('/test-details', { state: info });
    }

    const formatDateandtime = (isoString) => {
        const date = new Date(isoString);
        const options = { day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
        return date.toLocaleString('en-US', options);
    };

    const nantime = (testtimedata) => {
        const timeString = testtimedata; 
        const timeValue = parseFloat(timeString); 
        const displayMessage = isNaN(timeValue) ? 'Time not saved' : `${timeValue} minutes`;
        return displayMessage
    }

    return (
        <>
            {interviewData?.map((candidateTest, index) => (
                <div className={`candResultStatus ${candidateTest?.test_type == 1 ? "result-pass" : "result-fail"} bg-white small rounded-3 pointer overflow-auto`}>
                    <div class="info-header d-flex flex-wrap justify-content-between column-gap-2 px-3 py-2">
                        <div className="left-sec text-uppercase">
                            <span>REQ ID: VEREQ{candidateTest?.requisition_id}</span>
                            <span className='mx-2'>|</span>
                            <span>TEST ID: {candidateTest?.full_test_id}</span>
                        </div>
                        <div className="right-sec text-md-end">
                            <div className="test-round fw-semibold">{candidateTest?.test_type == 1 ? "Coding Round" : "One-to-One Interview By Sheela"}</div>
                        </div>
                    </div>
                    <div className="info-body p-3">
                        <div className="row g-2">
                            <div className="col-xl-3 item-wrapper d-flex flex-column gap-2">
                                <div className="info-box">
                                    <div className="recruiter-name fs-6 fw-semibold text-capitalize text-truncate">{candidateTest?.displayName}</div>
                                    <div className="candidate-mob text-capitalize">{candidateTest?.contactNo}</div>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 col-xl-3 item-wrapper d-flex flex-column gap-2">
                                <div className="info-box">
                                    <div className="info-label text-clr1 fs-12">Language</div>
                                    <div className="cand-company text-capitalize">{candidateTest?.selectedLanguage}</div>
                                </div>
                                <div className="info-box">
                                    <div className="info-label text-clr1 fs-12">Total Questions Attempted</div>
                                    <div className="text-capitalize">{candidateTest?.ques_attempt}</div>
                                </div>
                                <div className="info-box">
                                    <div className="info-label text-clr1 fs-12">Total Questions Skipped</div>
                                    <div className="text-capitalize">{candidateTest?.skip_count}</div>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 col-xl-3 item-wrapper d-flex flex-column gap-2">
                                <div className="info-box">
                                    <div className="info-label text-clr1 fs-12">Started this round at</div>
                                    <div className="text-capitalize">{candidateTest?.testStarttime ? formatDateandtime(candidateTest?.testStarttime) : ""}</div>
                                </div>
                                <div className="info-box">
                                    <div className="info-label text-clr1 fs-12">Completed this round at</div>
                                    <div className="text-capitalize">{candidateTest?.testEndtime ? formatDateandtime(candidateTest?.testEndtime) : "end time not saved"}</div>
                                </div>
                                <div className="info-box">
                                    <div className="info-label text-clr1 fs-12">Total Interview Time</div>
                                    <div className="text-capitalize">{candidateTest?.testDuration ? nantime(candidateTest?.testDuration) : "Time not saved"}</div>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 col-xl-3 item-wrapper d-flex flex-column gap-2">
                                <div className="info-box">
                                    <div className="info-label text-clr1 fs-12">Result</div>
                                    {/* <div className={`${candidateTest?.result === 'pass' ? 'text-success' : 'text-danger'} result-status fw-semibold text-uppercase`}>{candidateTest?.result}</div> */}
                                    <div className={`${candidateTest?.result?.toLowerCase() === 'pass' ? 'text-success' : 'text-danger'} result-status fw-semibold text-uppercase`}>{candidateTest?.result}</div>
                                </div>
                                <div className="action-wrapper d-flex flex-wrap gap-2 mt-1">
                                    <Button type='button' variant="light" size="sm" className="border px-3" onClick={() => InterviewerDetails(candidateTest)}>View Detail</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </>
    )
}

export default InterviewHistory