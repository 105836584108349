import React from "react";
import moment from "moment";
import { Button } from 'react-bootstrap';

export const PersonalInformation = ({ UserDetailsdata, getInterviewerDetails }) => {
    return (
        <>
            <div className="row g-3 g-xl-2">
                <div className="col-12 col-md-4 item-wrapper d-flex flex-column gap-2">
                    <div className="info-box">
                        <div className="info-label text-clr1 fs-12">Registration date</div>
                        <div className="cand-company text-capitalize">{moment(UserDetailsdata?.created_at).utc().format('YYYY-MM-DD HH:mm:ss A')}</div>
                    </div>
                    <div className="info-box">
                        <div className="info-label text-clr1 fs-12">City</div>
                        <div className="text-capitalize">{UserDetailsdata?.current_city}</div>
                    </div>
                    <div className="info-box">
                        <div className="info-label text-clr1 fs-12">Current company</div>
                        <div className="text-capitalize">{UserDetailsdata?.current_company}</div>
                    </div>
                    <div className="info-box">
                        <div className="info-label text-clr1 fs-12">Job role</div>
                        <div className="text-capitalize">{UserDetailsdata?.job_title}</div>
                    </div>
                    <div className="info-box">
                        <div className="info-label text-clr1 fs-12">Total experience</div>
                        <div className="text-capitalize">{UserDetailsdata?.total_exp}</div>
                    </div>
                </div>
                <div className="col-6 col-md-4 item-wrapper d-flex flex-column gap-2">
                    <div className="info-box">
                        <div className="info-label text-clr1 fs-12">Annual package</div>
                        <div className="text-capitalize">{UserDetailsdata?.annual_salary}</div>
                    </div>
                    <div className="info-box">
                        <div className="info-label text-clr1 fs-12">Notice period</div>
                        <div className="text-capitalize">{UserDetailsdata?.notice_period}</div>
                    </div>
                    <div className="info-box">
                        <div className="info-label text-clr1 fs-12">Working since</div>
                        <div className="text-capitalize">{UserDetailsdata?.working_from}</div>
                    </div>
                    <div className="info-box">
                        <div className="info-label text-clr1 fs-12">Key skills</div>
                        <div className="text-capitalize">{UserDetailsdata?.key_skill}</div>
                    </div>
                    <div className="info-box">
                        <div className="info-label text-clr1 fs-12">Job Status</div>
                        <div className="text-capitalize">{UserDetailsdata?.job_status}</div>
                    </div>
                </div>
                <div className="col-6 col-md-4 item-wrapper d-flex flex-column gap-2">
                    <div className="info-box">
                        <div className="info-label text-clr1 fs-12">specialization</div>
                        <div className="text-capitalize">{UserDetailsdata?.specialization}</div>
                    </div>
                </div>
                <div className="col-6 col-md-4 item-wrapper d-flex flex-column gap-2">
                    <div className="info-box">
                        <Button type="button" variant="success" class="focus-ring focus-ring-success px-3 btn btn-success" onClick={() => getInterviewerDetails(UserDetailsdata)}>View Details</Button>
                    </div>
                </div>
            </div>
        </>
    )
}