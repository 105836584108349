import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { Link } from "react-router-dom";
import moment from 'moment';
import { getAuth } from '../firebaseConfig';
import axios from 'axios';
import { Loader } from '../components/loader';
import { color } from '@uiw/react-codemirror';
import { UserAuth } from '../context/AuthContext';
// pagination
import Pagination from '../components/Pagination';
// pagination
import { ToastContainer, toast } from 'react-toastify';
import { useMyContext } from '../context/MyContext';

export const MemberListing = () => {
  const { values, handleChange } = useMyContext()
  const location = useLocation();
  const auth = getAuth();
  const [membersDetails, setMembersDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAddingError, setError] = useState(false);
  const { user, userType, userInfoLoaded, currentUserVal } = UserAuth();
  const listType = location.state;

  const [hrtypedata, setHrtypedata] = useState('')
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNum, setPhoneNum] = useState("");
  const [memberlist, setMemberList] = useState([]);
  // React Bootstrap Model
  const [show, setShow] = useState(false);
  // Block User Model
  const [blockUser, setBlockUser] = useState(false);
  const [isBlock, setBlock] = useState(false);

  // Block User Model
  const [deleteUser, setDeleteUser] = useState(false);
  const [selectedUser, setSelectedUser] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const itemsPerPage = 10;

  const closeDeleteUser = () => setDeleteUser(false);
  const openDeleteUser = (id) => {
    setSelectedUser(id);
    setDeleteUser(true)
  };
  useEffect(() => {

    fetchMembers();
  }, [listType, currentPage]);

  function Todo({ todo, index, markTodo, removeTodo }) {
    return (
      <tr key={index}>
        <td><div className="fw-medium">{index + 1}</div></td>
        <td><div className="text-capitalize" style={{ textDecoration: todo?.isDone ? "line-through" : "" }}>{todo?.name}</div></td>
        <td><div style={{ textDecoration: todo?.isDone ? "line-through" : "" }}>{todo?.email}</div></td>
        <td><div style={{ textDecoration: todo?.isDone ? "line-through" : "" }}>{todo?.userType}</div></td>
        <td>
          <div className="d-flex gap-2">
            {todo?.isBlocked == 0 ? <button className="btn btn-warning btn-sm px-3" onClick={() => openBlockUser(todo?.id, false)}><i className="bi bi-person-lock"></i> Block</button> :
              <button className="btn btn-warning btn-sm px-3" onClick={() => openBlockUser(todo?.id, true)}><i className="bi bi-person-lock"></i> UnBlock</button>}
            <button className="btn btn-danger btn-sm px-3" onClick={() => openDeleteUser(todo?.id)}><i className="bi bi-trash3-fill"></i> Delete</button>
          </div>
        </td>
      </tr>
    );
  }

  const closeBlockUser = () => setBlockUser(false);
  const openBlockUser = (id, isBlockval) => {
    setSelectedUser(id);
    setBlockUser(true);
    setBlock(isBlockval);
  };

  //Update User
  const updateUser = async (data) => {
    let updateInfo = { "updateData": data, "id": selectedUser, "operation": "updatemembers", };
    setIsLoading(true);
    // let getToken = await auth.currentUser.getIdToken();
    // let getToken = user?.accessToken;
    const refreshedToken = await currentUserVal?.getIdToken(true);
    var data = JSON.stringify(updateInfo);
    var headers = {
      'Authorization': `Bearer ${refreshedToken}`,
      'Content-Type': 'application/json'
    };
    axios.post(process.env.REACT_APP_API_URL, data, { headers })
      .then(function (response) {
        let userDatares = response.data;
        setIsLoading(false);
        fetchMembers();
        closeBlockUser();
        closeDeleteUser();
      })
      .catch(function (error) {
        // console.log(error);
        setIsLoading(false);

      });

    //updateData
  }

  async function fetchMembers(getData = '') {
    try {
      setIsLoading(true);
      const refreshedToken = await currentUserVal?.getIdToken(true);
      var data = JSON.stringify({
        "operation": "fetchuserbytype",
        "userType": listType || ["HR", "MGR", "SMGR"],
        "page": getData == 'add' ? totalPages : currentPage,
        "pageSize": itemsPerPage
      });
      var headers = {
        'Authorization': `Bearer ${refreshedToken}`,
        'Content-Type': 'application/json'
      };
      axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/dashboard/get-member?page=${currentPage}&pageSize=${itemsPerPage}`, data, { headers })
        .then(function (response) {
          let userDatares = response.data;
          let data = userDatares.memberList?.length > 0 ? userDatares.memberList : [];
          setMembersDetails(data);
          setTotalPages(response?.data.totalPage)
          setIsLoading(false);
        }).catch(function (error) {
          // console.log(error);
          setIsLoading(false);
        })
    } catch (error) {

    }
  }

  // buttonClick
  const handleClick = (type) => {
    if (type === 'prev' && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (type === 'next' && currentPage < totalPages) {
      setCurrentPage(currentPage + 1)
    }
  }
  // buttonClick

  const handleSubmit = e => {
    handleClose();
    e.preventDefault();
    const listTypeVal = //hrtypedata == 1 || hrtypedata == 2 ? 'HR' : 'Admin'
      // added
      hrtypedata == 1 || hrtypedata == 2 ? 'HR' :
        hrtypedata == 4 ? 'MGR' :
          hrtypedata == 5 ? 'SMGR' :
            'Admin';
    // added
    if (!name || !email || !hrtypedata) return;
    addMember({ name, email, hrtypedata, listTypeVal });
    //setName("");
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleOptionChange = (e) => {
    // console.log(e.target.value)
  }

  const addMember = async ({ name, email, hrtypedata, listTypeVal }) => {
    const userData = {
      // operation:"addmembers",
      name: name,
      email: email,
      userType: listTypeVal,
      timeStamp: new Date(),
      isBlocked: "0",
      isDeleted: "0",
      type: listTypeVal,
      hrType: hrtypedata,
      addedBy: user?.email
    };
    // console.log(userData)
    setIsLoading(true);
    //  let getToken = await auth.currentUser.getIdToken();
    //let getToken = user?.accessToken; 
    const refreshedToken = await currentUserVal?.getIdToken(true);
    var data = JSON.stringify(userData);
    var headers = {
      'Authorization': `Bearer ${refreshedToken}`,
      'Content-Type': 'application/json'
    };
    axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/dashboard/add-member`, data, { headers })
      .then(function (response) {
        let userDatares = response.data;
        if (userDatares?.data?.isExist == 1)
          setError(true);
        else {
          setError(false);
          handleClose();
        }
        setIsLoading(false);
        setName("");
        setEmail("");
        setHrtypedata("")
        toast.success("Member has been added.", {
          position: toast.POSITION.BOTTOM_CENTER
        });
        handlePageChange(totalPages)
        fetchMembers("add");
      })
      .catch(function (error) {
        setName("");
        setEmail("");
        setHrtypedata("")
        // console.log(error);
        setIsLoading(false);
      });
  };

  const markTodo = index => {
    // const newTodos = [...todos];
    // newTodos[index].isDone = true;
    // setTodos(newTodos);
  };

  const removeTodo = index => {
    // const newTodos = [...todos];
    // newTodos.splice(index, 1);
    // setTodos(newTodos);
  };

  // pagination
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    // Perform any action related to page change, e.g., fetching new data
  };
  // pagination

  // getCurrentPathname
  useEffect(() => {
    const currentUrl = window.location.href;
    const currentPath = window.location.pathname
    handleChange("getPagePath", currentPath)
  }, []);
  // getCurrentPathname

  return (
    <div className="manager-wrapper py-3">
      <div className="container-fluid">
        {isLoading ? (
          <Loader />
        ) : ''}
        <div className="d-flex flex-wrap align-items-center justify-content-between gap-2 mb-3">
          <div className="fs-5 fw-semibold">All Approved HR List</div>
          <Button variant="primary" className="ms-md-auto" onClick={handleShow}>
            <i className="bi bi-plus-circle"></i> Add new member
          </Button>
        </div>

        <Modal show={show} onHide={handleClose} centered scrollable backdrop="static" keyboard={false} contentClassName="border-0 border-5 border-top border-primary rounded-0 rounded-bottom">
          <Modal.Body className="modal-body px-4 px-md-5 py-5 text-center">
            <h1 className="modal-title fs-4 fw-semibold mb-4" id="addManagerLabel">Add New Member</h1>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Control type="text" className='form-control py-2' value={name} onChange={e => {
                  setName(e.target.value)
                  setError(false);
                }} placeholder="Enter member name" required />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Control type="email" className='form-control py-2' value={email} onChange={e => {
                  setError(false);
                  setEmail(e.target.value)
                }} placeholder="Enter member email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" required />
              </Form.Group>
              <Form.Select value={hrtypedata} onChange={e => {
                setHrtypedata(e.target.value)
                setError(false);
              }} className="fs-6 mb-3" aria-label="Default select example" required>
                <option value="">Select Member Type</option>
                <option value="1">Inhouse HR</option>
                <option value="2">Outsourced HR</option>
                <option value="3">Admin</option>
                <option value="4">Manager</option>
                <option value="5">Sr. Manager</option>
              </Form.Select>
              {/* <Form.Group className="mb-3">
                                <Form.Control type="text" id="myInput" className='form-control py-2' value={phoneNum} onChange={e => setPhoneNum(e.target.value)} placeholder="Enter phone number" maxLength={10} required />
                            </Form.Group> */}
              <div className="btn-wrapper text-center mt-4">
                <Button variant="secondary" className="px-3 me-2" onClick={handleClose} style={{ minWidth: '80px' }}>Cancel</Button>
                <Button variant="primary" type="submit" className="px-3" style={{ minWidth: '80px' }}>Add</Button>
              </div>
              {isAddingError ? <p style={{ color: "red" }}>Member with this email Already Exist.</p> : null}
            </Form>
          </Modal.Body>
        </Modal>


        {/* Table listing code */}
        <div className='listing-sec bg-white rounded p-3 p-xxl-4'>
          <div className='candidates-wrapper'>

            <div className='candidate-list table-responsive border rounded-3'>
              <table className="table align-middle text-nowrap mb-0">
                <thead className="bg-primary text-light sticky-top">
                  <tr>
                    <th scope="col">SR No</th>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Role</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {membersDetails.map((member, index) => (
                    <Todo
                      key={index}
                      index={index}
                      todo={member}
                      markTodo={markTodo}
                      removeTodo={removeTodo}
                    />
                  ))}
                </tbody>
              </table>

            </div>
            {/* pagination */}
            {membersDetails?.length >= 1 ?
              <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
              : ''}
            {/* pagination */}
            {/* Pagination code */}
          </div>
        </div>

        {/* Block User Popup */}
        <Modal show={blockUser} onHide={closeBlockUser} centered backdrop="static" keyboard={false} dialogClassName="userBlock-model" contentClassName="border-0 border-5 border-top border-primary rounded-0 rounded-bottom">
          <Modal.Body className="modal-body px-3 px-md-5 py-5 text-center">
            <div className="text-center mb-3">
              <span className='bg-warning bg-opacity-25 d-flex align-items-center justify-content-center rounded-circle mx-auto' style={{ width: '60px', height: '60px' }}><i className="bi bi-exclamation-octagon-fill fs-3 text-warning"></i></span>
            </div>
            <h1 className="modal-title fs-6 fw-medium mb-3">{`Are you sure you want to ${isBlock ? 'unblock' : 'block'} this user ?`}</h1>
            <div className="btn-wrapper text-center mt-4">
              <Button variant="secondary" className="px-3 me-2" onClick={closeBlockUser} style={{ minWidth: '80px' }}>No</Button>
              <Button variant="primary" type="submit" className="px-3" onClick={() => updateUser({
                isBlocked: isBlock ? 0 : 1,
                "blockedBy": user?.email
              })} style={{ minWidth: '80px' }}>Yes</Button>
            </div>
          </Modal.Body>
        </Modal>

        {/* Delete User Popup */}
        <Modal show={deleteUser} onHide={closeDeleteUser} centered backdrop="static" keyboard={false} dialogClassName="userBlock-model" contentClassName="border-0 border-5 border-top border-primary rounded-0 rounded-bottom">
          <Modal.Body className="modal-body px-3 px-md-5 py-5 text-center">
            <div className="text-center mb-3">
              <span className='bg-danger bg-opacity-25 d-flex align-items-center justify-content-center rounded-circle mx-auto' style={{ width: '60px', height: '60px' }}><i className="bi bi-trash3-fill fs-5 text-danger"></i></span>
            </div>
            <h1 className="modal-title fs-6 fw-medium mb-3">Are you sure you want to delete this user ?</h1>
            <div className="btn-wrapper text-center mt-4">
              <Button variant="secondary" className="px-3 me-2" onClick={closeDeleteUser} style={{ minWidth: '80px' }}>No</Button>
              <Button variant="primary" type="submit" className="px-3" onClick={() => updateUser({
                isDeleted: 1,
                "blockedBy": user?.email
              })} style={{ minWidth: '80px' }}>Yes</Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
      <ToastContainer />
    </div>
  );
}
