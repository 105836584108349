import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { languageOptions } from "../../constants/languageOptions";

export const Technicaldata = ({ allowFuncData, getprefLang, updateallow, updatePreflang }) => {
    const [getisAllowCandidateToSelectLang, setisAllowCandidateToSelectLang] = useState(false)
    const handleCheckboxChange = (event) => {
        setisAllowCandidateToSelectLang(event.target.checked);
        allowFuncData(event.target.checked)
    };
    const [selectedLanguage, setSelectedLanguage] = useState("");
    
    const handleLanguageChange = (event) => {
        setSelectedLanguage(event.target.value);
        getprefLang(event.target.value)
    };

    return (
        <>
            <div className="technical-test-sec">
                <div className="description text-muted fs-6 mb-4">
                    This section is for requesting a coding test from the candidate. Once you fill out this form,
                    the system will conduct two tests with the candidate: the first will be a coding round, followed by a face-to-face interview with Sheela.
                </div>
                <div className="bg-secondary bg-opacity-10 rounded-3 mb-4 p-3 p-xl-4" style={{ margin: '0 -16px' }}>
                    <div className="border-bottom border-secondary border-opacity-25 pb-4 mb-4">
                        <div className="fs-4 fw-bold lh-sm mb-3">First Round: Coding Test</div>
                        <div className="row g-3 gx-xl-5 align-items-center">
                            <div className="col-lg-6">
                                <Form.Group>
                                    <Form.Label>Technical Round Language</Form.Label>
                                    <div className="custom-select">
                                        <Form.Select id="technicalLanguage" required className="text-capitalize" value={updatePreflang=="pref"?"":selectedLanguage} onChange={handleLanguageChange}>
                                            <option value="">Select Language</option>
                                            {languageOptions?.map((items, index) => (
                                                <option value={`${"id" + ":" + items.id}` + "," + `${"value" + ":" + items.value}`}>{`${items.value}`}</option>
                                            ))}
                                        </Form.Select>
                                    </div>
                                    <div className="fs-12 text-clr3 mt-2">Select the coding language in which you want the candidate to answer the test questions.</div>
                                </Form.Group>
                            </div>
                            <div className="col-lg-6">
                                <Form.Check inline id="allowCondition" className="fs-18 me-4 pe-3">
                                    {/* <Form.Check.Input type="checkbox" /> */}
                                    {/* <Form.Check.Label className="lh-sm" onChange={handleCheckboxChange}>Allow the candidate to take the test in any language.</Form.Check.Label> */}
                                    <Form.Check
                                        type="checkbox"
                                        id="custom-checkbox"
                                        label="Allow the candidate to take the test in any language."
                                        checked={updateallow=="allow"?false:getisAllowCandidateToSelectLang}
                                        onChange={handleCheckboxChange}
                                    />
                                    <div className="fs-12 text-clr3 mt-1">Or, if you don't have a preferred language, you can allow the candidate to choose a coding language of their choice.</div>
                                </Form.Check>
                            </div>
                        </div>
                    </div>
                    <div className="info-cover">
                        <div className="fs-4 fw-bold lh-sm mb-2">Second Round: Face-to-Face Interview with Sheela</div>
                        <div className="fs-14 lh-sm mb-4">
                            In this round, Sheela will conduct a face-to-face interview with the candidate based on your requisition.
                            It is recommended that you must provide a detailed requisition, clearly outlining every aspect of the role and the skills required for thr developer.
                            This will enabled Sheela to conduct an in-depth interview with the candidate.
                        </div>
                        <div className="fs-14 lh-sm" style={{ color: '#a3b932' }}>
                            <p>
                                You can view the result of each section in the Panel alongside the recruiter. This includes a complete screen recording, webcam view,
                                and mobile stream of the candidate, offering a three-way cheating check during the interview.
                                You'll be able to see every question, the number of submissions compiler outputs, which questions were skipped,
                                and Sheela will also ask follow-up questions during the coding test to assess the candidate's understanding of their code.
                            </p>
                            <p>
                                Similarly, you'll have full details from the face-to-face round, including video and audio of the candidate's responses.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}