import React from "react";
import { Button } from 'react-bootstrap';

export const ScheduledInformation = ({ getcandidateinfo, gettimeFormat, getCandidateDetails }) => {
    return (
        <>
            <div className="row g-3 g-xl-2">
                <div className="col-12 col-md-4 item-wrapper d-flex flex-column gap-2">
                    <div className="info-box">
                        <div className="info-label text-clr1 fs-12">Current Company</div>
                        <div className="cand-company text-capitalize">{getcandidateinfo?.current_company}</div>
                    </div>
                    <div className="info-box">
                        <div className="info-label text-clr1 fs-12">Job Status</div>
                        <div className="text-capitalize">{getcandidateinfo?.job_status}</div>
                    </div>
                    <div className="info-box">
                        <div className="info-label text-clr1 fs-12">Job Role Awareness</div>
                        <div className="text-capitalize">{getcandidateinfo?.is_job_role_aware}</div>
                    </div>
                    <div className="info-box">
                        <div className="info-label text-clr1 fs-12">Notice Period</div>
                        <div className="text-capitalize">{getcandidateinfo?.notice_period}</div>
                    </div>
                    <div className="info-box">
                        <div className="info-label text-clr1 fs-12">Reason for Job Change</div>
                        <div className="text-capitalize">{getcandidateinfo?.reason_job_change}</div>
                    </div>
                </div>
                <div className="col-6 col-md-4 item-wrapper d-flex flex-column gap-2">
                    <div className="info-box">
                        <div className="info-label text-clr1 fs-12">Current CTC</div>
                        <div className="text-capitalize">{getcandidateinfo?.current_ctc}</div>
                    </div>
                    <div className="info-box">
                        <div className="info-label text-clr1 fs-12">Expected CTC</div>
                        <div className="text-capitalize">{getcandidateinfo?.expected_ctc}</div>
                    </div>
                    <div className="info-box">
                        <div className="info-label text-clr1 fs-12">Salary Negotiation</div>
                        <div className="text-capitalize">{getcandidateinfo?.is_salary_negotiation}</div>
                    </div>
                    <div className="info-box">
                        <div className="info-label text-clr1 fs-12">Possible of Notice BuyOut</div>
                        <div className="text-capitalize">{getcandidateinfo?.can_notice_buyout}</div>
                    </div>
                    <div className="info-box">
                        <div className="info-label text-clr1 fs-12">Location</div>
                        <div className="text-capitalize">{getcandidateinfo?.can_notice_buyout}</div>
                    </div>
                </div>
                <div className="col-6 col-md-4 item-wrapper d-flex flex-column gap-2">
                    <div className="info-box">
                        <div className="info-label text-clr1 fs-12">Comm. Skill Rating</div>
                        <div className="text-capitalize">{getcandidateinfo?.comm_skill_rating}/5</div>
                    </div>
                    <div className="info-box">
                        <div className="info-label text-clr1 fs-12">Cultural Fit Rating</div>
                        <div className="text-capitalize">{getcandidateinfo?.cultural_fit_rating}/5</div>
                    </div>
                    <div className="info-box">
                        <div className="info-label text-clr1 fs-12">Reason for Cultural fit</div>
                        <div className="text-capitalize">{getcandidateinfo?.cultural_fit}</div>
                    </div>
                    <div className="info-box">
                        <div className="info-label text-clr1 fs-12">Interview Medium</div>
                        <div className="text-capitalize">{getcandidateinfo?.interview_medium}</div>
                    </div>
                    <div className="info-box">
                        <div className="info-label text-clr1 fs-12">Interview Date & Time</div>
                        <div className="regis-date text-capitalize">{getcandidateinfo?.interview_date ? gettimeFormat(getcandidateinfo?.interview_date) : ""}, {getcandidateinfo?.interview_time}</div>
                    </div>
                </div>
                <div className="col-6 col-md-4 item-wrapper d-flex flex-column gap-2">
                    <div className="info-box">
                        <Button type="button" variant="success" class="focus-ring focus-ring-success px-3 btn btn-success" onClick={() => getCandidateDetails(getcandidateinfo, 'viewDetail')}>View Details</Button>
                    </div>
                </div>
            </div>
        </>
    )
}