import React from 'react';
import Protected from '../components/Protected';
import { Routes, Route } from 'react-router-dom';
import Sidebar from '../components/sidebar';
import { Dashboard } from './dashboard';
import { InterviewList } from './interview';
import { TestDetails } from './testDetails';
import Header from '../components/header';
import { UserAuth } from '../context/AuthContext';
import UnauthorizedAccess from '../components/UnauthorizedAccess';
import { UsersList } from './users';
import { UserDetails } from './userDetails';
import { MyAccounts } from './myAccounts';
import { MemberListing } from './memberListing';
import { HRCandidatesList } from './hrCandidatesList';
import { RecruitmentList } from './RecruitmentList';
import { MyRecruitmentList } from './MyRecruitmentList';
import { RecruitmentDetails } from './RecruitmentDetails';
import { MyRequisitionDetails } from './MyRequisitionDetails';
import { RequisitionCandidateDetails } from './RequisitionCandidateDetails';
import { RequisitionCandidateStatus } from './RequisitionCandidateStatus';
import { RequisitionStatus } from './RequisitionStatus';
import { InterviewCandidate } from './interviewCandidate';
import { HRCandidateDetails } from './HRCandidateDetails';
import { ReferredCandidate } from './ReferredCandidate';
import { ExternalCandidate } from './ExternalCandidate';
import { UniqueCandidate } from './UniqueCandidate';
import { Totalfail } from './Totalfail';
import { Totalpass } from './Totalpass';
import { TotalRequisition } from './TotalRequisition';
import { ClosedRequisition } from './ClosedRequisition';
import { ClosedRequisitionByme } from './ClosedRequisitionByme';
import { ClosedRequisitionByother } from './ClosedRequisitionByother';
import { TotalHR } from './TotalHR';
import { TotalCandidate } from './TotalCandidate';
import { CompletedProfileUser } from './CompletedProfileUser';
import { TotalInterview } from './TotalInterview';
import { CandidateSixtyPlus } from './CandidateSixtyPlus';
import { Reports } from './Reports';
import { IssuesListing } from './Issues/IssuesListing';
import { ReplyListing } from './Issues/Replylisting';
import OngoingCallsPage from './ongoingInterviews/OngoingInterview';
import { AdminRoomPage } from '../RoomPage/AdminRoomPage';
import { CandidateRegistrationStatus } from './Recruitment/CandidateRegistrationStatus';
import Consentmodalview from '../consentModal/consentModal';
import PdfViewer from './PdfViewer/PdfViewer';
import { TotalTestList } from './TotalTestList';
import { TestResultDetails } from './TestResultDetails';
import { OneToOneDetails } from './OneToOneDetails';
import { SheelaTestDetails } from './SheelaTestDetails';
import Instructions from './Instructions'
import { OthersInterviewList } from './OthersInterview';
import { VersionList } from './VersionList';
// import Instructions from './Instructions';
import { HRAddedCandidatesList } from './HRAddedCandidatesList';
import { CandidateDetail } from './CandidateDetails/CandidateDetail';
import { Issues } from './IssuesSection/Issues';
import { RaiseRequisition } from '../Requisition/RaiseRequisition';
import { NewRequisition } from '../Requisition/NewRequisition';
import { HrReport } from '../pages/HrReport';

const Layout = (props) => {
  const { user, userType, currentUserVal } = UserAuth();

  return (
    <>
      <div className="main-wrapper d-flex w-100">
        <div className="sidebar-wrapper">
          <Sidebar />
        </div>

        <div className="main-content">
          <Header />
          <Routes>
            {/* <Route path='/dashboard' element={<Protected page="dashboard" requiredRoles={['SSA', 'SA', 'HR']}><Dashboard loggedInUser={user} /></Protected>} /> */}
            <Route path='/dashboard' element={<Dashboard loggedInUser={user} />} />
            <Route
              path="/interview-list"
              element={
                <Protected page="interview" requiredRoles={['HR', 'SA', 'SSA', 'MGR', 'SMGR']}>
                  <InterviewList />
                </Protected>
              }
            />
            <Route
              path="/others-interview-list"
              element={
                <Protected page="interview" requiredRoles={['SA', 'SSA']}>
                  <OthersInterviewList />
                </Protected>
              }
            />
            <Route
              path="/recruitment-list"
              element={
                // <RecruitmentList />
                <Protected page="recruitmentlist" requiredRoles={['HR', 'SA', 'SSA', 'MGR', 'SMGR']}>
                  <RecruitmentList />
                </Protected>
              }
            />
            <Route
              path="/my-recruitment-list"
              element={
                <Protected page="myrecruitmentlist" requiredRoles={['HR', 'SA', 'MGR', 'SMGR']}>
                  <MyRecruitmentList />
                </Protected>
              }
            />
            <Route
              path="/recruitment-details"
              element={
                <Protected page="recruitmentdetails" requiredRoles={['HR', 'SA', 'SSA', 'MGR', 'SMGR']}>
                  <MyRecruitmentList />
                </Protected>
              }
            />
            <Route
              path="/interviewcandidate"
              element={
                <Protected page="interviewcandidate" requiredRoles={['HR', 'SA', 'SSA', 'MGR', 'SMGR']}>
                  <InterviewCandidate />
                </Protected>
              }
            />
            <Route
              path="/test-details"
              element={
                <Protected page="testdetails" requiredRoles={['HR', 'SA', 'SSA', 'MGR', 'SMGR']}>
                  <TestDetails />
                </Protected>
              }
            />
            <Route
              path="/sheela-test-details"
              element={
                <Protected page="sheelatestdetails" requiredRoles={['HR', 'SA', 'SSA', 'MGR', 'SMGR']}>
                  <SheelaTestDetails />
                </Protected>
              }
            />
            <Route
              path="/users"
              element={
                <Protected page="candidatelist" requiredRoles={['HR', 'SA', 'SSA', 'MGR', 'SMGR']}>
                  <UsersList />
                </Protected>
              }
            />
            <Route
              path="/user-detail"
              element={
                <Protected page="userdetails" requiredRoles={['HR', 'SA', 'SSA', 'MGR', 'SMGR']}>
                  <UserDetails />
                </Protected>
              }
            />
            <Route
              path="/members-list"
              element={
                <Protected page="memberlist" requiredRoles={['SA', 'SSA']}>
                  <MemberListing />
                </Protected>
              }
            />
            <Route
              path="/mycandidates"
              element={
                <Protected page="mycandidates" requiredRoles={['HR', 'SA']}>
                  <HRCandidatesList />
                </Protected>
              }
            />

            <Route
              path="/hrcandidates"
              element={
                <Protected page="hrcandidates" requiredRoles={['SSA', 'HR', 'SA', 'MGR', 'SMGR']}>
                  <HRAddedCandidatesList />
                </Protected>
              }
            />

            <Route
              path="/my-requisition-details"
              element={
                <Protected page="memberlist" requiredRoles={['HR', 'SA', 'SSA', 'MGR', 'SMGR']}>
                  <MyRequisitionDetails />
                </Protected>
              }
            />
            <Route path="/unauthorized" element={<UnauthorizedAccess />} />

            <Route path="/requisition-status" element={<RequisitionStatus />} />
            <Route path="/account" element={<MyAccounts />} />
            <Route path="/requisition-candidate-details" element={<RequisitionCandidateDetails />} />
            <Route path="/requisition-candidate-status" element={<RequisitionCandidateStatus />} />
            <Route path="/hr-candidate-details" element={<HRCandidateDetails />} />
            <Route path="/referred-candidate" element={<ReferredCandidate />} />
            <Route path="/external-candidate" element={<ExternalCandidate />} />
            <Route path="/unique-candidate" element={<UniqueCandidate />} />
            <Route path="/pass-candidate" element={<Totalpass />} />
            <Route path="/fail-candidate" element={<Totalfail />} />
            <Route path="/total-requisition" element={<TotalRequisition />} />
            <Route path="/closed-requisition" element={<ClosedRequisition />} />
            <Route path="/closed-requisitionbyme" element={<ClosedRequisitionByme />} />
            <Route path="/closed-requisitionbyOther" element={<ClosedRequisitionByother />} />

            <Route
              path="/total-HR"
              element={
                <Protected page="total-HR" requiredRoles={['SA', 'SSA']}>
                  <TotalHR />
                </Protected>
              }
            />
            <Route path="/total-Candidate" element={<TotalCandidate />} />
            <Route path="/completed-profile-user" element={<CompletedProfileUser />} />
            <Route path="/total-interview" element={<TotalInterview />} />
            <Route path="/candidate-sixty-plus" element={<CandidateSixtyPlus />} />
            <Route path="/compile-code" element={<Instructions />} />
            <Route
              path="/total-test-list"
              element={
                <Protected page="totaltestlist" requiredRoles={['HR', 'SA', 'SSA', 'MGR', 'SMGR']}>
                  <TotalTestList />
                </Protected>
              }
            />
            <Route path="/one-to-one-details"
              element={
                <Protected page="onetoonedetails" requiredRoles={['HR', 'SA', 'SSA', 'MGR', 'SMGR']}>
                  <OneToOneDetails />
                </Protected>
              }
            />

            <Route
              path="/test-result-details"
              element={
                <Protected page="testresultdetails" requiredRoles={['HR', 'SA', 'SSA', 'MGR', 'SMGR']}>
                  <TestResultDetails />
                </Protected>
              }
            />
            <Route
              path="/reports"
              element={
                <Protected page="reports" requiredRoles={['SA', 'SSA', 'SMGR']}>
                  <Reports />
                </Protected>
              }
            />
            <Route
              path="/candidate-registration-status"
              element={
                <Protected page="candidate-registration-status" requiredRoles={['HR', 'SA', 'SSA', 'MGR', 'SMGR']}>
                  <CandidateRegistrationStatus />
                </Protected>
              }
            />
            {/* <Route path="/reports" element={<Reports />} /> */}
            {/* <Route path="/candidate-registration-status" element={<CandidateRegistrationStatus />} /> */}

            <Route
              path="/ongoing-interviews"
              element={
                <Protected page="ongoingPage" requiredRoles={['HR', 'SA', 'SSA']}>
                  <OngoingCallsPage />
                </Protected>
              }
            />
            <Route
              path="/issues-list"
              element={
                <Protected page="issueList" requiredRoles={['HR', 'SA', 'SSA']}>
                  <IssuesListing />
                </Protected>
              }
            />
            <Route
              path="/replies-list"
              element={
                <Protected page="replyList" requiredRoles={['HR', 'SA', 'SSA']}>
                  <ReplyListing />
                </Protected>
              }
            />
            <Route
              path="/adminroom"
              element={
                <Protected page="adminPage" requiredRoles={['HR', 'SA', 'SSA']}>
                  <AdminRoomPage />
                </Protected>
              }
            />

            <Route
              path="/consentModal"
              element={
                <Protected page="consentModal" requiredRoles={['HR', 'SA', 'SSA']}>
                  <Consentmodalview />
                </Protected>
              }
            />

            <Route
              path="/version"
              element={
                <Protected page="version" requiredRoles={['SA', 'SSA']}>
                  <VersionList />
                </Protected>
              }
            />

            {/* <Route
                path="/pdfviewer"
                element={
                  <Protected page="pdfviewer" requiredRoles={['HR', 'SA', 'SSA']}>
                    <PdfViewer />
                  </Protected>
                }
              /> */}
            <Route
              path="/candidate-details"
              element={
                <Protected page="candidatedetails" requiredRoles={['HR', 'SA', 'SSA', 'MGR', 'SMGR']}>
                  <CandidateDetail />
                </Protected>
              }
            />

            <Route
              path="/issues"
              element={
                // <Protected page="Issues" requiredRoles={['HR', 'SA', 'SSA']}>
                <Issues />
                // </Protected>
              }
            />
            <Route path="/pdfviewer" element={<PdfViewer />} />
            <Route path="/raiserequisition"
              element={
                <Protected page="raiserequisition" requiredRoles={['HR', 'SA', 'SSA', 'MGR', 'SMGR']}>
                  <RaiseRequisition />
                </Protected>
              }
            />
            <Route path="/new-requisition"
              element={
                <Protected page="new-requisition" requiredRoles={['HR', 'SA', 'SSA', 'MGR', 'SMGR']}>
                  <NewRequisition />
                </Protected>
              }
            />

            <Route path="/hr-report"
              element={
                <Protected page="hr-report" requiredRoles={['HR', 'SA', 'SSA', 'MGR', 'SMGR']}>
                  <HrReport />
                </Protected>
              }
            />
          </Routes>

        </div>
      </div>
    </>
  );
};

export default Layout;



