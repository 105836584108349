import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Button, Form } from "react-bootstrap";
import CreatableSelect from 'react-select/creatable';
import makeAnimated from 'react-select/animated';
import { languageOptions } from "../../constants/languageOptions";
import { UserAuth } from "../../context/AuthContext";
import { SubmitRequisitionModal } from "../SubmitRequisitionModal";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Loader } from "../../components/loader";

export const Jobdescription = ({ getallowFuncdata }) => {
    const { user, userInfoLoaded, currentUserVal } = UserAuth();
    const animatedComponents = makeAnimated();
    const [isloading, setIsloading] = useState(false);
    const [Skills, setSelectedOption] = useState(null);
    const [jobTitle, setJobTitle] = useState("");
    const [primaryRole, setPrimaryRole] = useState("");
    const [clientName, setclientName] = useState("");
    const [minimumExp, setMinimumExp] = useState("");
    const [department, setDepartment] = useState("");
    const [getjobDescription, setJobDescription] = useState("");
    const [keySkills, setKeySkills] = useState([]);
    const [location, setLocation] = useState("");
    const [minimumQualification, setMinimumQualification] = useState("");
    const [requiredBy, setRequiredBy] = useState("");
    const [employmentType, setEmploymentType] = useState("");
    const [shiftType, setShiftType] = useState("");
    const [jobNatureType, setNatureOfJob] = useState("");
    const [isClientName, setIsClientName] = useState(false);
    const [minbudget, setMinbudget] = useState("");
    const [maxbudget, setMaxbudget] = useState("")
    const [getFormdata, setFormdata] = useState([])

    // Submit Requisition Modal
    const [showRequisitionModal, setShowRequisitionModal] = useState(false);
    const closeRequisitionModal = () => setShowRequisitionModal(false);
    const openRequisitionModal = () => setShowRequisitionModal(true);

    // React-slick npm style customisation
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            border: '1px solid',
            borderRadius: '0.375rem',
            backgroundColor: '#fff',
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'rga(108,117,125)', // Change the color of the placeholder text
        }),
    };

    // Define the quill toolbar modules
    const toolbarModules = {
        toolbar: [
            [{ 'font': [] }],
            [{ 'header': '1' }, { 'header': '2' }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            [{ 'list': 'bullet' }, { 'list': 'ordered' }],
            // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            // [{ 'color': [] }, { 'background': [] }],
            ['link', 'image', 'video', 'formula'],
            ['blockquote', 'code-block'], // Quote
            ['clean'] // Remove formatting button
        ],
    };

    // Define the quill formats that are allowed
    const formats = [
        'font', 'header', 'list', 'bullet', 'bold', 'italic', 'underline', 'align', 'link', 'image', 'blockquote'
    ];

    // validationWork
    const [errors, setErrors] = useState({
        jobTitle: "",
        primaryRole: "",
        minimumExp: "",
        clientName: "",
        minimumQualification: "",
        location: "",
        department: "",
        employmentType: "",
        jobNatureType: "",
        shiftType: "",
        requiredBy: "",
        keySkills: "",
        minbudget: "",
        maxbudget: "",
        getjobDescription: ""
    });

    const validateForm = () => {
        const newErrors = {};
        let formIsValid = true;

        // Job Title
        if (!jobTitle) {
            newErrors.jobTitle = "Job Title is required";
            formIsValid = false;
        }

        // Primary Role
        if (!primaryRole) {
            newErrors.primaryRole = "Primary Role is required";
            formIsValid = false;
        }

        // Minimum Experience
        if (!minimumExp || isNaN(minimumExp) || minimumExp <= 0) {
            newErrors.minimumExp = "Minimum experience is required";
            formIsValid = false;
        }

        // Client Name (Only required if isClientName is true)
        if (isClientName && !clientName) {
            newErrors.clientName = "Client Name is required";
            formIsValid = false;
        }

        // Minimum Qualification
        if (!minimumQualification) {
            newErrors.minimumQualification = "Minimum Qualification is required";
            formIsValid = false;
        }

        // Location
        if (!location) {
            newErrors.location = "Job Location is required";
            formIsValid = false;
        }

        // Department
        if (!department) {
            newErrors.department = "Department is required";
            formIsValid = false;
        }

        // Employment Type
        if (!employmentType) {
            newErrors.employmentType = "Employment Type is required";
            formIsValid = false;
        }

        // Job Nature Type
        if (!jobNatureType) {
            newErrors.jobNatureType = "Job Nature is required";
            formIsValid = false;
        }

        // Shift Type
        if (!shiftType) {
            newErrors.shiftType = "Shift Type is required";
            formIsValid = false;
        }

        // Required By (Date)
        if (!requiredBy) {
            newErrors.requiredBy = "Required By date is required";
            formIsValid = false;
        }

        // Key Skills
        if (keySkills.length === 0) {
            newErrors.keySkills = "At least one key skill is required";
            formIsValid = false;
        }

        // Budget
        if (!minbudget) { // Assuming Budget related to job nature type
            newErrors.minbudget = "Please select a min budget range";
            formIsValid = false;
        }

        if (!maxbudget) { // Assuming Budget related to job nature type
            newErrors.maxbudget = "Please select a max budget range";
            formIsValid = false;
        }

        if (!getjobDescription) {
            newErrors.getjobDescription = "Job Description is required";
            formIsValid = false;
        }
        setErrors(newErrors);
        return formIsValid;
    };

    const removeHtmlTags = (text) => {
        return text.replace(/<[^>]*>/g, '');
    };

    const handleSubmitRequisition = async (e) => {
        e.preventDefault();
        // console.log(keySkills)
        // if (!validateForm()) return;
        const formData = {
            jobTitle,
            primaryRole,
            clientName,
            minimumExp,
            department,
            getjobDescription,
            keySkills: keySkills.map((skill) => ({ id: skill.id, value: skill.value })),
            location,
            minimumQualification,
            requiredBy,
            employmentType,
            shiftType,
            jobNatureType,
            minbudget,
            maxbudget
        };
        const postData = {
            "jobTitle": formData?.jobTitle,
            "department": formData?.department,
            "jobDescription": removeHtmlTags(formData?.getjobDescription),
            "keySkills": formData?.keySkills,
            "location": formData?.location,
            "minimumExp": formData?.minimumExp,
            "minimumQualification": formData?.minimumQualification,
            "primaryRole": formData?.primaryRole,
            "clientName": formData?.primaryRole,
            "reqiredBy": formData?.requiredBy,
            "employmentType": formData?.employmentType,
            "shiftType": formData?.shiftType,
            "jobNatureType": formData?.jobNatureType,
            "requested_by": user?.displayName,
            "requested_by_email": user?.email,
            "prefered_lang": { "id": "68", "value": "JavaScript" },
            "isAllowCandidateToSelectLang": getallowFuncdata == true ? "Yes" : "No",
            "isReviewd": "1",
            "range1": "5",
            "range2": "8",
            "selectedTest": "Technical",
        }
        setFormdata(postData)
        openRequisitionModal();
        // Simulate API request
    };
    // validationWork

    const CallApi = async (getData, getUrl) => {
        const refreshedToken = await currentUserVal?.getIdToken(true);
        try {
            const res = await fetch(getUrl, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${refreshedToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(getData),
            });
            // console.log(res)
            // Step 5: Handle response
            if (res.ok) {
                const data = await res.json();
                return data
            } else {
                alert('Error submitting form');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('An error occurred while submitting the form');
        } finally {
            // setIsSubmitting(false); // Re-enable button after submission
        }
    }

    const createRequisition = async () => {
        setIsloading(true)
        const apiUrl = `${process.env.REACT_APP_ADMIN_API}/v3.0/requisitions/raise-requisition`
        const getResponse = await CallApi(getFormdata, apiUrl)
        if (getResponse?.statusCode == 200) {
            toast.success('Requisition has been raised.', {
                position: toast.POSITION.BOTTOM_CENTER
            });
            setIsloading(false)
        } else {
            toast.error('Network Error.', {
                position: toast.POSITION.BOTTOM_CENTER
            });
            setIsloading(false)
        }
        // console.log(getResponse)
        // console.log("getResponse")
    }

    return (
        <>
            <div className="job-description-sec">
                {isloading ? <Loader /> : ""}
                <div className="fs-4 fw-bold lh-sm mb-4">Job Description</div>
                <div className="row g-3 gx-xl-5">
                    <div className="col-sm-6">
                        <Form.Group>
                            <Form.Label>Job Title</Form.Label>
                            <Form.Control type="text" placeholder="Enter Job Title" value={jobTitle} onChange={(e) => { setJobTitle(e.target.value) }} autoComplete="off" required />
                        </Form.Group>
                        {errors.jobTitle && <div className="text-danger">{errors.jobTitle}</div>}
                    </div>
                    <div className="col-sm-6">
                        <Form.Group>
                            <Form.Label>Primary Role</Form.Label>
                            <div className="custom-select">
                                <Form.Select aria-label="Default select example" id="primary-role-id" value={primaryRole} onChange={(e) => { setPrimaryRole(e.target.value) }} required >
                                    <option value="">Select</option>
                                    <option value="In House">In House</option>
                                    <option value="Ongoing Client">Ongoing Client</option>
                                    <option value="New Client">New Client</option>
                                    <option value="For Bench">For Bench</option>
                                </Form.Select>
                            </div>
                        </Form.Group>
                        {errors.primaryRole && <div className="text-danger">{errors.primaryRole}</div>}
                    </div>
                    <div className="col-sm-6">
                        <Form.Group>
                            <Form.Label>Minimum Experience</Form.Label>
                            <Form.Control type="number" maxLength={2} placeholder="Years" value={minimumExp} onChange={(e) => {
                                const value = e.target.value;
                                if (value >= 0) {
                                    setMinimumExp(value);
                                }
                            }} autoComplete="off" required />
                        </Form.Group>
                        {errors.minimumExp && <div className="text-danger">{errors.minimumExp}</div>}
                    </div>
                    {isClientName ?
                        <div className="col-sm-6">
                            <Form.Group>
                                <Form.Label>Client Name</Form.Label>
                                <Form.Control type="text" placeholder="Enter Client Name" value={clientName} onChange={(e) => { setclientName(e.target.value) }} autoComplete="off" required />
                            </Form.Group>
                            {errors.clientName && <div className="text-danger">{errors.clientName}</div>}
                        </div>
                        : null}
                    <div className="col-sm-6">
                        <Form.Group>
                            <Form.Label>Minimum Qualification</Form.Label>
                            <div className="custom-select">
                                <Form.Select aria-label="Default select example" value={minimumQualification} onChange={(e) => { setMinimumQualification(e.target.value) }} required >
                                    <option value="">Select</option>
                                    <option value="Diploma">Diploma</option>
                                    <option value="Graduate">Graduate</option>
                                    <option value="Post Graduate">Post Graduate</option>
                                </Form.Select>
                            </div>
                        </Form.Group>
                        {errors.minimumQualification && <div className="text-danger">{errors.minimumQualification}</div>}
                    </div>
                    <div className="col-sm-6">
                        <Form.Group>
                            <Form.Label>Job Location</Form.Label>
                            <div className="custom-select">
                                <Form.Select aria-label="Default select example" value={location} onChange={(e) => { setLocation(e.target.value) }} required >
                                    <option value="">Select Location</option>
                                    <option value="Noida">Noida</option>
                                    <option value="Kolkata">Kolkata</option>
                                </Form.Select>
                            </div>
                        </Form.Group>
                        {errors.location && <div className="text-danger">{errors.location}</div>}
                    </div>

                    <div className="col-sm-6">
                        <Form.Group>
                            <Form.Label>Department</Form.Label>
                            <div className="custom-select">
                                <Form.Select aria-label="Default select example" value={department} onChange={(e) => { setDepartment(e.target.value) }} required >
                                    <option value="">Select Department</option>
                                    <option value="AI and Data Science">AI and Data Science</option>
                                    <option value="Account and Finance">Account and Finance</option>
                                    <option value="Back End Support">Back End Support</option>
                                    <option value="Billing">Billing</option>
                                    <option value="Business Operation">Business Operation</option>
                                    <option value="CAM">CAM</option>
                                    <option value="Content">Content</option>
                                    <option value="Coordination">Coordination</option>
                                    <option value="Customer Service">Customer Service</option>
                                    <option value="Data Science">Data Science</option>
                                    <option value="Digital Marketing">Digital Marketing</option>
                                    <option value="Embedded">Embedded</option>
                                    <option value="Enterprise Applications">Enterprise Applications</option>
                                    <option value="Foreign Language Expert">Foreign Language Expert</option>
                                    <option value="GAPM">GAPM</option>
                                    <option value="HR">HR</option>
                                    <option value="IT">IT</option>
                                    <option value="IT Support">IT Support</option>
                                    <option value="JAVA">JAVA</option>
                                    <option value="Legal / Paralegal">Legal / Paralegal</option>
                                    <option value="Management">Management</option>
                                    <option value="Microsoft Tech Stack">Microsoft Tech Stack</option>
                                    <option value="Mobile Apps">Mobile Apps</option>
                                    <option value="Multimedia">Multimedia</option>
                                    <option value="Operations">Operations</option>
                                    <option value="Open Source">Open Source</option>
                                    <option value="Quality">Quality</option>
                                    <option value="Recruitment">Recruitment</option>
                                    <option value="Research">Research</option>
                                    <option value="Sales">Sales</option>
                                    <option value="SEO / Marketing">SEO / Marketing</option>
                                    <option value="Testing">Testing</option>
                                    <option value="Virtual Assistant">Virtual Assistant</option>
                                    <option value="Web Designer">Web Designer</option>
                                </Form.Select>
                            </div>
                        </Form.Group>
                        {errors.department && <div className="text-danger">{errors.department}</div>}
                    </div>
                    <div className="col-sm-6">
                        <Form.Group>
                            <Form.Label>Job Type</Form.Label>
                            <div className="custom-select">
                                <Form.Select aria-label="Default select example" value={employmentType} onChange={(e) => { setEmploymentType(e.target.value) }} required >
                                    <option value="">Select Employment</option>
                                    <option value="Full Time">Full Time</option>
                                    <option value="Part Time">Part Time</option>
                                </Form.Select>
                            </div>
                        </Form.Group>
                        {errors.employmentType && <div className="text-danger">{errors.employmentType}</div>}
                    </div>
                    <div className="col-sm-6">
                        <Form.Group>
                            <Form.Label>Job Nature</Form.Label>
                            <div className="custom-select">
                                <Form.Select aria-label="Default select example" value={jobNatureType} onChange={(e) => { setNatureOfJob(e.target.value) }} required >
                                    <option value="">Select Job Nature</option>
                                    <option value="WFO">Work from Office</option>
                                    <option value="WFH">Work from Home</option>
                                </Form.Select>
                            </div>
                        </Form.Group>
                        {errors.jobNatureType && <div className="text-danger">{errors.jobNatureType}</div>}
                    </div>
                    <div className="col-sm-6">
                        <Form.Group>
                            <Form.Label>Job Shift Type</Form.Label>
                            <div className="custom-select">
                                <Form.Select aria-label="Default select example" value={shiftType} onChange={(e) => { setShiftType(e.target.value) }} required >
                                    <option value="">Select Shift Type</option>
                                    <option value="Day Shift">Day</option>
                                    <option value="Night Shift">Night</option>
                                </Form.Select>
                            </div>
                        </Form.Group>
                        {errors.shiftType && <div className="text-danger">{errors.shiftType}</div>}
                    </div>
                    <div className="col-sm-6">
                        <Form.Group>
                            <Form.Label>Required By</Form.Label>
                            <Form.Control type="date" value={requiredBy} onChange={(e) => { setRequiredBy(e.target.value) }} required />
                        </Form.Group>
                        {errors.requiredBy && <div className="text-danger">{errors.requiredBy}</div>}
                    </div>
                    <div className="col-xxl-6">
                        <Form.Group>
                            <Form.Label>Key Skills</Form.Label>
                            <CreatableSelect
                                isMulti
                                className="custom-select"
                                classNamePrefix="custom-select"
                                required
                                closeMenuOnSelect={false}
                                name="skills"
                                options={languageOptions} // You should pass an array of options here.
                                styles={customStyles}
                                value={keySkills} // Ensure that keySkills is an array.
                                onChange={(value) => {
                                    setKeySkills(value); // Value is an array of selected options
                                }}
                                placeholder="Add your skills"
                            />
                        </Form.Group>
                        {errors.keySkills && <div className="text-danger">{errors.keySkills}</div>}
                    </div>
                    <div className="col-sm-6 col-xxl-3">
                        <Form.Group>
                            <Form.Label>Min. Budget Per Annum (INR)</Form.Label>
                            <div className="custom-select">
                                <Form.Select aria-label="Default select example" value={minbudget} onChange={(e) => { setMinbudget(e.target.value) }} required>
                                    <option value="">Select Budget</option>
                                    <option value="1Lakh">1 Lakh</option>
                                    <option value="2Lakh">2 Lakh</option>
                                    <option value="3Lakh">3 Lakh</option>
                                    <option value="4Lakh">4 Lakh</option>
                                    <option value="5Lakh">5 Lakh</option>
                                </Form.Select>
                            </div>
                        </Form.Group>
                        {errors.minbudget && <div className="text-danger">{errors.minbudget}</div>}
                    </div>
                    <div className="col-sm-6 col-xxl-3">
                        <Form.Group>
                            <Form.Label>Max. Budget Per Annum (INR)</Form.Label>
                            <div className="custom-select">
                                <Form.Select aria-label="Default select example" value={maxbudget} onChange={(e) => { setMaxbudget(e.target.value) }} required>
                                    <option value="">Select Budget</option>
                                    <option value="1Lakh">1 Lakh</option>
                                    <option value="2Lakh">2 Lakh</option>
                                    <option value="3Lakh">3 Lakh</option>
                                    <option value="4Lakh">4 Lakh</option>
                                    <option value="5Lakh">5 Lakh</option>
                                </Form.Select>
                            </div>
                        </Form.Group>
                        {errors.maxbudget && <div className="text-danger">{errors.maxbudget}</div>}
                    </div>
                    <div className="col-12 new-mail-page">
                        <Form.Label>Job Description</Form.Label>
                        <div className="editor-section user-form-details">
                            {/* React Quill Editor */}
                            <ReactQuill id="note-description" className="custom-quill-editor bg-white d-flex flex-column flex-fill overflow-auto" theme="snow" placeholder="Enter job description here..."
                                value={getjobDescription}
                                onChange={(value) => setJobDescription(value)}
                                modules={toolbarModules}
                                formats={formats}
                            />
                        </div>
                        {errors.getjobDescription && <div className="text-danger">{errors.getjobDescription}</div>}
                    </div>
                    <div className="col-12 btn_wrapper d-flex flex-wrap justify-content-center gap-3 mt-4 pt-xl-3">
                        <Button type="button" variant="secondary" className="fs-18 py-2 px-5">Cancel</Button>
                        <Button type="submit" variant="success" className="fs-18 py-2 px-5" onClick={(e) => { handleSubmitRequisition(e) }} id="Add-requisition-button">Submit</Button>
                        {/* {isloading ?
                            <div style={{ textAlign: 'center' }}>
                                <span className="spinner-profile"></span>
                            </div>
                            : null} */}
                    </div>
                </div>
            </div>
            {/* Submit Requisition Modal */}
            <SubmitRequisitionModal show={showRequisitionModal} close={closeRequisitionModal} isCreateRequisition={createRequisition} />
            <ToastContainer />
        </>
    )
}