import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { getAuth } from '../firebaseConfig';
import { UserAuth } from '../context/AuthContext';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import moment from 'moment';
import { Loader } from '../components/loader';
import CryptoJS from 'crypto-js';
import ExportToExcel from '../components/ExportToExcel';
import { useMyContext } from "../context/MyContext";
import Avatar from "../assets/Avatar.png"
// pagination
import Pagination from '../components/Pagination';
import { FormLabel, Tooltip, OverlayTrigger } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
// pagination
export const MyRecruitmentList = () => {
    const { values, handleChange } = useMyContext()
    const [gethrpics, sethrpics] = useState(values.getArrayValue)
    const navigate = useNavigate();
    const { user, userInfoLoaded, currentUserVal } = UserAuth();
    const [startDate, setStartDate] = useState(values?.startDatemyrecruit != undefined ? values?.startDatemyrecruit : "");
    const [endDate, setEndDate] = useState(values?.endDatemyrecruit != undefined ? values?.endDatemyrecruit : "");
    const [isLoading, setIsLoading] = useState(false);
    const [isDatefilter, setIsDateFilter] = useState(false);
    const [DateError, setDateError] = useState("");
    const [myRequisitionCount, setMyRequisitionCount] = useState(0);
    const [totalRequisitionCount, setTotalRequisitionCount] = useState(0);
    const [totalCloseCount, setTotalCloseCount] = useState(0);
    const [requisitionClosedByMe, setRequisitionClosedByMe] = useState(0);
    const [requisitionClosedByOther, setRequisitionClosedByOther] = useState(0);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [optionStatusval, setoptionStatusval] = useState(values?.statusrecruit != undefined ? values?.statusrecruit : "")
    const [dwldjobdata, setDwnldjobdata] = useState([])
    const itemsPerPage = 10;

    // differenceDays
    const differenceDays = (startDateValue, endDateValue) => {
        // Date strings
        const date1Str = startDateValue;
        const date2Str = endDateValue;

        const date1 = new Date(date1Str);
        const date2 = new Date(date2Str);

        const differenceMs = date2 - date1;

        const differenceDays = differenceMs / (1000 * 60 * 60 * 24);
        return differenceDays
    }
    // differenceDays

    const handleStartDate = async (event) => {
        const startDateValue = event.target.value;
        const endDateValue = endDate;

        if (startDateValue === "") {
            setStartDate("");
            setEndDate("");
            setIsDateFilter(false);
            return;
        }

        // Get the current date
        const currentDate = new Date();
        const formattedCurrentDate = currentDate.toISOString().split('T')[0]; // Convert current date to ISO format

        const getDifferencedays = await differenceDays(startDateValue, endDateValue)

        if (endDateValue && startDateValue > endDateValue) {
            setDateError("Start date cannot be after the end date");
            return;
        }
        else if (getDifferencedays > 90) {
            setDateError("Please select dates within a 3-month period.");
            // You can also set an error state to display an error message in the UI
            return;
        }
        else {
            setStartDate(startDateValue);
            setDateError("");
        }
        if (startDateValue && endDateValue) {
            setIsDateFilter(true);
        } else {
            setIsDateFilter(false);
        }
    };

    const handleEndDate = async (event) => {
        const endDateValue = event.target.value;
        const startDateValue = startDate; // Get the current start date value

        if (endDateValue === "") {
            // If the end date is cleared, update the state and reset the filters
            setEndDate("");
            setStartDate("");
            setIsDateFilter(false);
            return;
        }

        // Get the current date
        const currentDate = new Date();
        const formattedCurrentDate = currentDate.toISOString().split('T')[0]; // Convert current date to ISO format

        const getDifferencedays = await differenceDays(startDateValue, endDateValue)

        if (startDateValue && endDateValue < startDateValue) {
            // If the selected end date is before the start date, show an error message
            setDateError("End date cannot be before the start date");
            // You can also set an error state to display an error message in the UI
            return;
        } else if (getDifferencedays > 90) {
            setDateError("Please select dates within a 3-month period.");
            // You can also set an error state to display an error message in the UI
            return;
        }
        else {
            // If validation passes, update the state with the selected end date and clear the error message
            setEndDate(endDateValue);
            setDateError("");
        }

        // Check if both start and end dates are selected
        if (startDateValue && endDateValue) {
            setIsDateFilter(true);
        } else {
            setIsDateFilter(false);
        }
    };





    // React Bootstrap Model
    const [show, setShow] = useState(false);

    const closeViewDetails = () => setShow(false);
    const handleViewDetails = () => setShow(true);

    const MyRecruitmentDetail = (info, btntype) => {
        // let requisitionId = info?.requisitionId;
        let requisitionId = info;
        let sendInfo = {
            "requisitionId": requisitionId
        }
        if (btntype == 'viewDetail') {
            const encryptedQuery = CryptoJS.AES.encrypt(JSON.stringify(requisitionId), 'secret_key').toString();
            navigate(`/my-requisition-details?query=${encodeURIComponent(encryptedQuery)}`);
        }
        if (btntype == 'viewStatus') {
            const encryptedQuery = CryptoJS.AES.encrypt(JSON.stringify(info), 'secret_key').toString();
            navigate(`/requisition-status?query=${encodeURIComponent(encryptedQuery)}`);
        }

        //  navigate(`/my-requisition-details?query=${encodeURIComponent(encryptedQuery)}`);
        //navigate('/my-requisition-details');
    }

    // State to store requisition data
    const [requisitions, setRequisitions] = useState([]);
    const [pagination, setPagination] = useState({});
    useEffect(() => {
        // Fetch requisition data from the API
        const overviewData = async () => {
            try {
                setIsLoading(true);
                const refreshedToken = await currentUserVal?.getIdToken(true);
                let postData = {
                    email: user?.email,
                };

                var headers = {
                    'Authorization': `Bearer ${refreshedToken}`,
                    'Content-Type': 'application/json'
                };
                const response = await axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/requisitions/requisition-overview`, postData, { headers })
                setMyRequisitionCount(response.data.myRequisitionCount);
                setTotalRequisitionCount(response.data.totalRequisitionCount);
                setTotalCloseCount(response.data.totalCloseCount);
                setRequisitionClosedByMe(response.data.requisitionClosedByMe);
                setRequisitionClosedByOther(response.data.requisitionClosedByOther);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching requisitions:', error);
                setIsLoading(false);
            }
        };

        overviewData();
    }, []);



    useEffect(() => {
        // Fetch requisition data from the API
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const refreshedToken = await currentUserVal?.getIdToken(true);
                let postData = {
                    email: user?.email,
                    page: 1,
                    limit: 10,
                    isDatefilter: false,
                    fromDate: startDate,
                    toDate: endDate,
                    statusType: optionStatusval !== '' ? optionStatusval : ""
                };
                if (startDate && endDate) {
                    postData = {
                        email: user?.email,
                        page: 1,
                        limit: 10,
                        isDatefilter: true,
                        fromDate: startDate,
                        toDate: endDate,
                        statusType: optionStatusval !== '' ? optionStatusval : ""
                    };
                }

                var headers = {
                    'Authorization': `Bearer ${refreshedToken}`,
                    'Content-Type': 'application/json'
                };
                const response = await axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/requisitions/my-requisition-listing`, postData, { headers })
                setRequisitions(response.data.myRequisitions);
                setPagination(response.data.pagination);
                setTotalPages(response.data?.pagination?.totalPage)
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching requisitions:', error);
                setIsLoading(false);
            }
        };

        fetchData(); // Call the fetch data function when the component mounts
    }, [startDate, endDate]);




    // Function to handle pagination
    const handlePagination = async (page) => {
        try {
            setIsLoading(true);
            const refreshedToken = await currentUserVal?.getIdToken(true);
            const postData = {
                email: user?.email,
                page: page,
                limit: 10,
                statusType: optionStatusval !== '' ? optionStatusval : ""
            };
            var headers = {
                'Authorization': `Bearer ${refreshedToken}`,
                'Content-Type': 'application/json'
            };
            const response = await axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/requisitions/my-requisition-listing`, postData, { headers })
            setRequisitions(response.data.myRequisitions);
            setPagination(response.data.pagination);
            setTotalPages(response.data?.pagination?.totalPage)
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching requisitions:', error);
            setIsLoading(false);
        }
    };

    const clickBox = (data) => {
        navigate(`/${data}`);
        // console.log(data)
    }

    // buttonClick
    const handleClick = (type) => {
        if (type === 'prev' && currentPage > 1) {
            setCurrentPage(currentPage - 1);
            const currentPagenum = currentPage - 1
            handlePagination(currentPagenum)
        } else if (type === 'next' && currentPage < totalPages) {
            setCurrentPage(currentPage + 1)
            const nextPagenum = currentPage + 1
            handlePagination(nextPagenum)
        }
    }
    // buttonClick

    // pagination
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        handlePagination(pageNumber)
        // Perform any action related to page change, e.g., fetching new data
    };
    // pagination

    // optionStatus
    const optionStatus = async (e) => {
        setoptionStatusval(e.target.value)
        handleChange("statusrecruit", e.target.value)
        const getoptionStatusval = e.target.value
        try {
            setIsLoading(true);
            const refreshedToken = await currentUserVal?.getIdToken(true);
            let postData = {
                email: user?.email,
                page: 1,
                limit: 10,
                isDatefilter: false,
                fromDate: startDate,
                toDate: endDate,
                statusType: getoptionStatusval
            };
            if (startDate && endDate) {
                postData = {
                    email: user?.email,
                    page: 1,
                    limit: 10,
                    isDatefilter: true,
                    fromDate: startDate,
                    toDate: endDate,
                    statusType: getoptionStatusval
                };
            }
            var headers = {
                'Authorization': `Bearer ${refreshedToken}`,
                'Content-Type': 'application/json'
            };
            const response = await axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/requisitions/my-requisition-listing`, postData, { headers })
            setRequisitions(response.data.myRequisitions);
            setPagination(response.data.pagination);
            setTotalPages(response.data?.pagination?.totalPage)
            setIsLoading(false);
            setCurrentPage(1)
            // console.log(response)
        } catch (error) {
            console.error('Error fetching requisitions:', error);
            setIsLoading(false);
        }
    }
    // optionStatus

    // convertDate
    const convertDate = (getDate) => {
        // console.log(getDate)
        // console.log("getDate")
        if (getDate != "" && getDate != null && getDate != undefined) {
            const originalDateString = getDate;
            const originalDate = new Date(originalDateString);
            const formattedDate = originalDate.toISOString().split('T')[0];
            return formattedDate
        }
    }
    // convertDate

    // exportData
    const exportData = async (getstartDate, getendDate, optionStatusval) => {
        const currentdatedata = moment().format('YYYY-MM-DD')
        const threeMonthsAgo = moment().subtract(3, 'months').format('YYYY-MM-DD');
        try {
            let postData = {
                "email": user?.email,
                "isDatefilter": true,
                "fromDate": getstartDate == "" ? threeMonthsAgo : getstartDate,
                "toDate": getendDate == "" ? currentdatedata : getendDate,
                "statusType": optionStatusval
            };
            const refreshedToken = await currentUserVal?.getIdToken(true);
            var headers = {
                'Authorization': `Bearer ${refreshedToken}`,
                'Content-Type': 'application/json'
            };
            // console.log(postData)
            const response = await axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/requisitions/download-my-requisition-listing`, postData, { headers })
            const responseData = response.data
            const resReq = responseData?.myRequisitions
            // create Array
            const jobDetails = resReq?.map(item => ({
                name: item.name,
                email: item.email,
                jobTitle: item.requisition.jobTitle,
                department: item.requisition.department,
                jobDescription: item.requisition.jobDescription,
                keySkills: item.requisition.keySkills,
                location: item.requisition.location,
                status: item.requisition.status,
                closedDate: convertDate(item.requisition.closedDate)
            }));
            setDwnldjobdata(jobDetails)
            // create Array
            return resReq
        } catch (error) {
            console.error('Error: ', error);
        }
    }

    useEffect(() => {
        exportData(startDate, endDate, optionStatusval)
    }, [startDate, endDate, optionStatusval])
    // exportData

    // dateAdded
    const [error, setError] = useState('');
    const [isDateSet, setDateVal] = useState(false);
    const handleStartDateChange = (event) => {
        setCurrentPage(1);
        setError(null);
        setStartDate(event.target.value);
        handleChange("startDatemyrecruit", event.target.value)
    };

    const handleEndDateChange = (event) => {
        setError(null);
        setCurrentPage(1);
        if (!startDate && event.target.value != "") {
            // setError("Please select start Date before end Date.");
            toast.error("Please select start Date before end Date.", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            setDateVal(false);
            return;
        }
        if (startDate > event.target.value && event.target.value != "") {
            // setError("End Date cannot be less than the start Date.");
            toast.error("End Date cannot be less than the start Date.", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            setDateVal(false);
            return;
        }
        handleChange("endDatemyrecruit", event.target.value)
        setEndDate(event.target.value);
        setDateVal(event.target.value != "" ? true : false);
    };

    const resetDateFilters = () => {
        setStartDate('');
        setEndDate('');
        setError('');
        handleChange("endDatemyrecruit", "")
        handleChange("startDatemyrecruit", "")
    };
    // dateAdded

    const divStyle = {
        bottom: "0px",
        right: "0px"
    };

    // getCurrentPathname
    useEffect(() => {
        const currentUrl = window.location.href;
        const currentPath = window.location.pathname
        handleChange("getPagePath", currentPath)
    }, []);
    // getCurrentPathname

    return (
        <div className='dashboard-page my-requisition-page py-3'>
            <div className='container-fluid'>
                {/* Heading and filter section */}
                <div className='d-flex flex-column flex-md-row flex-wrap align-items-md-center justify-content-between gap-2 mb-3 mb-md-2'>
                    <div className='left-sec'>
                        <div className='fs-4 fw-semibold lh-sm'>Active Requisition</div>
                    </div>

                    <div className="right-sec">
                        <div className="date-filter d-flex d-xl-inline-flex flex-column flex-sm-row flex-wrap align-items-sm-center gap-2">
                            <Form.Group className="status-type d-flex flex-column flex-md-row align-items-md-center flex-fill gap-1 gap-md-2 me-xl-4" controlId="statusType">
                                {/* <Form.Label className="fs-6 fw-normal text-body m-0">Status</Form.Label> */}
                                <Form.Select className="h-auto bg-white bg-opacity-50 border-0 rounded-3 shadow-none px-3 py-2" value={optionStatusval} onChange={(e) => optionStatus(e)} style={{ minWidth: '160px' }} required>
                                    {/* <option disabled >Status</option> */}
                                    <option value="">Status</option>
                                    <option value="Open">Open</option>
                                    <option value="Closed">Closed</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="start_date d-flex flex-column flex-sm-row align-items-sm-center flex-fill gap-1 gap-md-2" controlId="startDate">
                                <Form.Label className="fs-6 fw-normal text-body m-0 p-0">From</Form.Label>
                                <Form.Control type="date" className="form-control h-auto bg-white bg-opacity-50 border-0 rounded-3 shadow-none px-3 py-2"
                                    max={new Date().toJSON().slice(0, 10)}
                                    value={startDate} onChange={handleStartDateChange}
                                    required
                                />
                            </Form.Group>
                            <Form.Group className="end_date d-flex flex-column flex-sm-row align-items-sm-center flex-fill gap-1 gap-md-2" controlId="endDate">
                                <Form.Label className="fs-6 fw-normal text-body m-0 p-0">To</Form.Label>
                                <Form.Control type="date" className="form-control h-auto bg-white bg-opacity-50 border-0 rounded-3 shadow-none px-3 py-2"
                                    max={new Date().toJSON().slice(0, 10)}
                                    value={endDate} onChange={handleEndDateChange}
                                    required
                                />
                            </Form.Group>
                            <div className="btn-wrapper text-center me-xl-4">
                                <div className="bg-white bg-opacity-50 border-0 rounded-3 shadow-none px-3 py-2 cursor-pointer lh-sm" onClick={resetDateFilters}>
                                    <i className="bi bi-arrow-counterclockwise" ></i> <span className="fw-medium d-sm-none">Reset</span>
                                </div>
                            </div>
                            <ExportToExcel className="mb-3" data={dwldjobdata} filename="my_requisition_data" designType="onlyButton" />

                            {error && <div className="small text-danger text-md-end mt-1 position-absolute text-end" style={divStyle}>{error}</div>}
                            {/* new */}
                        </div>
                    </div>
                </div>

                {/* Info Cards Listing */}
                <div className="dashboard-home">
                    <div className="info-card-sec">
                        <div className="row g-2 g-md-3 mb-3">
                            <div className="col-xl-3 col-sm-6">
                                {/* <div className="card h-100 border-primary border border-2 rounded-3 p-3 pointer" onClick={() => clickBox('total-requisition')}> */}
                                <div className="bg-primary bg-opacity-25 border-bottom border-4 border-primary rounded-3 pointer h-100 p-3">
                                    <div className="d-flex align-items-center justify-content-between gap-3">
                                        <div className="card-details">
                                            <div className="label-value fs-3 fw-bold lh-1 mb-1">{user?.userType == 'HR' ? myRequisitionCount : totalRequisitionCount}</div>
                                            <div className="label-title small text-muted lh-sm">Total requisitions taken by me</div>
                                        </div>
                                        <div className="card-iconn text-primary lh-1">
                                            <i className="bi bi-person-fill-add fs-1"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* onClick={() => clickBox('closed-requisition')} */}
                            <div className="col-xl-3 col-sm-6">
                                <div className="bg-success bg-opacity-25 border-bottom border-4 border-success rounded-3 pointer h-100 p-3">
                                    <div className="d-flex align-items-center justify-content-between gap-3">
                                        <div className="card-details">
                                            <div className="label-value fs-3 fw-bold lh-1 mb-1">{totalCloseCount}</div>
                                            {/* <div className="label-title text-muted lh-sm mt-1">My current open requisition</div> */}
                                            <div className="label-title small text-muted lh-sm">Total closed requisitions</div>
                                        </div>
                                        <div className="card-iconn text-success lh-1">
                                            <i className="bi bi-person-check-fill fs-1"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* onClick={() => clickBox('closed-requisitionbyme')} */}
                            <div className="col-xl-3 col-sm-6">
                                <div className="bg-danger bg-opacity-25 border-bottom border-4 border-danger rounded-3 pointer h-100 p-3" >
                                    <div className="d-flex align-items-center justify-content-between gap-3">
                                        <div className="card-details">
                                            <div className="label-value fs-3 fw-bold lh-1 mb-1">{requisitionClosedByMe}</div>
                                            <div className="label-title small text-muted lh-sm">Requisitions closed by me</div>
                                        </div>
                                        <div className="card-iconn text-danger lh-1">
                                            <i className="bi bi-person-fill-up fs-1"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* onClick={() => clickBox('closed-requisitionbyOther')} */}
                            <div className="col-xl-3 col-sm-6">
                                <div className="bg-warning bg-opacity-25 border-bottom border-4 border-warning rounded-3 pointer h-100 p-3" >
                                    <div className="d-flex align-items-center justify-content-between gap-3">
                                        <div className="card-details">
                                            <div className="label-value fs-3 fw-bold lh-1 mb-1">{requisitionClosedByOther}</div>
                                            <div className="label-title small text-muted lh-sm">Requisitions closed by others</div>
                                        </div>
                                        <div className="card-iconn text-warning lh-1">
                                            <i className="bi bi-person-fill-down fs-1"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="text-end  mb-3">
                    <ExportToExcel className="mb-3" data={dwldjobdata} filename="my_data" />
                </div> */}

                {/* My Requisition List */}
                <div className='candidates-wrapper'>
                    {isLoading ? (
                        <Loader />
                    ) :
                        <div className='candidate-list'>
                            {requisitions?.map((info, index) => (
                                <div className="info-cards fs-14 bg-white rounded overflow-auto p-3 mt-2" key={index}>
                                    <div className="row g-3">
                                        <div className="col-12 col-xl-3 item-wrapper d-flex flex-column gap-2">
                                            <div className="info-box">
                                                <div className="fs-6 fw-bold text-body text-capitalize text-truncate lh-sm">{info?.requisition?.jobTitle}</div>
                                                <div className="fw-bold text-danger text-uppercase">VEREQ{info?.requisition?.id}</div>
                                            </div>
                                            <div className="info-box d-flex flex-wrap flex-xl-nowrap align-items-center gap-2">
                                                <div className="recruiters-imgages d-flex">
                                                    {info?.otherHR?.map((hrlist, index) => (
                                                        <img src={gethrpics[hrlist?.email] != "" && gethrpics[hrlist?.email] != null ? gethrpics[hrlist?.email] : Avatar} alt="Recruiters" className="recruiter-img img-fluid rounded-circle" style={{ height: '30px', width: '30px', minWidth: '30px' }} />
                                                    ))}
                                                </div>
                                                <OverlayTrigger
                                                    key={index}
                                                    placement={'bottom'} showw
                                                    overlay={
                                                        <Tooltip id={`tooltip-${index}`} className="custom-tooltip" data-bs-theme="dark">
                                                            <div className="recruiters-list d-flex flex-column gap-2 p-2">
                                                                {info?.otherHR?.map((hrlist, index) => (
                                                                    <div className="recruiter-items d-flex align-items-center gap-2">
                                                                        <img src={gethrpics[hrlist?.email] != "" && gethrpics[hrlist?.email] != null ? gethrpics[hrlist?.email] : Avatar} alt="Recruiters" className="hr-profiles img-fluid rounded-circle" style={{ height: '35px', width: '35px' }} />
                                                                        <div className="recruiter-info d-block text-start">
                                                                            {
                                                                                user?.userType == "HR" && user.hrType == '1' ?
                                                                                    hrlist.hrType != 1 ?
                                                                                        <>
                                                                                            <div className="recruiter-name fs-14 fw-medium text-capitalize text-truncate">External HR</div>
                                                                                            <div className="assign-candidate fs-12 text-capitalize text-truncate">External HR</div>
                                                                                        </>
                                                                                        :
                                                                                        <>
                                                                                            <div className="recruiter-name fs-14 fw-medium text-capitalize text-truncate">{hrlist?.name}</div>
                                                                                            <div className="assign-candidate fs-12 text-capitalize text-truncate">{hrlist?.name}</div>
                                                                                        </>
                                                                                    :
                                                                                    <>
                                                                                        <div className="recruiter-name fs-14 fw-medium text-capitalize text-truncate">{hrlist?.name}</div>
                                                                                        <div className="assign-candidate fs-12 text-capitalize text-truncate">{hrlist?.name}</div>
                                                                                    </>
                                                                            }

                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </Tooltip>
                                                    }
                                                >
                                                    <Button variant="link" className="recruiters-label fs-14 fw-medium text-primary text-truncate text-decoration-none p-0">
                                                        {info?.otherHR?.length > 1 ? `${info?.otherHR?.length} others recruiters are working` : `${info?.otherHR?.length} recruiter is working`}
                                                    </Button>
                                                </OverlayTrigger>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-4 col-xl-2 item-wrapper d-flex flex-column gap-2">
                                            <div className="info-box">
                                                <div className="info-label text-clr1 fs-12">Requested By</div>
                                                <div className="text-capitalize">{info?.requisition?.requested_by}</div>
                                            </div>
                                            <div className="info-box">
                                                <div className="info-label text-clr1 fs-12">Department</div>
                                                <div className="department_name text-capitalize">{info?.requisition?.department}</div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-4 col-xl-3 item-wrapper d-flex flex-column gap-2">
                                            <div className="info-box">
                                                <div className="info-label text-clr1 fs-12">Created By</div>
                                                <div className="created_date">{moment(info?.createdAt).utc().format('YYYY-MM-DD')} <span className="ms-1">({info?.timeAgo})</span></div>
                                            </div>
                                            <div className="info-box">
                                                <div className="info-label text-clr1 fs-12">Required By</div>
                                                <div className="Required_date">{info?.requisition?.reqiredBy} <span className="text-danger ms-1">({info?.time_remaining})</span></div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-4 col-xl-2 item-wrapper d-flex flex-column gap-2">
                                            <div className="info-box">
                                                <div className="info-label text-clr1 fs-12">Minimum Experience</div>
                                                <div className="min_exp text-capitalize">{info?.requisition?.minimumExp} Year</div>
                                            </div>

                                            <div className="info-box">
                                                <div className="info-label text-clr1 fs-12">Job Location</div>
                                                <div className="location text-capitalize">{info?.requisition?.location}</div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-12 col-xl-2 item-wrapper">
                                            <div className="info-box text-xl-end">
                                                <Button type="button" variant="success" className="focus-ring focus-ring-success px-3" onClick={() => MyRecruitmentDetail(info, 'viewDetail')}>View Details</Button>
                                                {/* <Button type="button" variant="success" size="sm" className='px-3' onClick={() => MyRecruitmentDetail(info, 'viewStatus')}>View Status</Button> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}

                            {/* When no record Found */}
                            {requisitions?.length == 0 ? (
                                <div className="no-data-wrapper bg-white rounded px-3 py-5 mt-2">
                                    <div className="text-center mx-auto" style={{ maxWidth: '350px' }}>
                                        <img src={require('../assets/no-data.jpg')} alt="Candidate" className="img-fluid mt-2" />
                                        <div className='fs-4 fw-semibold text-danger mt-3'>Sorry no record Found !</div>
                                        <div className='small text-muted'>Whoops... this information is not available for a moment</div>
                                    </div>
                                </div>) : null
                            }
                        </div>
                    }
                </div>

                {/* Start: Pagination code */}
                {pagination.totalPage >= 1 ?
                    <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
                    : ''}
                {/* End: Pagination code */}

            </div>
            <ToastContainer />
        </div>
    )
}
