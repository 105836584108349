import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// Import slick slider files
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { UserAuth } from '../context/AuthContext';
import { GoogleButton } from 'react-google-button';
import { useMyContext } from '../context/MyContext';
export const Login = () => {
    const { values, handleChange } = useMyContext()
    const navigate = useNavigate();
    const { signInWithMicrosoft, user, googleSignIn } = UserAuth();
    const isEmptyObject = (obj) => {
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                return false;
            }
        }
        return true;
    };
    useEffect(() => {
        const isUserEmpty = isEmptyObject(user);
        if (!isUserEmpty && user) {
          navigate('/dashboard');
        }
      }, [user]);
    const DashboardScreen = () => {
        navigate('/dashboard');
    }

    //Call Microsoft Login with AuthContext
    const msLogin = async () => {
        try {
            handleChange("loginTYpe","mslogin")
            await signInWithMicrosoft();
        } catch (error) {
            // console.log("error");
        }
    }
    const handleGoogleSignIn = async () => {
        try {
          handleChange("loginTYpe","google")
        await googleSignIn(); 
        } catch (error) {
          // console.log(error);
        }
      };

    var settings = {
        arrows: false,
        dots: true,
        autoplay: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <div className='authentication-page d-flex align-items-center py-5'>
            <div className='container-fluid container-xxl position-relative'>
                <div className='auth_page px-lg-3'>
                    <div className='row align-items-center'>
                        <div className='col-md-6 col-lg-7 col-xxl-8 mb-3 mb-md-0'>
                            <div className="left-section text-center">
                                <img className='img-fluid rounded-3 mb-2' src={require('../assets/virtual-logo.png')} alt='Logo' style={{ maxWidth: '300px' }} />
                                <div className="fs-2 fw-medium text-capitalize text-white">Welcome To NM-Interview Admin</div>
                            </div>
                        </div>
                        <div className='col-md-6 col-lg-5 col-xxl-4'>
                            <div className='right-section rounded-4 overflow-hidden w-100 mx-auto text-center shadow' style={{ maxWidth: '420px' }}>
                                <div className='bg-white text-center d-flex flex-wrap align-content-center justify-content-center px-4 py-5' style={{ maxHeight: '450px', minHeight: '450px' }}>
                                    <div className="w-100 fs-3 fw-bold text-primary lh-sm mb-2">NM-Interview System</div>
                                    <div className="introduction-subtitle fw-medium text-center">AI Based Interview System</div>
                                    <div className="w-100 img-wrapper mt-4">
                                        <img className='img-fluid mx-auto' src={require('../assets/registration-boy.png')} alt='Logo' style={{ maxWidth: '140px' }} />
                                    </div>
                                    <div className='mt-4'>
                                        <button className='btn btn-outline-primary focus-ring focus-ring-primary rounded-1 px-3 py-2' onClick={msLogin}>Proceed to Login <i className="bi bi-arrow-right"></i></button>
                                        <div className="fs-12 text-secondary mt-2 pt-1">You can login with your VirtualEmployee ID</div>
                                    </div>
                                    <div className='mt-4'>
                                        <p>OR</p>
                                        <GoogleButton onClick={handleGoogleSignIn} />
                                    </div>
                                </div>
                                <div className="bg-primary text-light small fw-medium text-capitalize border-bottom-rounded p-3">Only for VirtualEmployee's Employees</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}