import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import imgDefault from '../assets/Avatar.png';
import searchIcon from '../assets/search.svg'
import { getAuth } from '../firebaseConfig';
import axios from 'axios';
import ExportToExcel from '../components/ExportToExcel';
import moment from 'moment';
import { Loader } from '../components/loader';
import { UserAuth } from '../context/AuthContext';
import { useMyContext } from '../context/MyContext';
import { useNavigationContext } from "../context/NavContext";
// pagination
import Pagination from '../components/Pagination';
// pagination
export const ExternalCandidate = () => {
  const navigate = useNavigate();
  const auth = getAuth();
  const [candidateList, setCandidate] = useState([]);
  const [workingStatus, setWorkingStatus] = useState("");
  const [resultStatus, setResultStatus] = useState("");
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [error, setError] = useState(null);
  const [isDateSet, setDateVal] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [fetchObj, setfetchObj] = useState({
    "operation": "fetchtestResult",
    "queryType": [],
    "isSearch": false,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isActive, setIsActive] = useState(false);
  const { user, userInfoLoaded, currentUserVal } = UserAuth();
  const { values, handleChange } = useMyContext()
  const [changehrtype, setChangeHRtype] = useState('')
  const [dashboardData, setDashboardData] = useState({
    inhouseHrCount: 0,
    outsourcedHrCount: 0,
    uniqueCandidateCount: 0,
    passCount: 0,
    failCount: 0
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const itemsPerPage = 10;
  const { activepage, handlePageClick } = useNavigationContext()

  const openFilter = (event) => {
    setIsActive(current => !current);
  };

  handlePageClick('interview-list')

  useEffect(() => {
    const fetchList = async () => {
      setIsLoading(true);
      let filterObj = {
        "hrType": 2,
        "resultStatus": '',
        "page":currentPage,
        "pageSize":itemsPerPage
      };
      if (user?.userType == "HR") {
        filterObj.hr_email = user?.email;
      }
      if (user?.userType == "MGR" || user?.userType == "SMGR") {
        filterObj.userType = user?.userType;
        filterObj.managerEmail = user?.email;
      }
      const refreshedToken = await currentUserVal?.getIdToken(true);
      var data = JSON.stringify(filterObj);
      var headers = {
        'Authorization': `Bearer ${refreshedToken}`,
        'Content-Type': 'application/json'
      };
      axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/interviews/get-interiew?page=${currentPage}&pageSize=${itemsPerPage}`, data, { headers })
        .then(function (response) {
          let userDatares = response.data;
          let data = userDatares.interviews?.length > 0 ? userDatares.interviews : [];
          setCandidate(data);
          setTotalPages(userDatares?.totalPages)
          setIsLoading(false);
        })
        .catch(function (error) {
          // console.log(error);
          setIsLoading(false);

        });
    }
    // fetchList();
    if (((values.workstatusValueExtCan == '') || (values.workstatusValueExtCan == undefined) || (values.workstatusValueExtCan == null)) && ((values.resultstatusValueExtCan == '') || (values.resultstatusValueExtCan == undefined) || (values.resultstatusValueExtCan == null)) && ((values.getHRtype == '') || (values.getHRtype == undefined) || (values.getHRtype == null))) {
      fetchList();
    } else {
      onloadresultstatus()
      onloadworkstatus()
      onloadHRstatus()
      // console.log(values.workstatusValueExtCan)
      // console.log(values.resultstatusValueExtCan)
    }
  }, [currentPage, currentUserVal])

  const fetchListwithFilter = async (filterObj) => {
    setIsLoading(true);
    if (user?.userType == "HR") {
      filterObj.hr_email = user?.email;
      filterObj.page=currentPage;
      filterObj.pageSize=itemsPerPage;
    }
    if (user?.userType == "MGR" || user?.userType == "SMGR") {
      filterObj.userType = user?.userType;
      filterObj.managerEmail = user?.email;
    }
    const refreshedToken = await currentUserVal?.getIdToken(true);
    var data = JSON.stringify(filterObj);
    var headers = {
      'Authorization': `Bearer ${refreshedToken}`,
      'Content-Type': 'application/json'
    };
    axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/interviews/get-interiew?page=${currentPage}&pageSize=${itemsPerPage}`, data, { headers })
      .then(function (response) {
        let userDatares = response.data;
        let data = userDatares.interviews?.length > 0 ? userDatares.interviews : [];
        setCandidate(data);
        setTotalPages(userDatares?.totalPages)
        setIsLoading(false);
      })
      .catch(function (error) {
        // console.log(error);
        setIsLoading(false);
      });
  }


  //On Work Status Change
  const workStatusChange = (event) => {
    setCurrentPage(1)
    const workstatusValueExtCan = event.target.value
    const localData = handleChange('workstatusValueExtCan', workstatusValueExtCan)
    const resultValue = resultStatus ? resultStatus : ''
    const hrtypeVal = changehrtype ? changehrtype : ''
    var queryTypeval = []
    if (workstatusValueExtCan != '') {
      queryTypeval.push("workingStatus")
    } if (resultValue != '') {
      queryTypeval.push("resultStatus")
    }
    let updatedFilterData = {
      startDate: "",
      endDate: "",
      currentJobStatus: workstatusValueExtCan,
      resultStatus: resultValue,
      hrType: 2
      // hr_type: hrtypeVal
    };
    setfetchObj(updatedFilterData);
    fetchListwithFilter(updatedFilterData);
    setError(null);
    setWorkingStatus(event.target.value);
  }

  const resultStatusChange = (event) => {
    setCurrentPage(1)
    const resultstatusValueExtCan = event.target.value
    const localData = handleChange('resultstatusValueExtCan', resultstatusValueExtCan)
    const workValue = workingStatus ? workingStatus : ''
    const hrtypeVal = changehrtype ? changehrtype : ''
    var queryTypeval = []
    if (workValue != '') {
      queryTypeval.push("workingStatus")
    } if (resultstatusValueExtCan != '') {
      queryTypeval.push("resultStatus")
    }
    let updatedFilterData = {
      startDate: "",
      endDate: "",
      currentJobStatus: workValue,
      resultStatus: resultstatusValueExtCan,
      hrType: 2
      // hr_type: hrtypeVal
    };
    setfetchObj(updatedFilterData);
    fetchListwithFilter(updatedFilterData);
    setError(null);
    setResultStatus(event.target.value);
  }

  const HRstatuschange = (e) => {
    setCurrentPage(1)
    const HRtype = e.target.value
    var queryTypeval = []
    const localData = handleChange('getHRtype', HRtype)
    const workValue = workingStatus ? workingStatus : ''
    const resultValue = resultStatus ? resultStatus : ''
    var queryTypeval = []
    if (workValue != '') {
      queryTypeval.push("workingStatus")
    } if (resultValue != '') {
      queryTypeval.push("resultStatus")
    }
    let updatedFilterData = {
      startDate: "",
      endDate: "",
      currentJobStatus: workValue,
      resultStatus: resultValue,
      hrType: 2
      // hr_type: hrtypeVal
    };
    setfetchObj(updatedFilterData);
    fetchListwithFilter(updatedFilterData);
    setError(null);
    setChangeHRtype(e.target.value);
  }


  // onload Data
  const onloadworkstatus = () => {
    const getHRvalue = values.getHRtype
    const workstatusValueExtCan = values.workstatusValueExtCan
    const getresultValue = values.resultstatusValueExtCan
    const resultValue = getresultValue ? getresultValue : ''
    var queryTypeval = []
    if (workstatusValueExtCan != '') {
      queryTypeval.push("workingStatus")
    } if (resultValue != '') {
      queryTypeval.push("resultStatus")
    }
    let updatedFilterData = {
      startDate: "",
      endDate: "",
      currentJobStatus: workstatusValueExtCan,
      resultStatus: resultValue,
      hrType: 2
      // hr_type: hrtypeVal
    };
    setfetchObj(updatedFilterData);
    fetchListwithFilter(updatedFilterData);
    setError(null);
    setWorkingStatus(workstatusValueExtCan);
  }

  const onloadresultstatus = () => {
    const getHRvalue = values.getHRtype
    const resultstatusValueExtCan = values.resultstatusValueExtCan
    const getworkValue = values.workstatusValueExtCan
    const workValue = getworkValue ? getworkValue : ''
    var queryTypeval = []
    if (workValue != '') {
      queryTypeval.push("workingStatus")
    } if (resultstatusValueExtCan != '') {
      queryTypeval.push("resultStatus")
    }
    let updatedFilterData = {
      startDate: "",
      endDate: "",
      currentJobStatus: workValue,
      resultStatus: resultstatusValueExtCan,
      hrType: 2
      // hr_type: hrtypeVal
    };
    setfetchObj(updatedFilterData);
    fetchListwithFilter(updatedFilterData);
    setError(null);
    setResultStatus(resultstatusValueExtCan);
  }

  const onloadHRstatus = () => {
    const getHRvalue = values.getHRtype
    const resultstatusValueExtCan = values.resultstatusValueExtCan
    const getworkValue = values.workstatusValueExtCan
    const workValue = getworkValue ? getworkValue : ''
    var queryTypeval = []
    if (workValue != '') {
      queryTypeval.push("workingStatus")
    } if (resultstatusValueExtCan != '') {
      queryTypeval.push("resultStatus")
    }
    let updatedFilterData = {
      startDate: "",
      endDate: "",
      currentJobStatus: workValue,
      resultStatus: resultstatusValueExtCan,
      hrType: 2
      // hr_type: hrtypeVal
    };
    setfetchObj(updatedFilterData);
    fetchListwithFilter(updatedFilterData);
    setError(null);
    setChangeHRtype(getHRvalue);
  }
  // onload data

  const handleStartDateChange = (event) => {
    setError(null);
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setError(null);
    if (!startDate && event.target.value != "") {
      setError("Please select start Date before end Date.");
      setDateVal(false);
      return;
    }
    if (startDate > event.target.value && event.target.value != "") {
      setError("End Date cannot be less than the start Date.");
      setDateVal(false);
      return;
    }
    setEndDate(event.target.value);
    setDateVal(event.target.value != "" ? true : false);
  };

  const handleApplyClick = () => {
    const filterData = {
      operation: "fetchtestResult",
      queryType: [],
      isSearch: false,
    };
    let updatedFilterData = {
      operation: "fetchtestResult",
      queryType: [],
      isSearch: false,
    };

    if (!isDateSet && (startDate != "" || endDate != "")) {
      setError("Please select from and to Date.");
      setDateVal(false);
      return;
    }

    if (isDateSet && resultStatus !== "" && workingStatus !== "") {
      const updates = {
        startDate: startDate,
        endDate: endDate,
        currentJobStatus: workingStatus,
        resultStatus: resultStatus,
      };
      updatedFilterData = { ...filterData, ...updates };
      setError(null);
    }
    if (isDateSet && resultStatus !== "" && workingStatus == "") {
      const updates = {
        startDate: startDate,
        endDate: endDate,
        resultStatus: resultStatus,
      };
      updatedFilterData = { ...filterData, ...updates };
      setError(null);
    }
    if (isDateSet && workingStatus != "" && resultStatus == "") {
      const updates = {
        startDate: startDate,
        endDate: endDate,
        currentJobStatus: workingStatus,
      };
      updatedFilterData = { ...filterData, ...updates };
      setError(null);
    }

    if (isDateSet && workingStatus == "" && resultStatus == "") {
      const updates = {
        startDate: startDate,
        endDate: endDate,
      };
      updatedFilterData = { ...filterData, ...updates };
      setError(null);
    }
    if (!isDateSet && workingStatus != "" && resultStatus != "") {
      const updates = {
        currentJobStatus: workingStatus,
        resultStatus: resultStatus,
      };
      updatedFilterData = { ...filterData, ...updates };
      setError(null);
    }
    if (!isDateSet && workingStatus != "" && resultStatus == "") {
      const updates = {
        currentJobStatus: workingStatus,
      };
      updatedFilterData = { ...filterData, ...updates };
      setError(null);
    }
    if (!isDateSet && workingStatus == "" && resultStatus != "") {
      const updates = {
        resultStatus: resultStatus,
      };
      updatedFilterData = { ...filterData, ...updates };
      setError(null);
    }
    setfetchObj(updatedFilterData);
    fetchListwithFilter(updatedFilterData);
  };

  const searchFunction = (event) => {
    let searchText = event.target.value;
    // console.log(searchText)
    setSearchVal(searchText);
    if (searchText == "") {
      fetchListwithFilter(fetchObj);
    }
    if (searchText != "" & searchText?.length > 2) {
      let updatedFilterData = {
        startDate: "",
        endDate: "",
        currentJobStatus: "",
        resultStatus: "",
        hrType: 2,
        // search_query: searchText
        searchString: searchText
      };
      fetchListwithFilter(updatedFilterData);
    }
  }

  const InterviewerDetails = (info) => {
    info.test_id = info.id;
    info.user_id = info.userId;
    info.display_name = info.displayName;
    info.sel_language = info.selectedLanguage;
    info.total_exp = info.totalExp;
    info.sel_language = info.selectedLanguage;
    info.test_starttime = info.testStarttime;
    info.test_endtime = info.testEndtime;
    info.contact_no = info.contactNo;
    info.profile_img = info.profileImg;
    info.current_company = info.currentCompany;
    info.status = info.result;
    info.job_status = info.jobStatus;
    navigate('/test-details', { state: info });
  }

  // buttonClick
  const handleClick = (type) => {
    if (type === 'prev' && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (type === 'next' && currentPage < totalPages) {
      setCurrentPage(currentPage + 1)
    }
  }
  // buttonClick

  // pagination
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    // Perform any action related to page change, e.g., fetching new data
  };
  // pagination
  return (
    <div className='interviewList-page dashboard-home py-3'>
      <div className="container-fluid">
        <div className='filter-sec mb-2'>
          <div className="d-flex align-items-center justify-content-between gap-2">
            <div className="filter-label fw-medium pointer" onClick={openFilter}><i className="bi bi-filter-circle-fill" title='Click to filter'></i> Filter By -</div>
            <div className="filter-label fw-medium pointer"></div>

            {candidateList?.length > 0 ?
              <ExportToExcel data={candidateList} filename="my_data" /> :
              null
            }
          </div>

          {/* <div className={`d-flex flex-column flex-md-row align-items-md-center justify-content-between gap-1 gap-xl-2 mt-2 ${isActive ? 'active' : 'd-none'}`}> */}
          <div className={`d-flex flex-column flex-md-row align-items-md-center justify-content-between gap-1 gap-xl-2 mt-2`}>
            <div className="searchfield-wrapper flex-fill">
              <input type="search" className="form-control rounded-2" id="search" value={searchVal} placeholder="Search Here..." onChange={searchFunction} />
              <img src={searchIcon} alt="Search Icon" className="position-absolute top-50 end-0 translate-middle" />
            </div>
            {user?.userType == 'SSA' ?
              <div className="Result-filter flex-fill">
                <select className="form-select" aria-label="Default select example" value={values.getHRtype} onChange={HRstatuschange}>
                  <option value="">Select HR</option>
                  <option value="1">Inhouse</option>
                  <option value="2">Outsourced</option>
                </select>
              </div> : ''}

            <div className="Result-filter flex-fill">
              <select className="form-select" value={resultStatus} aria-label="Default select example" onChange={resultStatusChange} required>
                <option value="">Result Status</option>
                <option value="Pass">Passed</option>
                <option value="Fail">Fail</option>
              </select>
            </div>

            <div className="status-filter flex-fill">
              <select className="form-select" value={workingStatus} aria-label="Default select example" onChange={workStatusChange} required>
                <option value="">Working Status</option>
                <option value="In Job">In Job</option>
                <option value="On Notice Period">On Notice Period</option>
                <option value="No Where Working">No Where Working</option>
                <option value="Fresher">Fresher</option>
              </select>
            </div>

            <div className="date-filter d-flex align-items-center gap-1 bg-white rounded-2 px-3">
              <input type="date" className="form-control border-0 ps-0" id="startDate" value={startDate} onChange={handleStartDateChange} required />
              <input type="date" className="form-control border-0 ps-0" id="endDate" value={endDate} onChange={handleEndDateChange} required />
              <button type='button' className='btn btn-sm btn-primary px-3 lh-1' onClick={() => handleApplyClick()}>Apply</button>
            </div>
          </div>
          {error && <p className="text-danger">{error}</p>}
        </div>

        <div className='candidates-wrapper'>
          {/* When loader visible */}
          {isLoading ? (
            <Loader />
          ) : ''}

          <div className='candidate-list'>
            {candidateList.map((info, id) => {
              const originalMoment = moment(info?.testStarttime).utc();
              const formattedDate = originalMoment.format('YYYY-MM-DD HH:mm:ss');

              let profilePic = "";
              let startMoment = moment(info?.testStarttime).utc();
              let endMoment = moment(info?.test_endtime).utc();
              let duration = moment.duration(endMoment.diff(startMoment));
              let testStartDatetime = startMoment.format("YYYY-MM-DD HH:mm:ss");
              profilePic = info?.user_photo != null ? info?.user_photo : imgDefault;

              return (
                <div className="info-cards card small bg-white rounded overflow-auto p-3 mt-2">
                  <div className="row g-2">
                    <div className="col-6 col-md-4 col-xl-3 item-wrapper d-flex flex-column gap-2">
                      <div className="info-box">
                        <div className="img-wrapper mb-2">
                          <img src={profilePic != '' ? profilePic : imgDefault} referrerPolicy="no-referrer" alt="Candidate" className="img-fluid object-fit-cover border border-2 border-secondary border-opacity-75 rounded-1" style={{ width: '45px', height: '40px' }} />
                        </div>
                        <div className="cand-name fs-6 fw-medium text-truncate text-capitalize">{info.displayName}</div>
                        {info.userEmail && <div className="cand-email text-muted text-truncate text-lowercase"><i className="bi bi-envelope-fill"></i> {info.userEmail}</div>}
                        {info.contactNo && <div className="cand-contact text-muted text-truncate text-capitalize"><i className="bi bi-telephone-fill"></i> {info.contactNo}</div>}
                      </div>
                    </div>
                    <div className="col-6 col-md-4 col-xl-3 item-wrapper d-flex flex-column gap-2">
                      <div className="info-box">
                        <div className="info-label text-secondary">Current Company</div>
                        <div className="cand-company text-capitalize">{info.currentCompany}</div>
                      </div>

                      <div className="info-box">
                        <div className="info-label text-secondary">Start Time</div>
                        <div className="date text-capitalize">{formattedDate}</div>
                      </div>
                    </div>
                    <div className="col-6 col-md-4 col-xl-2 item-wrapper d-flex flex-column gap-2">
                      <div className="info-box">
                        <div className="info-label text-secondary">Experience</div>
                        <div className="cand-exp text-capitalize">{info.totalExp} Year</div>
                      </div>

                      <div className="info-box">
                        <div className="info-label text-secondary">Duration</div>
                        <div className="test-duration text-capitalize">{info?.testDuration} </div>
                      </div>
                    </div>
                    <div className="col-6 col-md-4 col-xl-2 item-wrapper d-flex flex-column gap-2">
                      <div className="info-box">
                        <div className="info-label text-secondary">Language</div>
                        <div className="cand-lang text-capitalize">{info.selectedLanguage}</div>
                      </div>
                      <div className="info-box action-wrapper">
                        <div className="info-label text-secondary">Result</div>
                        <div className={`${info.result === 'Pass' ? 'btn-success' : 'btn-danger'}`}>{info.result}</div>
                      </div>
                    </div>
                    <div className="col-12 col-md-4 col-xl-2 item-wrapper d-flex flex-column gap-2">
                      <div className="info-box action-wrapper">
                        <div className="info-label text-secondary">Working Status</div>
                        <span className={`fs-12 badge rounded-pill px-3 ${info.jobStatus === 'In Job' ? 'text-bg-success' : 'text-bg-secondary' && info.jobStatus.toLowerCase() == 'currently on notice period' ? 'text-bg-danger' : 'text-bg-success' && info.jobStatus === 'No Where Working' ? 'text-bg-warning' : 'text-bg-secondary'}`}>{info.jobStatus}</span>
                      </div>
                      <div className='action-wrapper d-flex gap-2 mt-2'>
                        <button type='button' className='btn btn-sm btn-light border px-3' onClick={() => InterviewerDetails(info)}>View Details</button>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
            )}

            {/* pagination */}
            {candidateList?.length >= 1 ?
              // <div className='paginationBtn mt-4'>
              //   <button type="button" className="btn btn-primary" onClick={() => handleClick('prev')} disabled={currentPage === 1}>Prev</button>
              //   <span className='px-2 p-2 backWhite'>Page {currentPage} of {totalPages}</span>
              //   <button type="button" className="btn btn-primary" onClick={() => handleClick('next')} disabled={currentPage === totalPages}>Next</button>
              // </div> 
              <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
              : ''}
            {/* pagination */}
          </div>


          {/* No data available */}
          {candidateList?.length == 0 ? (
            <div className="no-data-wrapper bg-white rounded px-3 py-5 mt-2">
              <div className="text-center mx-auto" style={{ maxWidth: '350px' }}>
                <img src={require('../assets/no-data.jpg')} alt="Candidate" className="img-fluid" />
                <div className='fs-4 fw-semibold text-danger mt-3'>Sorry no record Found !</div>
                <div className='small text-muted'>Whoops... this information is not available for a moment</div>
              </div>
            </div>) : null
          }
        </div>
      </div>
    </div >

  )
}
