import React, { createContext, useState } from 'react';

// Create Context
const DataContext = createContext();

// Create Data Provider component
const DataProvider = ({ children }) => {
  // Initial user data in the context
  const [userArray, setUserArray] = useState([]);

  // Function to add an array of users
  const addUsers = (newUsers) => {
    setUserArray((prevArray) => [...prevArray, ...newUsers]);
  };

  return (
    <DataContext.Provider value={{ userArray, addUsers }}>
      {children}
    </DataContext.Provider>
  );
};

export { DataProvider, DataContext };
