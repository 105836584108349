import React from "react";

export const NonTechnical = () => {
    return (
        <>
            <div className="non-technical-test-sec">
                <div className="description text-muted fs-6 mb-4">
                    This section does not require the candidate to take a coding test.
                    Once you fill out this form, the system will only schedule one test with the candidate, which is a face-to-face interview with sheela.
                </div>
                <div className="bg-secondary bg-opacity-10 rounded-3 mb-4 p-3 p-xl-4" style={{ margin: '0 -16px' }}>
                    <div className="fs-4 fw-bold lh-sm mb-3 mb-lg-4">Second Round: Face-to-Face Interview with Sheela</div>
                    <div className="fs-14 lh-sm mb-3 pb-lg-1">
                        In this round, Sheela will conduct a face-to-face interview with the candidate based on your requisition.
                        It is recommended that you must provide a detailed requisition, clearly outlining every aspect of the role and the skills required for thr developer.
                        This will enabled Sheela to conduct an in-depth interview with the candidate.
                    </div>
                    <div className="fs-14 lh-sm" style={{ color: '#a3b932' }}>
                        You can view the result of this in the Panel with the recruiter, including a complete screen recording, webcam view,
                        and mobile stream of the candidate for a through cheating check during the interview.
                        Detailed information from the face-to-face round will also be available, with video and audio of the candidate's responses.
                        Sheela's evaluation, along explanations for why each answer was correct or incorrect, will be provided for review.
                    </div>
                </div>
            </div>
        </>
    )
}