import React, { useEffect, useState } from "react";
// import './issues.scss';
import { Button, NavLink, Form, Card, Accordion } from 'react-bootstrap'
import { useNavigate } from "react-router-dom";
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Modal from "react-bootstrap/Modal";
import { UserAuth } from "../context/AuthContext";
import { getAuth } from "firebase/auth";
import { useNavigationContext } from "../context/NavContext";
import Pagination from "../components/Pagination";

import CryptoJS from 'crypto-js';
// react-quill editor
import ReactQuill, { quill } from 'react-quill';
import "react-quill/dist/quill.snow.css";

// import tostify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { callAxios } from "../utils/util";
import axios from "axios";
// loader
import { Loader } from '../components/loader';
import moment from 'moment';
import searchIcon from '../assets/search.svg'
import { useMyContext } from '../context/MyContext';


// Custom Accordion
function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
        console.log('totally custom!'),
    );

    return (
        <div className="d-flex flex-wrap flex-sm-nowrap gap-2" onClick={decoratedOnClick}>
            {children}
        </div>
    );
}

export const HrReport = () => {
    const navigate = useNavigate();
    const { values, handleChange } = useMyContext()
    const { user, userInfoLoaded, currentUserVal } = UserAuth();
    const { activepage, handlePageClick } = useNavigationContext()
    const [searchVal, setSearchVal] = useState("");
    const [searchValData, setSearchValdata] = useState("");
    const [list, setList] = useState([]);
    const [show, setShow] = useState(false);
    const [noResults, setNoResults] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const itemsPerPage = 10;
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    // pagination

    // For Add Candidate Model
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [startDate, setStartDate] = useState(values?.startDatescheduled != undefined && values?.startDatescheduled != '' ? values?.startDatescheduled : moment().subtract(1, 'months').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(values?.endDatescheduled != undefined && values?.endDatescheduled != '' ? values?.endDatescheduled : moment().format('YYYY-MM-DD'));
    const [status, setStatus] = useState('');
    const [statusD, setStatusD] = useState('')
    const className = ["primary", "success", "info", "warning", "danger"];
    const getIssues = async () => {
        setIsLoading(true)
        const refreshedToken = await currentUserVal?.getIdToken(true);
        const sendData = {
            "startDate": startDate, // optional
            "endDate": endDate, // optional
            "searchString": searchVal,
            "page": currentPage,
            "pageSize": itemsPerPage
        }

        if (user?.userType == "HR") {
            sendData.eMailId = user?.email;
        }
        const headers = {
            'Authorization': `Bearer ${refreshedToken}`,
            'Content-Type': 'application/json'
        };
        axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/reports/hr-candidate`, sendData, { headers }).then((issues) => {
            // console.log(issues);
            const getallIssues = issues.data.list
            // console.log(getallIssues)
            // console.log("isssues2")
            setList(getallIssues);
            setTotalPages(issues?.data.totalPage)
            setNoResults(false)
            setIsLoading(false)
        })
    };

    useEffect(() => {
        getIssues();
    }, [])
    const dateFilter = (event) => {
        handleChange("startDatescheduled", startDate)
        handleChange("endDatescheduled", endDate)
        setIsLoading(true)
        setCurrentPage(1);
        setError(null);
        getIssues();
    };
    const searchFunction = (event) => {
        let searchText = event.target.value;
        // setSearchVal(getremovedVal);
        setSearchVal(searchText);
        setSearchValdata(searchText)
        getIssues();
    }
    const viewDetails = (info, btntype, candidateStatus = "") => {
        if (btntype == 'viewDetail') {
            handleChange("statusscheduled", candidateStatus)
            handleChange("hrscheduled", info?.recruiter_email)
            handleChange("startDatescheduled", startDate)
            handleChange("endDatescheduled", endDate)
            handleChange("showBack", true)
            navigate(`/hrcandidates`);
        }
    }
    // Define the quill toolbar modules
    const toolbarModules = {
        toolbar: {
            container: '#toolbar', // Reference the ID of the custom toolbar
            handlers: {
                // Add custom handlers if necessary
            }
        },
        toolbar: [
            [{ 'font': [] }],
            [{ 'header': '1' }, { 'header': '2' }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            [{ 'list': 'bullet' }, { 'list': 'ordered' }],
            // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            // [{ 'color': [] }, { 'background': [] }],
            ['link', 'image', 'video', 'formula'],
            ['blockquote', 'code-block'], // Quote
            ['clean'] // Remove formatting button
        ],
    };
    // Define the quill formats that are allowed
    const formats = [
        'font', 'header', 'list', 'bullet', 'bold', 'italic', 'underline', 'align', 'link', 'image', 'blockquote'
    ];
    const noRecordFound = () => {
        return (
            <div className="no-data-wrapper bg-white rounded px-3 py-5 mt-2">
                <div className="text-center mx-auto" style={{ maxWidth: '350px' }}>
                    <img src={require('../assets/no-data.jpg')} alt="Candidate" className="img-fluid" />
                    <div className='fs-4 fw-semibold text-danger mt-3'>Sorry no record Found !</div>
                    {/* <div className='small text-muted'>No candidates registered on the selected date</div> */}
                </div>
            </div>
        )
    }

    // getCurrentPathname
    useEffect(() => {
        const currentUrl = window.location.href;
        const currentPath = window.location.pathname
        handleChange("getPagePath", currentPath)
    }, []);
    // getCurrentPathname

    return (
        <div className="IssuesList-page py-3">
            <div className="container-fluid px-xxl-5">
                <div className="border-opacity-75 mb-3 pb-3">
                    <div className="d-flex flex-wrap flex-column flex-md-row align-items-md-center justify-content-between gap-2 gap-xl-5">
                        <div className="fs-4 fw-bold lh-sm">Recruiter Report</div>
                        <div className="date-wrapper d-flex flex-column flex-md-row align-items-md-center gap-2 gap-xl-3 ms-xxl-3">
                            <div className="searchfield-wrapper flex-fill">
                                <input className="form-control rounded-2" id="search" value={searchValData} placeholder="Search by email or name..." onChange={searchFunction} style={{ padding: "0.375rem 1.75rem 0.375rem 0.75rem" }} />
                                <img src={searchIcon} alt="Search Icon" className="position-absolute top-50 end-0 translate-middle" />
                            </div>
                            <Form.Group className="d-flex flex-column flex-md-row align-items-md-center flex-fill gap-1 gap-md-2 gap-xxl-3" controlId="dateFrom">
                                <Form.Label className="fs-6 text-body m-0">From</Form.Label>
                                <Form.Control type="date" className="bg-white bg-opacity-50 border-0 rounded-3 shadow-none px-3 py-2"
                                    max={new Date().toJSON().slice(0, 10)}
                                    defaultValue={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className="d-flex flex-column flex-md-row align-items-md-center flex-fill gap-1 gap-md-2 gap-xxl-3" controlId="dateTo">
                                <Form.Label className="fs-6 text-body m-0">To</Form.Label>
                                <Form.Control type="date" className="bg-white bg-opacity-50 border-0 rounded-3 shadow-none px-3 py-2" s
                                    max={new Date().toJSON().slice(0, 10)}
                                    defaultValue={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                />
                            </Form.Group>

                            <div class="btn-wrapper d-flex flex-column gap-1 gap-md-2 text-center">
                                <label class="d-none d-sm-block d-md-none">&nbsp;</label>
                                <div class="d-flex align-items-center justify-content-center bg-white bg-opacity-50 border-0 rounded-3 shadow-none px-3 py-2 cursor-pointer" style={{ minHeight: '45px' }} onClick={dateFilter}>
                                    <i class="bi bi-search"></i>
                                    <span class="fw-medium d-sm-none">Search</span>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

            <div className="candidates-wrapper px-3">
                <div className='candidate-list d-flex flex-column gap-3'>
                    {isLoading ? (<Loader />) : ""}
                    {list?.length > 0 && list?.map((info, id) => {
                        // const status = getUserStatus(info.is_registered, info.is_registration_completed, info.is_interviewGiven);
                        //const status = getUserStatus(1,0,0);

                        // const statusClassName = getStatusClassName(status);
                        // const status = className[id % 3];
                        const status = "info";
                        return (
                            <div className={`candidateStatusCard bg-white small rounded-3 overflow-auto p-3 ${status}`} key={id} >
                                <div class="info-header border-bottom pb-2 mb-2" >
                                    <div class="row g-2">
                                        <div className="col-12 col-md-6">
                                            <div className="candidate-info">
                                                <div className="candidate-name fs-5 text-truncate text-capitalize lh-sm">{info.recruiter_name}</div>
                                                {/* <div className="cand-email text-capitalize">{info.timezoneName}</div> */}
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="candidate-info text-md-end">
                                                {info.recruiter_email && <div className="cand-email text-lowercase">{info.recruiter_email}</div>}
                                                {/* <div className="cand-email text-capitalize">{info.timezoneName}</div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row g-3 g-xl-2">
                                    <div className="col-6 col-md-3 item-wrapper d-flex flex-column pointer" onClick={() => viewDetails(info, 'viewDetail')}>
                                        <div className="info-box">
                                            <div className="info-label text-clr1 fs-12">Candidates Added</div>
                                            <div className="cand-company text-capitalize">{info.candidates_added}</div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-3 item-wrapper d-flex flex-column pointer" onClick={() => viewDetails(info, 'viewDetail', '1')}>
                                        <div className="info-box">
                                            <div className="info-label text-clr1 fs-12">Registered Candidates</div>
                                            <div className="text-capitalize">{info.registered_candidates} <small>({info.percentage_register_candidates}%) </small></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-3 item-wrapper d-flex flex-column pointer" onClick={() => viewDetails(info, 'viewDetail', '0')}>
                                        <div className="info-box">
                                            <div className="info-label text-clr1 fs-12">Unregistered Candidates</div>
                                            <div className="text-capitalize">{info.naver_register_candidates} <small>({info.percentage_naver_register_candidates}%)</small></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-3 item-wrapper d-flex flex-column pointer" onClick={() => viewDetails(info, 'viewDetail', '3')}>
                                        <div className="info-box">
                                            <div className="info-label text-clr1 fs-12">Fully Registered Candidates</div>
                                            <div className="text-capitalize">{info.fully_registered_candidates} <small>({info.percentage_fully_registered_candidates}%)</small></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-3 item-wrapper d-flex flex-column pointer" onClick={() => viewDetails(info, 'viewDetail', '2')}>
                                        <div className="info-box">
                                            <div className="info-label text-clr1 fs-12">Partially Registered Candidates</div>
                                            <div className="text-capitalize">{info.partially_registered_candidates} <small>({info.percentage_partially_registered_candidates}%)</small></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-3 item-wrapper d-flex flex-column pointer" onClick={() => viewDetails(info, 'viewDetail', '4')}>
                                        <div className="info-box">
                                            <div className="info-label text-clr1 fs-12">Candidates Attempted Interview</div>
                                            <div className="text-capitalize">{info.candidates_attempted_interview} <small>({info.percentage_candidates_attempted_interview}%)</small></div>
                                        </div>
                                    </div>

                                    <div className="col-6 col-md-3 item-wrapper d-flex flex-column"></div>

                                    <div className="col-6 col-md-3 item-wrapper d-flex flex-column"></div>

                                </div>
                            </div>
                        )
                    }
                    )}
                    {list?.length === 0 ? noRecordFound() : ''}
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}
