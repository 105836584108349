import React, { useEffect, useState } from "react";
import './Requisition.scss';
import { Button, NavLink, Form, Card, Accordion } from 'react-bootstrap'
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import searchIcon from '../assets/search.svg'
import { UserAuth } from "../context/AuthContext";
import { Loader } from '../components/loader';
import { statuscode } from '../utils/util'
import { useMyContext } from "../context/MyContext";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const RaiseRequisition = () => {
    const { values, handleChange } = useMyContext()
    const { user, userInfoLoaded, currentUserVal } = UserAuth();
    const navigate = useNavigate();
    const [startDate, setStartDate] = useState(values.raiseRequisitionstartDate != undefined ? values.raiseRequisitionstartDate : moment().subtract(1, 'months').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(values.raiseRequisitionendDate != undefined ? values.raiseRequisitionendDate : moment().format('YYYY-MM-DD'));
    const [opportunityId, setOpportunityId] = useState(values.raiseRequisitionsearch != undefined ? values.raiseRequisitionsearch : "");
    const [filter, seFilter] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [clientList, setClientList] = useState([])

    const CallApi = async (getData, getUrl) => {
        const refreshedToken = await currentUserVal?.getIdToken(true);
        try {
            const res = await fetch(getUrl, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${refreshedToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(getData),
            });
            // Step 5: Handle response
            if (res.ok) {
                const data = await res.json();
                // getdataSubmitted()
                return data
            } else {
                toast.error("Network Error.", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error("Network Error.", {
                position: toast.POSITION.BOTTOM_CENTER
            });
        } finally {

        }
    }

    const formateDateData = (inputDate) => {
        const date = new Date(inputDate);
        const formattedDate = date.toISOString();
        return formattedDate
    }

    const getList = async () => {
        setIsLoading(true)
        const apiUrl = `https://vd2jka7itj.execute-api.us-east-1.amazonaws.com/getRequisitions`
        const sendData = {
            "startDate": formateDateData(startDate),
            "endDate": formateDateData(endDate),
            "opportunityId": opportunityId,
            "filter": filter
        }
        const getCreatedData = await getcreatedrequisitionlist()
        const getResponse = await CallApi(sendData, apiUrl)
        // console.log(getResponse);
        if(getResponse){
            const getCreatereqdata = getResponse.data
            // setClientList(getResponse.data);
            const resultData = await compareOpportunityIds(getCreatereqdata, getCreatedData)
            // console.log(resultData);
            setClientList(resultData); 
        }
        setIsLoading(false)
    }
    const dataFilter = (event) => {
        setIsLoading(true)
        // setError(null);
        getList();
        getcreatedrequisitionlist()
    };//dataFilter
    const handleChangeSearch = (event) => {
        const value = event.target.value;
        // console.log(event);
        setOpportunityId(value);
        handleChange('raiseRequisitionsearch', value)
    };
    useEffect(() => {
        getList();
        // getcreatedrequisitionlist()
    }, [])
    function formatDate(dateString) {
        const date = new Date(dateString);
        const options = { hour: 'numeric', minute: 'numeric', hour12: true };
        const time = new Intl.DateTimeFormat('en-US', options).format(date);

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        return `${day}-${month}-${year} ${time}`;
    }
    const noRecordFound = () => {
        return (
            <div className="no-data-wrapper bg-white rounded px-3 py-5 mt-2">
                <div className="text-center mx-auto" style={{ maxWidth: '350px' }}>
                    <img src={require('../assets/no-data.jpg')} alt="Candidate" className="img-fluid" />
                    <div className='fs-4 fw-semibold text-danger mt-3'>Sorry no record Found !</div>
                    {/* <div className='small text-muted'>No candidates registered on the selected date</div> */}
                </div>
            </div>
        )
    }

    const getcreatedrequisitionlist = async () => {
        const apiUrl = `${process.env.REACT_APP_ADMIN_API}/v3.0/requisitions/get-created-requisition`
        const sendData = {
            "startDate": startDate,
            "endDate": endDate,
        }
        const getResponse = await CallApi(sendData, apiUrl)
        if (getResponse?.statusCode == 200) {
            // console.log(getResponse?.requisitionListing);
            const getRequistionlisting = getResponse?.requisitionListing
            return getRequistionlisting
        }
    }

    // Function to compare opportunityids
    const compareOpportunityIds = (arr1, arr2) => {
        return arr1.map(item1 => {
            // Find a matching item in arr2 using 'find'
            const matchingItem = arr2.find(item2 => item1.opportunityid === item2.opportunityid);
            const isMatch = matchingItem !== undefined;
            return {
                ...item1,
                isOpportunityMatched: isMatch, // Set 'isOpportunityMatched' to true if matched, false otherwise
                reqId: isMatch ? matchingItem.id : null // Get 'id' from the matched item, or null if no match
            };
        });
    };

    useEffect(() => {

    }, [values.raiseRequisitionsearch, values.raiseRequisitionendDate, values.raiseRequisitionstartDate])

    return (
        <div className='RaiseRequisition-page py-3'>
            <div className="container-fluid">
                <div className="d-flex flex-wrap flex-column flex-md-row align-items-md-center justify-content-between row-gap-2 column-gap-3 mb-3">
                    <div className="fs-3 fw-bold lh-sm">Raise Requisition</div>
                    <div className="right-section d-flex flex-wrap flex-xxl-nowrap flex-column flex-md-row gap-2 column-gap-3">
                        <div className="search-wrapper flex-fill">
                            <div className="searchfield-wrapper ms-xxl-auto">
                                <input className="form-control bg-white bg-opacity-50 border-0 shadow-none ps-3 pe-5 py-2" id="search" value={opportunityId} placeholder="Search with Opportunity ID" onChange={handleChangeSearch} />
                                <img src={searchIcon} alt="Search Icon" className="position-absolute top-50 end-0 translate-middle" onClick={dataFilter} />
                            </div>
                        </div>

                        {/* <Form.Group className="filter-cover d-flex flex-fill flex-column flex-md-row align-items-md-center gap-1 gap-md-2 gap-xxl-3">
                            <Form.Label className="fs-6 text-body m-0">Filter</Form.Label>
                            <Form.Select className="bg-white bg-opacity-50 border-0 rounded-3 shadow-none ps-3 py-2" required style={{ minWidth: '175px' }}>
                                <option value="All">All</option>
                                <option value="In Job">In Job</option>
                                <option value="On Notice Period">On Notice Period</option>
                                <option value="No Where Working">No Where Working</option>
                                <option value="Fresher">Fresher</option>
                            </Form.Select>
                        </Form.Group> */}
                        <div className="date-wrapper d-flex flex-column flex-sm-row align-items-md-center gap-2 gap-xxl-3">
                            <Form.Group className="d-flex flex-column flex-md-row align-items-md-center flex-fill gap-1 gap-md-2 gap-xxl-3" controlId="dateFrom">
                                <Form.Label className="fs-6 text-body m-0">From</Form.Label>
                                <Form.Control type="date" className="bg-white bg-opacity-50 border-0 rounded-3 shadow-none px-3 py-2"
                                    max={new Date().toJSON().slice(0, 10)}
                                    defaultValue={startDate}
                                    onChange={(e) => {
                                        setStartDate(e.target.value)
                                        handleChange('raiseRequisitionstartDate', e.target.value)
                                    }}
                                />
                            </Form.Group>
                            <Form.Group className="d-flex flex-column flex-md-row align-items-md-center flex-fill gap-1 gap-md-2 gap-xxl-3" controlId="dateTo">
                                <Form.Label className="fs-6 text-body m-0">To</Form.Label>
                                <Form.Control type="date" className="bg-white bg-opacity-50 border-0 rounded-3 shadow-none px-3 py-2" s
                                    max={new Date().toJSON().slice(0, 10)}
                                    defaultValue={endDate}
                                    onChange={(e) => {
                                        setEndDate(e.target.value)
                                        handleChange('raiseRequisitionendDate', e.target.value)
                                    }}
                                />
                            </Form.Group>
                            <div className="btn-wrapper d-flex flex-column gap-1 gap-md-2 text-center mt-1 mt-sm-0">
                                <label className="d-none d-sm-block d-md-none">&nbsp;</label>
                                <div className="d-flex align-items-center justify-content-center bg-white bg-opacity-50 border-0 rounded-3 shadow-none px-3 py-2 cursor-pointer" style={{ minHeight: '45px' }} onClick={dataFilter}>
                                    <i className="bi bi-search"></i>
                                    <span className="fw-medium d-sm-none ms-2">Search</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="candidates-wrapper">
                    <div className='candidate-list d-flex flex-column gap-3'>
                        {isLoading ? (<Loader />) : ""}
                        {clientList?.length > 0 && clientList?.map((info, id) => {
                            // const status = getUserStatus(info.is_registered, info.is_registration_completed, info.is_interviewGiven);
                            //const status = getUserStatus(1,0,0);

                            // const statusClassName = getStatusClassName(status);

                            return (
                                <div className={`candidateStatusCard bg-white small rounded-3 overflow-auto p-3 ${info?.isOpportunityMatched == false ? "primary pointer" : "success"}`} key={id}>
                                    <div class="info-header border-bottom pb-2 mb-2">
                                        <div class="row g-2">
                                            <div className="col-6 col-md-6">
                                                <div className="candidate-info">
                                                    <div className="candidate-name fs-5 text-truncate text-capitalize lh-sm">{info.new_fullname}</div>
                                                    {info.countryName && <div className="cand-email text-lowercase">{info.countryName}</div>}
                                                    {/* <div className="cand-email text-capitalize">{info.timezoneName}</div> */}
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-6">
                                                <div className="btn-primary text-sm-end">
                                                    <Button variant={info?.isOpportunityMatched == false ? "primary" : "success"} className="custom-button fw-medium rounded-pill py-2" disabled={info?.isOpportunityMatched} onClick={() => navigate(`/new-requisition?q=${btoa(JSON.stringify(info))}`)}>
                                                        {/* {status} */}{info?.isOpportunityMatched == true ? `VEREQ${info?.reqId}` : "Create Requisition"}
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row g-3 g-xl-2">
                                        <div className="col-6 col-md-3 item-wrapper d-flex flex-column">
                                            <div className="info-box">
                                                <div className="info-label text-clr1 fs-12">Opportunity ID</div>
                                                <div className="cand-company text-capitalize">{info.new_opportunityid}</div>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-3 item-wrapper d-flex flex-column">
                                            <div className="info-box">
                                                <div className="info-label text-clr1 fs-12">Skill Required</div>
                                                <div className="text-capitalize">{info.skills}</div>
                                            </div>
                                        </div>
                                        {/* <div className="col-3 col-md-3 item-wrapper d-flex flex-column">
                                <div className="info-box">
                                <div className="info-label text-clr1 fs-12">Account</div>
                                <div className="text-capitalize">{info?.is_job_role_aware}</div>
                                </div>
                            </div> */}
                                        <div className="col-6 col-md-3 item-wrapper d-flex flex-column">
                                            <div className="info-box">
                                                <div className="info-label text-clr1 fs-12">Status Reason</div>
                                                <div className="text-capitalize">{statuscode[info.statuscode]}</div>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-3 item-wrapper d-flex flex-column">
                                            <div className="info-box">
                                                <div className="info-label text-clr1 fs-12">Created By</div>
                                                <div className="text-capitalize">{info?.createdByName}</div>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-3 item-wrapper d-flex flex-column">
                                            <div className="info-box">
                                                <div className="info-label text-clr1 fs-12">Created On</div>
                                                <div className="text-capitalize">{formatDate(info.createdon)}</div>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-3 item-wrapper d-flex flex-column">
                                            <div className="info-box">
                                                <div className="info-label text-clr1 fs-12">Time Zone</div>
                                                <div className="text-capitalize">{info.timezoneName}</div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            )
                        }
                        )}
                        {!isLoading && clientList?.length === 0 ? noRecordFound() : ''}
                    </div>
                </div>
                {/* <ToastContainer /> */}
                {/* <div className="listing-wrapper fs-12">
                    <div className="list-header text-light fw-bold rounded-3 text-nowrap mb-2" style={{ backgroundColor: '#0054ca' }}>
                        <div className="table-row p-3 overflow-auto">
                            <div className="table-cell">Topic</div>
                            <div className="table-cell">Opportunity ID</div>
                            <div className="table-cell">Skill Required</div>
                            <div className="table-cell">Country</div>
                            <div className="table-cell">Time Zone</div>
                            <div className="table-cell">Status Reason</div>
                            <div className="table-cell">Contact</div>
                            <div className="table-cell">Account</div>
                            <div className="table-cell">Created By</div>
                            <div className="table-cell">Created On</div>
                            <div className="table-cell"></div>
                        </div>
                    </div>
                    <div className="list-body d-flex flex-column gap-2">
                        {[...Array(10)].map((info, index) => (
                            <>
                                {index === 2 || index === 5 ?
                                    <div className="table-row bg-white rounded-3 overflow-auto p-3">
                                        <div className="table-cell text-break">002_Graham Shiels</div>
                                        <div className="table-cell text-break">002_Graham Shiels_OP_271223_016</div>
                                        <div className="table-cell">PHP Developer</div>
                                        <div className="table-cell">United States</div>
                                        <div className="table-cell">Queensland time</div>
                                        <div className="table-cell">Analysis</div>
                                        <div className="table-cell">002_Graham Shiels</div>
                                        <div className="table-cell">right move recruitment</div>
                                        <div className="table-cell">Puneet Kanda (Available)</div>
                                        <div className="table-cell">27-01-2023 10:02 PM</div>
                                        <div className="table-cell">
                                            <Button variant="outline-info" className="fs-12 rounded-pill" onClick={()=> navigate('/new-requisition')}>Create Requisition</Button>
                                        </div>
                                    </div> :
                                    <div className="table-row bg-white rounded-3 overflow-auto p-3" key={index}>
                                        <div className="table-cell text-break">002_Graham Shiels</div>
                                        <div className="table-cell text-break">002_Graham Shiels_OP_271223_016</div>
                                        <div className="table-cell">PHP Developer</div>
                                        <div className="table-cell">United States</div>
                                        <div className="table-cell">Queensland time</div>
                                        <div className="table-cell">Analysis</div>
                                        <div className="table-cell">002_Graham Shiels</div>
                                        <div className="table-cell">right move recruitment</div>
                                        <div className="table-cell">Puneet Kanda (Available)</div>
                                        <div className="table-cell">27-01-2023 10:02 PM</div>
                                        <div className="table-cell">
                                            <Button variant="primary" className="fs-12 rounded-pill" onClick={()=> navigate('/new-requisition')}>Create Requisition</Button>
                                        </div>
                                    </div>
                                }
                            </>
                        ))}

                    </div>
                </div> */}
            </div>
            <ToastContainer />
        </div>
    )
}
