import React, { useEffect, useState } from "react";
import './issues.scss';
import { Button, Form } from 'react-bootstrap'

// Quill Editor
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Link } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Loader } from '../../components/loader';

export const IssuesInProgress = ({ allData,getIssues, getCount }) => {

    // For Sidebar list selected card{

    const { user, userInfoLoaded, currentUserVal } = UserAuth();
    const [activeIssue, setActiveIssue] = useState(allData[0])
    const [isSaving, setIsSaving] = useState(false);
    const [noResults, setNoResults]= useState(true)
    const [replies, setReplies] = useState([]);
    const [fileType, setFileType] = useState('');
    const [fileName, setFileName] = useState('');
    const [preview, setPreview] = useState([])
    const [replyText, setReplyText] = useState("");
    const [isLoading, setIsloading] = useState(false);
    const [submitButtonDisable,setSubmitButtonDisable] = useState(false);
    
    useEffect(() => {
        // console.log(user);
        setActiveIssue(allData[0]);
      },[allData])
    function getFileTypeFromUrl(url) {
        // Extract the extension from the URL

        const fileName = url.split('/').pop();
        // const extension = url.split('.').pop();
        const extension = fileName.split('.').pop();
        // Define a basic map of extensions to MIME types
        const mimeTypes = {
            'jpg': 'image/jpeg',
            'jpeg': 'image/jpeg',
            'png': 'image/png',
            'gif': 'image/gif',
            'pdf': 'application/pdf',
            'mp4': 'video/mp4',
            'mp3': 'audio/mpeg',
            'doc': 'application/msword',
            'docx': 'application/msword',
            'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'zip': 'application/zip'
            // Add more extensions as needed
        };
        const fileType = mimeTypes[extension] || 'Unknown file type';
        return {
            fileName,
            fileType
        };
        // Get the MIME type based on the file extension
        return mimeTypes[extension] || 'Unknown file type';
    }
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        // console.log(reply)
        if (replyText === "") {
          document.getElementById("editorBorder-issues-list").style.border = "1px solid red";
          return false;
        } else {
          setIsloading(true)
          const formData = {
            issue_id: activeIssue?.id,
            uid: user?.uid,
            reply: replyText,
            name: user?.displayName,
            email: user?.email,
            userType: user?.userType,
            files: preview,
            attachmentType: "reply"
          };
          //console.log(formData)
          const refreshedToken = await currentUserVal?.getIdToken(true);
          
            setSubmitButtonDisable(true)
          var headers = {
              'Authorization': `Bearer ${refreshedToken}`,
              'Content-Type': 'application/json'
          };
          const response = await axios.post(process.env.REACT_APP_ADMIN_API + "/v4.0/issues/save-reply", formData, { headers });
          if (response?.data?.status === 200) {
            // resetForm();
            setReplyText("");
            setPreview([])
            
            setSubmitButtonDisable(false)
            toast.success("Reply Submitted!", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            getIssuesRepy();
            // document.getElementById("ReplyForm").reset();
            resetForm();
    
          } else {
            
            setSubmitButtonDisable(false)
            document.getElementById("replies-preloader").classList.add("d-none");
            toast.error("Network Issue!", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        }
      };
    
      const resetForm = async () => {
        setReplyText("");
        
        setSubmitButtonDisable(false)
        const element = document.getElementsByClassName("ql-editor");
        element[0].innerHTML = "";
        // await callAxios(
        //   process.env.REACT_APP_ADMIN_API + "/v4.0/issues/get-reply-v2",
        //   { issueId: currentItem?.id },
        //   "post"
        // ).then((replies) => {
    
        //   // console.log(replies.data)
        //   setCurrentItem(replies.data)
        //   document.getElementById("replies-preloader").classList.add("d-none");
        //   document.getElementById("ReplyForm").reset();
        //   // currentItem['issues_replies'] = replies.data
        // });
    
    
    
      };
    const updateStatus = async (status, id) => {
        setIsSaving(true)
        const formData = {
            status: status,
            id: id,
        };

        const refreshedToken = await currentUserVal?.getIdToken(true);
        var headers = {
            'Authorization': `Bearer ${refreshedToken}`,
            'Content-Type': 'application/json'
        };
        const response = await axios.post(process.env.REACT_APP_ADMIN_API + "/v3.0/issues/update-status", formData, { headers });
        if (response?.data?.status === 200) {
            //   resetForm();
            setIsSaving(false)
            
            getIssues();
            getCount()
            // getIssues(activeStatus);
            toast.success("Status updated!", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else {
            //document.getElementById("replies-preloader").classList.add('d-none');
            toast.error("Network Issue!", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    function getInitials(name) {
        return name
            .split(' ')
            .map(word => word.charAt(0).toUpperCase())
            .join('');
    }
    const formatAMPM = (date) => {
        //console.log("LOCAL TIME:",date)
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? "PM" : "AM";
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? "0" + minutes : minutes;
        var strTime = hours + ":" + minutes + " " + ampm;
        return strTime;
    };
    // Define the quill toolbar modules
    const toolbarModules = {
        toolbar: {
            container: '#toolbar', // Reference the ID of the custom toolbar
            handlers: {
                // Add custom handlers if necessary
            }
        },
        toolbar: [
            // [{ 'font': [] }],
            [{ 'header': '1' }, { 'header': '2' }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            [{ 'list': 'bullet' }, { 'list': 'ordered' }],
            // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            // [{ 'color': [] }, { 'background': [] }],
            ['link', 'image',
                // 'video', 'formula'
            ],
            ['blockquote', 'code-block'], // Quote
            ['clean'] // Remove formatting button
        ],
    };

    // Define the quill formats that are allowed
    const formats = [
        'font', 'header', 'list', 'bullet', 'bold', 'italic', 'underline', 'align', 'link', 'image', 'blockquote'
    ];
    const getIssuesRepy = async () => {
        setIsloading(true)
    const refreshedToken = await currentUserVal?.getIdToken(true);
        var headers = {
            'Authorization': `Bearer ${refreshedToken}`,
            'Content-Type': 'application/json'
        };
        
        await axios.post(process.env.REACT_APP_ADMIN_API + "/v4.0/issues/get-reply", { issueId: activeIssue.id }, { headers }).then((replies) => {
            setReplies(replies.data);
            // console.log(replies.data)
            setNoResults(false)
            
        })
        setIsloading(false)
    };
    
    useEffect(() => {
        getIssuesRepy();
    },[activeIssue])
    const uploadFiles = async (event) => {
    
    //   setSubmitButtonDisable(true)
      const refreshedToken = await currentUserVal?.getIdToken(true);
      setIsloading(true)
      const selectedFiles = event.target.files; // Get the selected files
      // console.log(selectedFiles);
    
    //   Check if there are any selected files
      if (selectedFiles.length > 0) {
        const formData = new FormData();
    
        // Append each selected file to the formData object
        for (let i = 0; i < selectedFiles.length; i++) {
          formData.append('files', selectedFiles[i]); // 'files' should match the key in the backend
        }
    
        try {
            
            setSubmitButtonDisable(true)
            const headers = {
                'Authorization': `Bearer ${refreshedToken}`,
                'Content-Type': 'multipart/form-data', // Set content type to form data
            };
            const response = await axios.post(process.env.REACT_APP_ADMIN_API + "/v4.0/issues/upload-to-s3", formData, { headers });
            // const response = await axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/s3/upload`, formData, { headers });
            
            setPreview(response.data)
            setIsloading(false)
            
            setSubmitButtonDisable(false)
          // console.log('Files uploaded successfully:', response.data);
    
          // Clear the file input
          event.target.value = null; // Reset the file input
        } catch (error) {
            
            setSubmitButtonDisable(false)
          console.error('Error uploading files:', error);
        }
      }
    };
    const handleDelete = async(itemUrl) =>{
        
        setSubmitButtonDisable(false)
        const refreshedToken = await currentUserVal?.getIdToken(true);
        // console.log(itemId, itemUrl)
        const updatedAttachments = preview.filter(item => item.upload !== itemUrl);
        setPreview(updatedAttachments)
        const sendData = {
            "url": itemUrl,
            "userType": user?.userType
        }
        try {
            const headers = {
                'Authorization': `Bearer ${refreshedToken}`,
                'Content-Type': 'application/json'
            };
            const response =  await axios.post(process.env.REACT_APP_ADMIN_API + "/v4.0/issues/delete-attachment", sendData, { headers });
            
            setSubmitButtonDisable(false)
        } catch (error) {
            console.error('Error uploading files:', error);
        }

    }
    return (
        <div className="row g-3">
            <div className="col-md-4 col-xxl-3">
                <div className="issues-list-section d-flex flex-column gap-2">
                    {
                        allData?.length > 0 ?
                            allData.map((issue, index) => (
                                <div className={`issue-card rounded-3 pointer p-3 ${activeIssue.id === issue.id ? "selected" : ""}`} key={issue.id} onClick={() => setActiveIssue(issue)}>
                                    <div className="d-flex flex-wrap align-items-center justify-content-between gap-2 mb-1">
                                        <div className="user-name fs-14 text-truncate text-capitalize mb-1">{issue.name}</div>
                                        <div className="issue-status">
                                            {
                                                user?.userType === 'SSA'?
                                                <Form.Select className={`
                                                    ${issue.status == 'open' ? 'bg-warning' : issue.status == 'closed' ? 'bg-success' : issue.status == 'In Progress' ? 'bg-info' : issue.status == 'In Discussion' ? 'bg-secondary' : ''}
                                                    bg-warning bg-opacity-75 h-auto fs-12 fw-medium border-0 rounded-1 shadow-none lh-sm ps-2 pe-4 py-1`} style={{ maxWidth: '90px', backgroundPosition: 'right .35rem center' }}
                                                    onChange={(e) => {
                                                        updateStatus(e.target.value, issue.id);
                                                    }} value={issue.status || ""} required>
                                                    <option value="" disabled >Select status</option>
                                                    <option value="open" disabled>Open</option>
                                                    <option value="In Progress">In Progress</option>
                                                    <option value="In Discussion">In Discussion</option>
                                                    <option value="closed">Closed</option>
                                                </Form.Select>
                                                : <span className={`text-capitalize badge fw-medium border rounded-1 text-bg-warning `} style={{ minWidth: '63px' }}>
                                                In Progress
                                            </span>
                                            }
                                        </div>
                                    </div>

                                    <div className="fs-6 fw-medium text-truncate mb-1">{issue.subject.slice(0, 100)}</div>
                                    <div className="d-flex flex-wrap align-items-center justify-content-between gap-2">
                                        <div className="fs-14 d-flex flex-wrap gap-1">
                                            <span className="name text-uppercase">{new Date(issue.createdAt).toLocaleDateString("en-IN", { day: "2-digit", month: "2-digit", year: "numeric", })}</span>-
                                            <span className="time text-uppercase">{formatAMPM(new Date(issue.createdAt))}</span>
                                        </div>
                                        {/* <span className="badge text-bg-danger fw-medium border rounded-1" style={{ minWidth: '63px' }}>{issue.priority}</span> */}
                                        {/* <span className="badge text-bg-success fw-medium border rounded-1" style={{ minWidth: '63px' }}>Normal</span>   */}
                                        {/* <span className="badge text-bg-warning fw-medium border rounded-1" style={{ minWidth: '63px' }}>Medium</span> */}
                                        <span className={`text-capitalize badge fw-medium border rounded-1 ${issue.priority === 'High' || issue.priority === 'high' ? 'text-bg-danger' : issue.priority === 'Normal' || issue.priority === 'normal' ? 'text-bg-success' : issue.priority === 'Medium' || issue.priority === 'medium' ? 'text-bg-warning' : ''}`} style={{ minWidth: '63px' }}>
                                            {issue.priority}
                                        </span>
                                    </div>
                                </div>
                            )) : <p className="fs-6 fw-medium text-danger text-center p-3 ">No Record Found</p>}
                </div>
            </div>
            <div className="col-md-8 col-xxl-9">
            {isLoading ? (
                        <Loader />
                    ) : ""}
                <div className="issues-details-section w-100 d-flex flex-column gap-2">
                    {/* Editor Section */}
                  
                    <div className="editor-section border border-light border-opacity-50 rounded d-flex flex-column flex-fill p-2" >
                        <form id="ReplyForm">
                        <div className="mb-4" id="editorBorder-issues-list">
                            {/* React Quill Editor */}
                            <ReactQuill id="note-description" className="custom-quill-editor d-flex flex-column flex-fill overflow-auto" theme="snow" placeholder="Please enter reply here.."
                            modules={toolbarModules}
                            formats={formats}
                            onChange={setReplyText}
                            />
                        </div>

                        <div className="end-section px-2">
                            <div className="files-wrapper d-flex flex-wrap gap-2 mb-2">
                            {preview && preview.length > 0 && preview.map((item, index) => (
                                <div className="file-btn fs-14 text-light text-opacity-75 d-flex gap-1 border border-secondary rounded-3 position-relative px-2 py-1 py-xl-2">
                                <span className="deleteAttachment position-absolute z-3 bg-danger rounded-circle d-flex align-items-center justify-content-center pointer text-white" style={{ height: "16px", width: "16px", top: "-8px", right: "-8px" }} onClick={() => { handleDelete(item.upload) }}><i className="bi bi-x"></i></span>

                                <span className="text-truncate">{decodeURIComponent(item.upload.split("_")[1])}</span>
                                <a href={item.upload} target="_blank"><i className="bi bi-cloud-download ms-1"></i></a>
                                </div>
                            ))}

                            </div>
                            <div className="file-upload-wrapper">
                            <Form.Control type="file" id="attachFile" className="d-none" onChange={(e) => uploadFiles(e)} multiple />
                            <label for="attachFile" className="submit-msg-wrapper d-flex align-items-center justify-content-between gap-2 rounded-3 p-2">
                                <div className="d-flex align-items-center gap-1 text-white text-opacity-75 pointer" ><i className="bi bi-paperclip fs-5"></i> Attach file</div>
                                <Button type="submit" variant="primary" size="sm" className="border-light rounded-3 px-4" onClick={handleFormSubmit} disabled={submitButtonDisable}>
                                {
                                    submitButtonDisable?'Pleas Wait ...':'Submit Reply'
                                }
                                    
                                </Button>


                            </label>
                            </div>
                        </div>
                        </form>
                    </div>
                   
                    {
                    replies?.length > 0 ?
                    replies.map((reply, index) => (
                    <div className="info-card bg-white rounded-3 p-3">
                        <div className="d-flex flex-wrap align-items-xl-center flex-sm-nowrap gap-2">
                            <div className="user-img bg-warning bg-opacity-75 border border-secondary border-opacity-50 rounded-circle d-flex align-items-center justify-content-center overflow-hidden" style={{ height: '40px', width: '40px', minWidth: '40px' }}>
                                {/* <img src={require('../resources/images/Avatar.png')} alt="User" className="img-fluid" /> */}
                                <div className="user-sortname text-white fs-6 fw-semibold text-uppercase">{getInitials(reply?.name)}</div>
                            </div>
                            <div className="user-email-info w-100">
                                <div className="row align-items-center g-2">
                                    <div className="col-sm-6">
                                        <div className="user-name fs-6 fw-semibold text-truncate text-capitalize">{(reply?.name)}</div>
                                        <div className="user-team fs-14 text-capitalize">{(reply?.userType == 'HR'?'HR':'NM Admin')} Department</div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="d-flex flex-wrap align-items-center justify-content-sm-end row-gap-1 column-gap-2">
                                            <div className="fs-14 fw-semibold d-flex flex-wrap">
                                            <span className="name text-uppercase">{new Date(reply.createdAt).toLocaleDateString("en-IN", { day: "2-digit", month: "2-digit", year: "numeric", })} </span> -
                                            <span className="time text-uppercase"> {formatAMPM(new Date(reply.createdAt))}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="user-details mt-3 ms-sm-5">
                            <div className="description-wrapper fs-14" dangerouslySetInnerHTML={{ __html: reply?.reply }}>
                                
                            </div>
                            {
                                reply.attachments?.length > 0 ?
                                <div className="files-wrapper d-flex flex-wrap gap-2 mb-2 pt-2">
                                { reply.attachments.map((attachment, index) => (                                    
                                    <div className="file-btn fs-6 d-flex gap-1 border border-secondary border-opacity-50 rounded-3 px-2 py-1 py-xl-2 pointer" onClick={() => window.open(attachment.url, "_blank")}>
                                        { 
                                        getFileTypeFromUrl(attachment.url) == 'application/pdf' ?
                                        <i className="bi bi-file-earmark-pdf-fill"></i>
                                        :getFileTypeFromUrl(attachment.url) == 'application/msword' ?
                                        <i className="bi bi-file-earmark-word-fill"></i>
                                        :getFileTypeFromUrl(attachment.url) == 'application/zip' ?
                                        <i className="bi bi-file-earmark-zip-fill"></i>
                                        :''
                                        }
                                        <span className="text-truncate">{attachment.filename}</span>
                                        <i className="bi bi-cloud-download ms-1"></i>

                                    </div>
                                 ))
                                 }
                                 </div>
                                :''
                                
                            }
                        </div>
                    </div>
                    ))
                    :""
                    }
                    <div className="info-card bg-white rounded-3 p-3">
                        <div className="d-flex flex-wrap align-items-xl-center flex-sm-nowrap gap-2">
                            <div className="user-img bg-info bg-opacity-75 border border-secondary rounded-circle d-flex align-items-center justify-content-center overflow-hidden" style={{ height: '45px', width: '45px', minWidth: '45px' }}>
                                {/* <img src={require('../resources/images/Avatar.png')} alt="User" className="img-fluid" /> */}
                                <div className="user-sortname text-white fs-6 fw-semibold text-uppercase">{getInitials(activeIssue?.name)}</div>
                            </div>
                            <div className="user-email-info w-100">
                                <div className="row align-items-center g-3">
                                    <div className="col-xl-5">
                                        <div className="user-name fs-6 fw-semibold text-truncate text-capitalize">{activeIssue?.name}</div>
                                        {
                                            activeIssue?.userType === 'HR'?
                                            <div className="user-team fs-14 text-opacity-75 text-capitalize">HR Department</div>
                                            :
                                            <div className="user-team fs-14 text-opacity-75 text-capitalize">NM Admin Department</div>
                                        }
                                        
                                    </div>
                                    <div className="col-xl-7">
                                        <div className="d-flex flex-wrap align-items-center justify-content-xl-end row-gap-1 column-gap-2">
                                            <div className="issue_id fs-6 fw-bold text-danger text-capitalize">VE{activeIssue?.id}</div>
                                            <div className="badge text-bg-light fw-medium border border-secondary rounded-1" style={{ minWidth: '63px' }}>{activeIssue.ticket_type}</div>
                                            <div className={`text-capitalize badge text-bg-danger fw-medium border border-secondary rounded-1 ${activeIssue.priority === 'High' || activeIssue.priority === 'high' ? 'text-bg-danger' : activeIssue.priority === 'Normal' || activeIssue.priority === 'normal' ? 'text-bg-success' : activeIssue.priority === 'Medium' || activeIssue.priority === 'medium' ? 'text-bg-warning' : ''}`} style={{ minWidth: '63px' }}>
                                                {activeIssue.priority}
                                            </div>
                                            <div className="fs-14 fw-semibold d-flex flex-wrap ms-1">
                                                <span className="name text-uppercase">{new Date(activeIssue.createdAt).toLocaleDateString("en-IN", { day: "2-digit", month: "2-digit", year: "numeric", })} </span> -
                                                <span className="time text-uppercase"> {formatAMPM(new Date(activeIssue.createdAt))}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="user-details mt-4 ms-sm-5">
                            <div className="subject-heading fs-6 fw-bold mb-3">Sub: {activeIssue.subject}</div>
                            <div className="description-wrapper mb-3" dangerouslySetInnerHTML={{ __html: activeIssue?.description }}>

                            </div>
                            
                            {
                                activeIssue.attachments?.length > 0 ?
                                <div className="files-wrapper d-flex flex-wrap gap-2 mb-2 pt-2">
                                { activeIssue.attachments.map((attachment, index) => (                                    
                                    <div className="file-btn fs-6 d-flex gap-1 border border-secondary border-opacity-50 rounded-3 px-2 py-1 py-xl-2 pointer" onClick={() => window.open(attachment.url, "_blank")}>
                                        { 
                                        getFileTypeFromUrl(attachment.url) == 'application/pdf' ?
                                        <i className="bi bi-file-earmark-pdf-fill"></i>
                                        :getFileTypeFromUrl(attachment.url) == 'application/msword' ?
                                        <i className="bi bi-file-earmark-word-fill"></i>
                                        :getFileTypeFromUrl(attachment.url) == 'application/zip' ?
                                        <i className="bi bi-file-earmark-zip-fill"></i>
                                        :''
                                        }
                                        <span className="text-truncate">{attachment.filename}</span>
                                        <i className="bi bi-cloud-download ms-1"></i>

                                    </div>
                                 ))
                                 }
                                 </div>
                                :''
                                
                            }
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
