import React, { useState, useCallback, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import imgDefault from '../assets/Avatar.png';
import BannerImg from '../assets/interview.webp';
import { useDropzone } from 'react-dropzone';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import AWS from 'aws-sdk';
import axios from 'axios';
import moment from 'moment';
import { Rating } from 'react-simple-star-rating'
import { UserAuth } from '../context/AuthContext';
import { getAuth, database, secondDatabase } from '../firebaseConfig';
import CryptoJS from 'crypto-js';
import { Loader } from '../components/loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// pagination
import Pagination from '../components/Pagination';
// pagination
export const HRCandidatesList = () => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const [dataisLoading, setdataisLoading] = useState(true)
  const auth = getAuth();
  const { user, userInfoLoaded, currentUserVal } = UserAuth();
  const [scheduleClient, setScheduleClient] = useState([]);
  const [rating, setRating] = useState(0);
  const [culturalRating, setCulturalRating] = useState(0)


  const [formData, setFormData] = useState({
    user_name: '',
    email: '',
    contact_no: '',
    current_company: '',
    interview_date: '',
    interview_time: '',
    other_details: '',
    resume_url: null,
    added_by_email: '',
    added_by_name: '',
    job_status: '',
    notice_period: '',
    current_ctc: '',
    expected_ctc: '',
    source_of_candidate: '',
    is_notice_buyout: '',
    job_role: '',
    reason_job_change: '',
    salaryNegotiation: '',
    candidate_ques: '',
    interview_medium: '',
    cultural_fit: '',
    request_id: '',
    requisition_id: '',
  });
  // React Bootstrap Model
  const [show, setShow] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [file, setFile] = useState(null);
  const acceptedFileTypes = ['docx', 'pdf', 'doc'];
  const [error, setError] = useState('');
  const [loading, setIsLoading] = useState(false);
  const [phone, setPhone] = useState("");
  const [isDisable, setDisable] = useState(true);
  const [checkMessage, setMessage] = useState("");
  const [otherVisibility, setOtherVisibility] = useState(true);
  const [isProgress, setisProgress] = useState(false);
  const [isChecking, setisChecking] = useState(false);
  const [selectedCandidate, setCandidate] = useState("");
  const [statuses] = useState([
    { value: 'Chase Up', label: 'Chase up Call' },
    { value: 'Not Picked', label: 'Not Picked up Call' },
    { value: 'Manager Round', label: 'Promoted for managerial Round' },
    { value: 'Offer Given', label: 'Offer Given' },
    { value: 'Selected', label: 'Got Selected' },
    { value: 'Not Interested', label: 'Not Interested' },
    { value: 'Too Much Salary Expectation', label: 'Too Much Salary Expectation' },
    { value: 'Rejected in Second Round', label: 'Rejected in Second Round' },
  ]);
  const [nextStep, setNextStep] = useState('');
  const [note, setNote] = useState('');
  const [getCandidateList, setCandidateList] = useState([])
  const [selectedStatus, setSelectedStatus] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const itemsPerPage = 10;

  const handleSubmitStatus = async (event) => {
    event.preventDefault();
    // console.log("hit")
    // Handle form submission, e.g., send data to API
    // console.log('Status:', event.target.status.value);
    // console.log('Next Step:', nextStep);
    // console.log('Note:', note);

    const refreshedToken = await currentUserVal?.getIdToken(true);
    var headers = {
      'Authorization': `Bearer ${refreshedToken}`,
      'Content-Type': 'application/json'
    };
    try {
      setisProgress(true);
      // const response = await axios.post("https://dev.virtualemployee.com/admin-panel/api/v3.0/requisitions/add-status", {
      const response = await axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/requisitions/add-status`, {
        requisitionId: selectedCandidate?.request_id,
        name: selectedCandidate?.user_name,
        email: selectedCandidate?.email,
        type: 'candidate',
        status: event.target.status.value,
        nextStep: nextStep,
        note: note,
        addedBy: user?.displayName,
        addedByEmail: user?.email
      }, { headers });
      // console.log('Form submitted successfully');
      setNextStep('');
      setNote('');
      setisProgress(false);
      toast.success('Candidate status has been updated successfully.', {
        position: toast.POSITION.BOTTOM_CENTER
      });
      closeStatusModal();
    } catch (error) {
      setisProgress(false);
      console.error('Error submitting form:', error);
      // Handle error
    }
  };

  // HRCandidateList
  const HRCandidateList = async (dateData) => {
    let filterObj = dateData
    const getpagenumber = dateData?.selectStatustype == "yes" ? 1 : currentPage;
    filterObj.page = getpagenumber
    filterObj.pageSize = itemsPerPage;
    setIsLoading(true);
    if (user?.userType == "HR") {
      // filterObj.added_by_email = user?.email;
      filterObj.eMailId = user?.email;
      // filterObj.page=currentPage;
      // filterObj.pageSize=itemsPerPage;
    }
    const refreshedToken = await currentUserVal?.getIdToken(true);
    var headers = {
      'Authorization': `Bearer ${refreshedToken}`,
      'Content-Type': 'application/json'
    };
    axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/candidates/get-schdule-candidate-list?page=${currentPage}&pageSize=${itemsPerPage}`, filterObj, { headers })
      // axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/reports/get-hr-interview-medium`, filterObj, { headers })
      .then(function (response) {
        // console.log("dataresponse")
        const responseData = response?.data.list
        setCandidateList(responseData)
        setTotalPages(response?.data.totalPage)
        setIsLoading(false);
      })
      .catch(function (error) {
        setIsLoading(false);
      });
  }
  // HRCandidateList

  // buttonClick
  const handleClick = (type) => {
    if (type === 'prev' && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (type === 'next' && currentPage < totalPages) {
      setCurrentPage(currentPage + 1)
    }
  }
  // buttonClick

  // date
  const handleStartDateChange = (e) => {
    setStartDate(e.target.value)
  }
  const handleEndDateChange = (e) => {
    setEndDate(e.target.value)
  }
  const handleApplyClick = () => {
    if ((startDate == "" || endDate == "")) {
      setError("Please select from and to Date.");
      return;
    } else {
      var dateData = {
        "fromDate": startDate,
        "toDate": endDate,
        "interview_medium": selectedStatus
      };
      setCurrentPage(1)
      HRCandidateList(dateData)
    }
  }
  // date

  //Fetch Data from Api 
  useEffect(() => {
    setdataisLoading(true);
    // fetchScheduleCandidate();
    var dateData = {
      "fromDate": startDate != '' ? startDate : "",
      "toDate": endDate != '' ? endDate : "",
      "interview_medium": selectedStatus
    };
    HRCandidateList(dateData)
    setdataisLoading(false);
  }, [currentPage, selectedStatus]);

  const handleRating = (rate) => {
    setRating(rate)
  }

  const handleCulturalRating = (rate) => {
    setCulturalRating(rate)
  }

  //fetchScheduleCandidate
  const fetchScheduleCandidate = async () => {
    let fetchcandidatequery = {
      "operation": "fetchschedulecandidate",
      "page": currentPage,
      "pageSize": itemsPerPage,
      "interview_medium": "",
    };
    if (user?.userType == "HR") {
      fetchcandidatequery.role = "HR";
      // fetchcandidatequery.added_by_email = user?.email;  
      fetchcandidatequery.eMailId = user?.email;
      // fetchcandidatequery.page = currentPage;
      // fetchcandidatequery.pageSize = itemsPerPage;
    }
    const refreshedToken = await currentUserVal?.getIdToken(true);
    var data = JSON.stringify(fetchcandidatequery);
    var headers = {
      'Authorization': `Bearer ${refreshedToken}`,
      'Content-Type': 'application/json'
    };
    axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/candidates/get-schdule-candidate-list?page=${currentPage}&pageSize=${itemsPerPage}`, data, { headers })
      .then(function (response) {
        let dashDatares = response.data;
        let data = dashDatares.data ? dashDatares.data : {};
        setScheduleClient(data);
        setIsLoading(false);
      }).catch(function (error) {
        // console.log(error);
        setIsLoading(false);

      });
  }


  const s3 = new AWS.S3({
    region: 'us-east-1',
    accessKeyId: process.env.REACT_APP_ACCESSKEYID,
    secretAccessKey: process.env.REACT_APP_ACCESSKEY
  });
  // For Add Candidate Model
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // For Add Candidate Status Model
  const closeStatusModal = () => setShowStatusModal(false);
  const openStatusModal = () => setShowStatusModal(true);


  const CandidateDetails = (info, btntype) => {
    let requisitionId = info?.request_id;
    let sendInfo = { "requisitionId": requisitionId, "email": info?.email }
    if (btntype == 'viewDetail') {
      const encryptedQuery = CryptoJS.AES.encrypt(JSON.stringify(sendInfo), 'secret_key').toString();
      navigate(`/hr-candidate-details?query=${encodeURIComponent(encryptedQuery)}`);
    }
  }

  //select candidate and show in Modal
  const addCandidateStatus = (candidateInfo) => {
    // console.log(candidateInfo);
    setCandidate(candidateInfo);
    openStatusModal();
  }


  const handleDrop = useCallback((acceptedFiles) => {
    setError("");
    const selectedFile = acceptedFiles[0];
    if (!acceptedFileTypes.includes(selectedFile.name.split('.').pop())) {
      setError(`File ${selectedFile.name} has an invalid file type. Only ${acceptedFileTypes.join(', ')} are accepted.`);
      return;
    }

    setFile(selectedFile);
  }, []);
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
  };


  //Upload resume Function
  const uploadResume = async () => {
    if (error === "") {
      if (file) {
        setLoading(true);
        const fileVal = file || {};
        const fileName = file?.name;
        const fileType = file?.type;

        try {
          // Get the Firebase authentication token
          const refreshedToken = await user?.getIdToken(true);

          // Include the token in the request headers
          const headers = {
            'Authorization': `Bearer ${refreshedToken}`,
            'Content-Type': 'multipart/form-data', // Set content type to form data
          };

          const formData = new FormData();
          formData.append('file', fileVal);
          formData.append('bucketName', 'interviewer-profile');
          formData.append('key', `/ResumeByHR/${fileName}`);
          formData.append('contentType', fileType);
          formData.append('isEncoding', false);
          formData.append('ContentEncoding', '');
          const response = await axios.post(process.env.REACT_APP_S3UPLOAD, formData, { headers });

          setLoading(false);
          return response.data.uploadResponse;
        } catch (error) {
          console.error(error);
          setLoading(false);
        }
      }
    } else {
      setLoading(false);
      setError("Please select appropriate file type and mark agree to upload.");
      return;
    }
  };

  //Check email
  const checkEmail = async () => {
    try {
      setisChecking(true);
      const refreshedToken = await currentUserVal?.getIdToken(true);
      const data = JSON.stringify({
        "operation": "checkcandidate",
        "checkby": "email",
        "email": formData?.email
      });

      const headers = {
        'Authorization': `Bearer ${refreshedToken}`,
        'Content-Type': 'application/json'
      };

      // Make a POST request to the API endpoint
      const response = await axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/candidates/check-candidate`, data, { headers });
      let isuserExist = response.data?.data;
      if (isuserExist?.isExist == "1") {
        setisChecking(false);
        setMessage(`Added by ${isuserExist?.added_by} on ${moment(isuserExist?.created).utc().format('YYYY-MM-DD')}`);
        setOtherVisibility(true);
      }
      else {
        setOtherVisibility(false);
        setMessage("");
        setisChecking(false);

      }
    } catch (error) {
      // Handle errors that occurred during the request
      // console.log(error);
      setisChecking(false);

      // You might want to throw the error or handle it differently based on your needs
      throw error;
    }
  }

  //Check contact Number exist or Not
  const checkCandidateContact = async (contact_no) => {
    try {
      setisChecking(true);
      const refreshedToken = await currentUserVal?.getIdToken(true);
      const data = JSON.stringify({
        "operation": "checkcandidate",
        "checkby": "number",
        "contact_no": contact_no
      });
      const headers = {
        'Authorization': `Bearer ${refreshedToken}`,
        'Content-Type': 'application/json'
      };

      // Make a POST request to the API endpoint
      const response = await axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/candidates/check-candidate`, data, { headers });
      setisChecking(false);

      // Return the response data
      return response.data?.data;
    } catch (error) {
      // Handle errors that occurred during the request
      setisChecking(false);
      // console.log(error);
      // You might want to throw the error or handle it differently based on your needs
      throw error;
    }
  };


  const handleOnChangeNumber = (contact_val, country) => {
    const phoneInput = document.querySelector('.react-tel-input input');
    const parsedNumber = phoneInput?.value;
    setPhone(contact_val);

    if (parsedNumber) {
      const cleanNumber = parsedNumber.replace(/[\s()-]/g, '');
      const localNumber = cleanNumber.replace(`+${country.dialCode}`, '');
      if (localNumber.length == 10) {

        checkCandidateContact(contact_val)
          .then((response) => {

            if (response?.isExist == 1) {
              setMessage(`Added by ${response?.added_by} on ${moment(response?.created).utc().format('YYYY-MM-DD')}`);
              setDisable(true);
            }
            else {
              setDisable(false);
              setMessage("");
            }

            // Do something with the result
          })
          .catch((error) => {
            console.error("Error checking candidate contact:", error);
            // Handle the error
          });
      }
    }


  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let candidateData = formData;
    candidateData.added_by_email = user?.email;
    candidateData.added_by_name = user?.displayName;
    candidateData.added_by_id = user?.uid;
    candidateData.contact_no = phone;
    candidateData.comm_skill_rating = rating;
    candidateData.cultural_fit_rating = culturalRating;
    if (file) {
      setisProgress(true);
      const url = await uploadResume();
      // console.log(url);
      candidateData.resume_url = url;
      setFormData(candidateData);

      //let getToken = await auth?.currentUser?.getIdToken();
      // let getToken = user?.accessToken;
      const refreshedToken = await currentUserVal?.getIdToken(true);
      candidateData.operation = "addcandidate";
      var data = JSON.stringify(candidateData);
      var headers = {
        'Authorization': `Bearer ${refreshedToken}`,
        'Content-Type': 'application/json'
      };
      axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/candidates/add-candidate`, data, { headers })
        .then(function (response) {
          setIsLoading(false);
          setisProgress(false);
          handleClose();

        }).catch(function (error) {
          // console.log(error);
          setisProgress(false);
          setIsLoading(false);


        });
    }
    else {
      setLoading(false);
      setisProgress(false);
      setError("Please select candidate Resume.");
    }


  };

  function Dropzone({ onDrop }) {
    const onDropCallback = useCallback((acceptedFiles) => {
      onDrop(acceptedFiles);
    }, [onDrop]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop: onDropCallback,
      accept: acceptedFileTypes.join(','),
    });

    return (
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <div className='upload-icon mb-2'><i className="bi bi-cloud-arrow-up-fill fs-3 text-success lh-1"></i></div>
        {
          isDragActive ?
            <p>Drop the files here ...</p> :
            <div className='text-muted'>
              <p>Drop your candidate resume here or Browse.<br /> File should be (DOCX, PDF, DOC ) only</p>
              <button className='btn btn-sm btn-success rounded-pill px-3'>Browse File</button>
            </div>
        }
      </div>
    );
  }

  // pagination
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    // Perform any action related to page change, e.g., fetching new data
  };
  // pagination

  const handleSelectChange = (event) => {
    const interviewMedium = event.target.value;
    setSelectedStatus(interviewMedium);
    setCurrentPage(1)
    var dateData = {
      "fromDate": startDate,
      "toDate": endDate,
      "interview_medium": interviewMedium,
      "selectStatustype": "yes"
    };
    setCurrentPage(1)
    HRCandidateList(dateData)
  };

  return (
    <div className='dashboard-page py-3'>
      {loading ? <Loader /> : ""}
      <div className='container-fluid'>

        <div className='row align-items-center position-relative'>
          <div className='col-xl-4 col-md-4'>
            <div className="fs-4 fw-semibold">HR Candidates List</div>
          </div>
          <div className='col-xl-4 col-md-4'>
            <select className="form-select" aria-label="Default select example" value={selectedStatus} onChange={handleSelectChange}>
              <option value="">Select Status</option>
              <option value="NM Interview">NM Interview</option>
              <option value="In Person">In Person</option>
              <option value="Other Interview Tool">Other Interview Tool</option>
            </select>
          </div>
          <div className='col-xl-4 col-md-4 mt-2 mt-md-0'>
            <div className="date-filter d-flex align-items-center gap-1 bg-white rounded-2 px-3 ms-md-auto" style={{ maxWidth: '400px' }}>
              <input type="date" className="form-control border-0 ps-0" id="startDate" value={startDate} onChange={handleStartDateChange} required />
              <input type="date" className="form-control border-0 ps-0" id="endDate" value={endDate} onChange={handleEndDateChange} required />
              <button type='button' className='btn btn-sm btn-primary px-3 lh-1' onClick={() => handleApplyClick()}>Apply</button>
            </div>
          </div>
          {error && <p className="text-danger errMsg">{error}</p>}

        </div>

        {/* <div className='cmpny-list-wrapper bg-white rounded p-3'>
          <div className='d-flex flex-column flex-sm-row align-items-sm-center justify-content-sm-between gap-2'>
            <div className='left-sec fs-5 fw-medium'>
              <i className="bi bi-journal-album"></i> HR Candidates List
            </div>
          </div>
        </div> */}
        {/* <Button variant="primary" className="px-3" onClick={handleShow}>
            <i className="bi bi-person-plus-fill"></i> Add Candidate
          </Button> */}
        <div className="candidates-wrapper">
          <div className="candidate-list">
            {getCandidateList?.length >= 1 ?
              <>
                {getCandidateList.map((info, id) => {
                  return (
                    <div className="info-cards card small bg-white rounded overflow-auto p-3 mt-2" key={id}>
                      <div className="row g-3 g-xl-2">
                        <div className="col-md-4 col-xl-3 item-wrapper d-flex flex-column gap-2">
                          <div className="info-box">
                            <div className="img-wrapper mb-2">
                              <img src={imgDefault} referrerPolicy="no-referrer" alt="Candidate" className="img-fluid object-fit-cover border border-2 border-secondary border-opacity-75 rounded-1" style={{ width: '45px', height: '40px' }} />
                            </div>
                            <div className="cand-name fs-6 fw-medium text-truncate text-capitalize">{info?.user_name}</div>
                            {info?.email && <div className="cand-email text-muted text-truncate text-lowercase"><i className="bi bi-envelope-fill"></i> {info?.email}</div>}
                          </div>
                        </div>
                        <div className="col-6 col-md-4 col-xl-3 item-wrapper d-flex flex-column gap-2">
                          <div className="info-box">
                            <div className="info-label text-secondary">Current Company</div>
                            <div className="cand-contact text-truncate text-capitalize">{info?.current_company}</div>
                          </div>

                          <div className="info-box">
                            <div className="info-label text-secondary">Notice Period</div>
                            <div className="cand-exp text-capitalize">{info?.notice_period}</div>
                          </div>
                        </div>
                        <div className="col-6 col-md-4 col-xl-3 item-wrapper d-flex flex-column gap-2">
                          <div className="info-box action-wrapper">
                            <div className="info-label text-secondary">Interview Time</div>
                            <div className="cand-lang btn-success text-capitalize">{info?.interview_time}</div>
                          </div>

                          <div className="info-box">
                            <div className="info-label text-secondary">Interview Date</div>
                            <div className="regis-date text-capitalize">{moment(info?.interview_date).utc().format('YYYY-MM-DD')}</div>
                          </div>
                        </div>
                        <div className="col-xl-3 item-wrapper d-flex flex-column gap-2">
                          <div className="info-box">
                            <div className="info-label text-secondary">Job Status</div>
                            <span className={`fs-12 text-capitalize badge rounded-pill fw-medium px-3 ${info.job_status === 'In Job' ? 'text-bg-success' : 'text-bg-secondary' && info.job_status.toLowerCase() == 'currently on notice period' ? 'text-bg-danger' : 'text-bg-success' && info.job_status === 'No Where Working' ? 'text-bg-warning' : 'text-bg-secondary'}`} style={{ minWidth: '90px' }}>
                              {info?.job_status.toLowerCase() == 'currently on notice period' ? "On Notice Period" : info.job_status}
                            </span>
                          </div>

                          <div className="action-wrapper d-flex flex-wrap gap-2 mt-1">
                            <Button type='button' variant="light" size="sm" className="border px-3" onClick={() => CandidateDetails(info, 'viewDetail')}>View Details</Button>
                            <Button type='button' variant="success" size="sm" className="px-3" onClick={() => addCandidateStatus(info)}><i className="bi bi-plus-lg"></i> Add Status</Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
                }
                {/* Start: Pagination code */}
                <div className="pagination-wrapper text-center mt-3">
                  {getCandidateList?.length >= 1 ?
                    // <div className='bg-white d-inline-flex align-items-center border rounded overflow-hidden'>
                    //   <button type="button" className="btn btn-secondary border-0 rounded-0 py-2" onClick={() => handleClick('prev')} disabled={currentPage === 1}>Prev</button>
                    //   <span className='px-3'>Page <span className="fw-bold text-primary">{currentPage}</span> of <span className="fw-bold text-primary">{totalPages}</span></span>
                    //   <button type="button" className="btn btn-primary border-0 rounded-0 py-2" onClick={() => handleClick('next')} disabled={currentPage === totalPages}>Next</button>
                    // </div> 
                    <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
                    : ''}
                </div>
                {/* End: Pagination code */}
              </> :
              <div>
                {loading === true ? <Loader /> : ""}
                <div className="no-data-wrapper bg-white rounded px-3 py-5 mt-2">
                  <div className="text-center mx-auto" style={{ maxWidth: '350px' }}>
                    <img src={require('../assets/no-data.jpg')} alt="Candidate" className="img-fluid mt-2" />
                    <div className='fs-4 fw-semibold text-danger mt-3'>Sorry no record Found !</div>
                    <div className='small text-muted'>Whoops... this information is not available for a moment</div>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
        <ToastContainer />
      </div>

      {/* Add New Candidate Model */}
      <Modal show={show} onHide={handleClose} centered backdrop="static" keyboard={false} size="xl" contentClassName="border-0 border-5 border-top border-primary rounded-0 rounded-bottom">
        <Modal.Body className="modal-body px-3 px-xl-5 py-4">
          <h1 className="modal-title fs-4 fw-fw-semibold text-center mb-3" id="addCandidate">Add New Candidate</h1>
          <Form onSubmit={handleSubmit}>
            <div className="row g-2 gx-sm-3">
              <div className="col-sm-6 col-lg-4">
                <Form.Group>
                  <Form.Label>Contact</Form.Label>
                  <PhoneInput
                    country={"in"}
                    countryCodeEditable={false}
                    inputclassName="w-100 h-100 border"
                    autoComplete={'false'}
                    value={phone}
                    onChange={handleOnChangeNumber}
                    inputProps={{
                      name: 'phone',
                      autoComplete: 'off',
                      required: true
                    }}
                    style={{ height: '45px' }}
                  />
                  <div className="small text-danger mt-1">{checkMessage}</div>
                </Form.Group>
              </div>
              <div className="col-sm-6 col-lg-4">
                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                    autoComplete="off"
                    disabled={isDisable}
                    onBlur={checkEmail}
                    required />
                </Form.Group>
              </div>
              <div className="col-lg-4">
                <Form.Group>
                  <Form.Label>Source Of Candidate Options </Form.Label>
                  <Form.Select name="source_of_candidate" onChange={handleInputChange} required
                    disabled={otherVisibility}
                  >
                    <option value={""}>Select Source</option>
                    <option value={"job_boards"}>Job Boards (e.g., Indeed, Monster need text box)</option>
                    <option value={"company_website"}>Company Website</option>
                    <option value={"social_media"}>Social Media (e.g., LinkedIn, Twitter, Facebook)</option>
                    <option value={"employee_referral"}>Employee Referral</option>
                    <option value={"recruitment_agencies"}>Recruitment Agencies</option>
                    <option value={"campus_recruitment"}>Campus Recruitment</option>
                    <option value={"networking_events"}>Networking Events</option>
                    <option value={"internal_application"}>Internal Application</option>
                    <option value={"headhunting"}>Headhunting</option>
                    <option value={"others"}>Others</option>
                  </Form.Select>
                </Form.Group>
              </div>
            </div>

            <div className={otherVisibility ? "d-none" : ""}>
              {/* Verification of Basic Details Section */}
              <div id="basicDetails" className="row g-2 gx-sm-3 mt-1">
                <div className="col-sm-6 col-lg-4">
                  <Form.Group>
                    <Form.Label>Full Name</Form.Label>
                    <Form.Control type="text" name="user_name" value={formData.user_name} onChange={handleInputChange} autoComplete="off" required />
                  </Form.Group>
                </div>
                <div className="col-sm-6 col-lg-4">
                  <Form.Group>
                    <Form.Label>Current Company</Form.Label>
                    <Form.Control type="text" name="current_company" value={formData.current_company} onChange={handleInputChange} autoComplete="off" required />
                  </Form.Group>
                </div>
                <div className="col-lg-4">
                  <Form.Group>
                    <Form.Label>Job status</Form.Label>
                    <Form.Select name="job_status" onChange={handleInputChange} required>
                      <option value="">Select Job Status</option>
                      <option value="In Job">In Job</option>
                      <option value="Notice Period">Notice Period</option>
                      <option value="Nowhere Working">Nowhere Working</option>
                    </Form.Select>
                  </Form.Group>
                </div>
              </div>

              {/* Suitability and Expectations Section */}
              <div id="interviewSchedule" className="row g-2 gx-sm-3 mt-1">
                <div className="col-sm-6 col-lg-4">
                  <Form.Group>
                    <Form.Label>Current CTC</Form.Label>
                    <Form.Control type="text" name="current_ctc" value={formData.current_ctc} onChange={handleInputChange} autoComplete="off" required />
                  </Form.Group>
                </div>
                <div className="col-sm-6 col-lg-4">
                  <Form.Group>
                    <Form.Label>Expected CTC</Form.Label>
                    <Form.Control type="text" name="expected_ctc" value={formData.expected_ctc} onChange={handleInputChange} autoComplete="off" required />
                  </Form.Group>
                </div>
                <div className="col-sm-6 col-lg-4">
                  <Form.Group>
                    <Form.Label>Notice Period</Form.Label>
                    <Form.Select name="notice_period" onChange={handleInputChange} required>
                      <option value="">Select Notice Period</option>
                      <option value="Immidiate Joiner">Immidiate Joiner</option>
                      <option value="15 Days">15 Days</option>
                      <option value="1 Month">1 Month</option>
                      <option value="2 Month">2 Month</option>
                      <option value="3 Month">3 Month</option>
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="col-sm-6 col-lg-4">
                  <Form.Group>
                    <Form.Label>Possibility Of Notice BuyOut</Form.Label>
                    <Form.Select name="is_notice_buyout" onChange={handleInputChange} required>
                      <option value="">Select Notice Possibility</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="col-sm-6 col-lg-4">
                  <Form.Group>
                    <Form.Label>Job Role</Form.Label>
                    <Form.Control type="text"
                      name="job_role"
                      value={formData.job_role}
                      onChange={handleInputChange}
                      autoComplete="off" required />
                  </Form.Group>
                </div>
                <div className="col-sm-6 col-lg-4">
                  <Form.Group>
                    <Form.Label>Reason For Job Change</Form.Label>
                    <Form.Control type="text"
                      name="reason_job_change"
                      value={formData.reason_job_change}
                      onChange={handleInputChange}
                      autoComplete="off" required />
                  </Form.Group>
                </div>
                <div className="col-12 mt-4">
                  <Form.Group className="d-flex align-items-center flex-wrap gap-4 h-100">
                    <Form.Label>Possibility Of Salary Negotiation</Form.Label>
                    <div className="">
                      <Form.Check
                        inline
                        label="Yes"
                        value="Yes"
                        name="salaryNegotiation"
                        type="radio"
                        checked={formData.salaryNegotiation == 'Yes' ? true : false}
                        onChange={handleInputChange}

                        id="inline-radio-1"
                        required
                      />

                      <Form.Check
                        inline
                        label="No"
                        name="salaryNegotiation"
                        type="radio"
                        value="No"
                        checked={formData.salaryNegotiation == 'No' ? true : false}
                        id="inline-radio-2"
                        onChange={handleInputChange}
                      />
                    </div>
                  </Form.Group>
                </div>
              </div>

              {/* Upload Section */}
              <div id="quesAndResume" className="row g-2 gx-sm-3 mt-1">
                <div className="col-sm-6">
                  <Form.Group>
                    <Form.Label>Candidate Questions</Form.Label>
                    <Form.Control as="textarea" rows={5} name="candidate_ques"
                      value={formData.candidate_ques}
                      onChange={handleInputChange}
                      autoComplete="off" required />
                  </Form.Group>
                </div>
                <div className="col-sm-6">
                  <Form.Group>
                    <Form.Label>Upload File</Form.Label>
                    <label htmlFor="uploadResume" className="w-100 bg-warning bg-opacity-10 border-warning rounded-3 small text-center py-2" style={{ border: '1.5px dashed' }}>
                      <Dropzone acceptedFiles={['application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/pdf', 'application/msword']} onDrop={handleDrop} />
                      {file ?
                        <p key={file.name} className='bg-light border py-2 px-3 rounded-pill small text-truncate mx-auto mt-3' style={{ maxWidth: '300px' }}>
                          {file.name} ({file.size} bytes)
                        </p> : null
                      }
                    </label>
                    {error && <p className="small text-danger mt-2">{error}</p>}
                  </Form.Group>
                </div>
              </div>

              {/* Rating Section */}
              <div id="culturalFit" className="row g-2 gx-sm-3 mt-1">
                <div className="col-sm-6 col-lg-4">
                  <Form.Group>
                    <Form.Label>Communication Skills</Form.Label>
                    <div className='start-rating d-flex align-items-center gap-3'>
                      <Rating onClick={handleRating} size={35}
                        initialValue={rating}
                        allowFraction
                        // showTooltip 
                        fillColorArray={['#f14f45', '#f16c45', '#f18845', '#f1b345', '#f1d045']}
                      />
                    </div>
                  </Form.Group>
                </div>

                <div className="col-sm-6 col-lg-4">
                  <Form.Group>
                    <Form.Label>Cultural Fit</Form.Label>
                    <div className='start-rating d-flex align-items-center gap-3'>
                      <Rating onClick={handleCulturalRating} size={35}
                        initialValue={culturalRating}
                        allowFraction
                        // showTooltip 
                        fillColorArray={['#f14f45', '#f16c45', '#f18845', '#f1b345', '#f1d045']}
                      />
                    </div>
                  </Form.Group>
                </div>

                <div className="col-lg-4">
                  <Form.Group>
                    <Form.Label>Reason for Cultural Fit</Form.Label>
                    <Form.Control as="textarea"
                      rows={2} name="cultural_fit"
                      autoComplete="off"
                      onChange={handleInputChange}
                      required />
                  </Form.Group>
                </div>
              </div>

              {/* Interview Schedule Section */}
              <div id="interviewSchedule" className="row g-2 gx-sm-3 mt-1">
                <div className="col-sm-6 col-lg-4">
                  <Form.Group>
                    <Form.Label>Interview Date</Form.Label>
                    <Form.Control type="date" name="interview_date" value={formData.interview_date} onChange={handleInputChange} required />
                  </Form.Group>
                </div>
                <div className="col-sm-6 col-lg-4">
                  <Form.Group>
                    <Form.Label>Interview Time</Form.Label>
                    <Form.Control type="time" name="interview_time" value={formData.interview_time} onChange={handleInputChange} required />
                  </Form.Group>
                </div>
                <div className="col-lg-4">
                  <Form.Group>
                    <Form.Label>Interview Medium</Form.Label>
                    <Form.Select name="interview_medium" onChange={handleInputChange} required>
                      <option value="">Select Medium</option>
                      <option value="Online Interview">Online Interview</option>
                      <option value="Offline Interview">Offline Interview</option>
                      <option value="1 Month">NMLive</option>
                    </Form.Select>
                  </Form.Group>
                </div>
              </div>


            </div>
            {isChecking ? <Spinner className="ms-2 border-3" as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : null}

            <div className="btn-wrapper text-center mt-4">

              <Button variant="secondary" className="px-3 me-2" onClick={handleClose}>Cancel</Button>
              <Button variant="primary" type="submit" className="px-3" disabled={otherVisibility ? true : false}>Submit
                {isProgress ? <Spinner className="ms-2 border-3" as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : null}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Add Requisition Status Model */}
      <Modal show={showStatusModal} onHide={closeStatusModal} backdrop="static" keyboard={false} size="xl" centered contentClassName="rounded-4">
        <Modal.Body className="p-3 py-4 p-lg-5">
          {/* {isLoading ? (
            <Loader />
          ) : ''} */}
          <div className="fs-3 fw-semibold text-center mb-2 mb-lg-4 mt-lg-n2">Add Candidate Status</div>
          <div className="row g-3 g-xl-4">
            <div className="col-6">
              <div className="info_wrapper d-xl-flex gap-3 gap-md-4">
                <div className="col-xl-4 title_name text-secondary text-xl-end">Candidate Name</div>
                <div className="value text-capitalize">{selectedCandidate?.user_name}</div>
              </div>
            </div>
            <div className="col-6">
              <div className="info_wrapper d-xl-flex gap-3 gap-md-4">
                <div className="col-xl-4 title_name text-secondary text-xl-end">Contact No</div>
                <div className="value text-capitalize">{selectedCandidate?.contact_no}</div>
              </div>
            </div>
            <div className="col-6">
              <div className="info_wrapper d-xl-flex gap-3 gap-md-4">
                <div className="col-xl-4 title_name text-secondary text-xl-end">Current company</div>
                <div className="value text-capitalize">{selectedCandidate?.current_company}</div>
              </div>
            </div>
            <div className="col-6">
              <div className="info_wrapper d-xl-flex gap-3 gap-md-4">
                <div className="col-xl-4 title_name text-secondary text-xl-end">email</div>
                <div className="value text-capitalize">{selectedCandidate?.email}</div>
              </div>
            </div>

            {/* Divider */}
            {/* <hr className="d-none d-xl-block divider border-secondary border-opacity-75 mt-4 mb-0" /> */}
            <div className="col-12">
              <Form onSubmit={handleSubmitStatus}>
                <Form.Select className="mb-3" aria-label="Default select example" name="status" required>
                  <option value="">Select Status</option>
                  {statuses.map((status, index) => (
                    <option key={index} value={status.value}>{status.label}</option>
                  ))}
                </Form.Select>
                <Form.Group className="mb-3">
                  <Form.Control type='text' value={nextStep} onChange={(e) => setNextStep(e.target.value)} placeholder="Next Step To be Taken" required />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control as="textarea" className="fs-6" value={note} onChange={(e) => setNote(e.target.value)} rows={4} placeholder="Note..." required />
                </Form.Group>
                <div className="btn_wrapper d-flex justify-content-center gap-3">
                  <Button type="button" variant="secondary" className="py-2 px-5" onClick={closeStatusModal}>Cancel</Button>
                  <Button type="submit" variant="success" className="py-2 px-5">Submit {isProgress ? <Spinner className="ms-2 border-3" as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : null}
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
