import { useContext, createContext, useEffect, useState } from 'react';

import {
  signOut,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithRedirect,

} from 'firebase/auth';
import {
  provider,
  getAuth,
  OAuthProvider,
  signInWithPopup,
  app,
  database,
  secauth
} from '../firebaseConfig';



import axios from 'axios';
import LoadingComponent from "../components/Loadingcomponent";
const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [userInfoLoaded, setUserInfoLoaded] = useState(true);
  const [currentUserVal, setCurrentUser] = useState(null);
  const [getAuthData, setAuthData]=useState({})
  const auth = getAuth();

  const fetchUserType = async (idToken, email) => {
    try {

      var data = JSON.stringify({
        "operation": "getUsers",
        "email": email
      });

      var headers = {
        'Authorization': `Bearer ${idToken}`,
        'Content-Type': 'application/json'
      };
      const response = await axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/dashboard/get-my-details`, data, { headers })
      // return response.data.data?.[0]?.userType || null; 'SA' response.data.data?.userType
      // return {userType: response.data.data?.[0]?.userType || null, userId: response.data.data?.[0]?.id || null , hrType: response.data.data?.[0]?.hrType || ""}
      // return {userType: "HR", userId: response.data.data?.id || null , hrType:"1"}
      return { userType: response.data.data?.userType || null, userId: response.data.data?.id || null, hrType: response.data.data?.hrType || "" }
    } catch (error) {
      console.error('Error fetching user type:', error);
      return null;
    }
  };



  const signInWithMicrosoft = async () => {
    try {
      const currentUser = auth.currentUser;
      if (currentUser) {
        // User is already signed in, no need to open the Microsoft login popup
        // console.log("User is already signed in");
        return;
      }
      const result = await signInWithPopup(auth, provider);
      setAuthData(result)
      const credential = await OAuthProvider.credentialFromResult(result);
      const accessToken = result?.user?.stsTokenManager?.accessToken;
      const getOauthAccessToken = result?._tokenResponse?.oauthAccessToken
      const idToken = credential.idToken;
      const userInfo = {
        uid: result?.user?.uid,
        email: result?.user?.email,
        displayName: result?.user?.displayName,
        accessToken: accessToken,
        idToken: idToken,
        userImage: result?.user?.photoURL,
        saveOauthAccessToken: getOauthAccessToken
      };

      // Fetch user role and update the context
      const userDetails = await fetchUserType(accessToken, result?.user?.email);

      // // console.log(userDetails)
      setUser({
        ...userInfo,
        // userType: userDetails.userType,
        // userId: userDetails.id
        ...userDetails
      });
    } catch (error) {
      console.error('Error signing in with Microsoft:', error);
    }
  };

useEffect(()=>{
  // console.log(getAuthData)
  // console.log("getAuthData")
},[getAuthData])


  const logOut = async () => {
    try {
      // Sign out from Firebase authentication (works for both Google and Microsoft)
      await signOut(auth);
      await signOut(secauth);
      // Update the user state
      setUser(null);
    } catch (error) {
      console.error('Error during sign-out:', error);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(secauth, async (currentUser) => {
      // console.log(currentUser)
      if (currentUser) {
        setCurrentUser(currentUser);
        const idToken = await currentUser.getIdToken();
        const userDetails = await fetchUserType(currentUser?.accessToken, currentUser?.email);

        // console.log(userDetails)
        const userInfo = {
          uid: currentUser?.uid,
          email: currentUser?.email,
          displayName: currentUser?.displayName,
          accessToken: currentUser?.accessToken,
          idToken: currentUser?.accessToken,
          userImage: currentUser?.photoURL,
          // userType: userType,
          // userType: userDetails.userType,
          // userId: userDetails.id
          ...userDetails
        };
        setUser(userInfo);
      }
      else {
        // console.log("not logged in");
      }
    });

    return () => {
      unsubscribe();
    };
  }, [currentUserVal]);



  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (authUser) => {
      if (authUser) {
        // console.log("auth user called");
        // console.log(authUser);
        setCurrentUser(authUser);
        const idToken = await authUser.getIdToken();
        const userDetails = await fetchUserType(authUser?.accessToken, authUser.email);
        const getOauthAccessToken = getAuthData?._tokenResponse?.oauthAccessToken
        setUser({
          uid: authUser.uid,
          email: authUser.email,
          displayName: authUser.displayName,
          accessToken: authUser?.accessToken, // You may want to fetch a new access token here if needed
          idToken: idToken,
          userImage: authUser?.photoURL,
          saveOauthAccessToken: getOauthAccessToken,
          // userType: userDetails.userType,
          // userId: userDetails.id
          ...userDetails
        });
      } else {
        setUser(null);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [currentUserVal]);

  // const googleSignIn = () => {
  //   const provider = new GoogleAuthProvider();
  //    //signInWithPopup(secauth, provider);
  //   signInWithRedirect(secauth, provider);
  // };

  const googleSignIn = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(secauth, provider);
    //signInWithRedirect(secauth, provider);
  };

  return (
    <AuthContext.Provider value={{ googleSignIn, logOut, signInWithMicrosoft, user, userInfoLoaded, currentUserVal }}>
      {children}
    </AuthContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(AuthContext);
};