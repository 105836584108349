import React, { useEffect, useState } from "react";
import './issues.scss';
import { Button, NavLink, Form, Card, Accordion } from 'react-bootstrap'
import { useNavigate } from "react-router-dom";
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Modal from "react-bootstrap/Modal";
import { UserAuth } from "../../context/AuthContext";
import { getAuth } from "firebase/auth";
import { useNavigationContext } from "../../context/NavContext";
import Pagination from "../../components/Pagination";

// react-quill editor
import ReactQuill, { quill } from 'react-quill';
import "react-quill/dist/quill.snow.css";

// import tostify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { callAxios } from "../../utils/util";
import axios from "axios";
// loader
import { Loader } from '../../components/loader';
import moment from 'moment';

import SearchIcon from '../../assets/search.svg';
import { AddNewIssueModal } from "./AddNewIssueModal";
import { OpenIssues } from "./OpenIssues";

import { IssuesInProgress } from "./IssuesInProgress";
import { IssuesClosed } from "./IssuesClosed";
import { IssuesReopen } from "./IssuesReopen";
import { ReopenIssueModal } from "./ReopenIssueModal";
import { useMyContext } from "../../context/MyContext";

// Custom Accordion
function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
        console.log('totally custom!'),
    );

    return (
        <div className="d-flex flex-wrap flex-sm-nowrap gap-2" onClick={decoratedOnClick}>
            {children}
        </div>
    );
}

export const Issues = () => {
    const { values, handleChange } = useMyContext()
    const navigate = useNavigate();

    // Initialize state to track the active menu
    const [activeMenu, setActiveMenu] = useState(0);

    // For Sidebar list selected card
    const [active, setActive] = useState(0);
    const [activeStatus, setActiveStatus] = useState('open');

    // Add new meeting modal
    const [showAddIssueModal, setShowAddIssueModal] = useState(false);
    const closeAddIssueModal = () => setShowAddIssueModal(false);
    const openAddIssueModal = () => setShowAddIssueModal(true);

    // Handler function to update the active Menu
    const handleClick = (index) => {
        setActiveMenu(index);
        setActiveStatus(index)
        setCurrentPage(1)
    };

    const newMailPage = () => {
        navigate('/new-mail');
    }
    const { user, userInfoLoaded, currentUserVal } = UserAuth();
    const { activepage, handlePageClick } = useNavigationContext()
    // console.log(user)
    // for issue listing
    const [subject, setSubject] = useState("");
    const [type, setType] = useState("");
    const [priority, setPriority] = useState("");
    const [description, setDescription] = useState("");
    const [file, setFile] = useState(null);
    const [issues, setIssues] = useState([]);
    const [issueCount, setIssueCount] = useState(null);
    // end issue listing
    const [show, setShow] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const [isSaving, setIsSaving] = useState(false);

    const [noResults, setNoResults] = useState(true)
    const [error, setError] = useState(null);
    // pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const itemsPerPage = 10;
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    // pagination

    // For Add Candidate Model
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [startDate, setStartDate] = useState(moment().subtract(1, 'months').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
    const [status, setStatus] = useState('');
    const [statusD, setStatusD] = useState('')

    const getIssues = async () => {
        setIsLoading(true)
        const refreshedToken = await currentUserVal?.getIdToken(true);
        const sendData = {
            "fromDate": startDate, // optional
            "toDate": endDate, // optional
            "status": activeStatus, // closed, In Progress, open optional
            "page": currentPage,
            "pageSize": itemsPerPage
        }

        if (user?.userType == "HR") {
            sendData.eMailId = user?.email;
        }
        const headers = {
            'Authorization': `Bearer ${refreshedToken}`,
            'Content-Type': 'application/json'
        };
        axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/issues/list`, sendData, { headers }).then((issues) => {
            // console.log(issues);
            const getallIssues = issues.data.list
            // console.log(getallIssues)
            // console.log("isssues2")
            setIssues(getallIssues);
            setTotalPages(issues?.data.totalPage)
            setNoResults(false)
            setIsLoading(false)
        })
    };
    const getCount = async () => {
        const refreshedToken = await currentUserVal?.getIdToken(true);
        const sendData = {
            "fromDate": startDate, // optional
            "toDate": endDate, // optional
            "status": activeStatus, // closed, In Progress, open optional
        }
        if (user?.userType == "HR") {
            sendData.eMailId = user?.email;
        }
        const headers = {
            'Authorization': `Bearer ${refreshedToken}`,
            'Content-Type': 'application/json'
        };
        axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/issues/getCount`, sendData, { headers }).then((issues) => {
            const issuesCount = issues.data
            // console.log(issuesCount)
            setIssueCount(issuesCount);
        })
    };


    useEffect(() => {
        getCount();
    }, [currentUserVal])

    useEffect(() => {
        getIssues();
        handlePageClick('activepage', 'issues-list')
    }, [currentUserVal, currentPage, activeStatus]);
    const dateFilter = (event) => {
        setIsLoading(true)
        setCurrentPage(1);
        setError(null);
        getCount();
        getIssues();
    };
    // Define the quill toolbar modules
    const toolbarModules = {
        toolbar: {
            container: '#toolbar', // Reference the ID of the custom toolbar
            handlers: {
                // Add custom handlers if necessary
            }
        },
        toolbar: [
            [{ 'font': [] }],
            [{ 'header': '1' }, { 'header': '2' }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            [{ 'list': 'bullet' }, { 'list': 'ordered' }],
            // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            // [{ 'color': [] }, { 'background': [] }],
            ['link', 'image', 'video', 'formula'],
            ['blockquote', 'code-block'], // Quote
            ['clean'] // Remove formatting button
        ],
    };
    // Define the quill formats that are allowed
    const formats = [
        'font', 'header', 'list', 'bullet', 'bold', 'italic', 'underline', 'align', 'link', 'image', 'blockquote'
    ];
    const noRecordFound = () => {
        return (
            <div className="no-data-wrapper bg-white rounded px-3 py-5 mt-2">
                <div className="text-center mx-auto" style={{ maxWidth: '350px' }}>
                    <img src={require('../../assets/no-data.jpg')} alt="Candidate" className="img-fluid" />
                    <div className='fs-4 fw-semibold text-danger mt-3'>Sorry no record Found !</div>
                    {/* <div className='small text-muted'>No candidates registered on the selected date</div> */}
                </div>
            </div>
        )
    }


    // getCurrentPathname
    useEffect(() => {
        const currentUrl = window.location.href;
        const currentPath = window.location.pathname
        handleChange("getPagePath", currentPath)
    }, []);
    // getCurrentPathname

    return (
        <div className="IssuesList-page py-3">
            <div className="container-fluid px-xxl-5">
                <div className="border-opacity-75 mb-3 pb-3" style={{ borderBottom: '4px solid #0054ca' }}>
                    <div className="d-flex flex-wrap flex-column flex-md-row align-items-md-center justify-content-between gap-2 gap-xl-5">
                        <div className="fs-4 fw-bold lh-sm">Issues</div>
                        <div className="date-wrapper d-flex flex-column flex-md-row align-items-md-center gap-2 gap-xl-3 ms-xxl-3">
                            <Form.Group className="d-flex flex-column flex-md-row align-items-md-center flex-fill gap-1 gap-md-2 gap-xxl-3" controlId="dateFrom">
                                <Form.Label className="fs-6 text-body m-0">From</Form.Label>
                                <Form.Control type="date" className="bg-white bg-opacity-50 border-0 rounded-3 shadow-none px-3 py-2"
                                    max={new Date().toJSON().slice(0, 10)}
                                    defaultValue={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className="d-flex flex-column flex-md-row align-items-md-center flex-fill gap-1 gap-md-2 gap-xxl-3" controlId="dateTo">
                                <Form.Label className="fs-6 text-body m-0">To</Form.Label>
                                <Form.Control type="date" className="bg-white bg-opacity-50 border-0 rounded-3 shadow-none px-3 py-2" s
                                    max={new Date().toJSON().slice(0, 10)}
                                    defaultValue={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                />
                            </Form.Group>
                            {/* <Form.Group className="status-type d-flex flex-column flex-md-row align-items-md-center flex-fill gap-1 gap-md-2 gap-xxl-3" controlId="statusType">
                                <Form.Label className="fs-6 text-body m-0">Status</Form.Label>
                                <Form.Select className="bg-white bg-opacity-50 border-0 rounded-3 shadow-none px-3 py-2" style={{ minWidth: '120px' }} required>
                                    <option value="" disabled >Status</option>
                                    <option value="All" selected>All</option>
                                    <option value="Open">Open</option>
                                    <option value="Closed">Closed</option>
                                </Form.Select>
                            </Form.Group> */}
                            <div class="btn-wrapper d-flex flex-column gap-1 gap-md-2 text-center">
                                <label class="d-none d-sm-block d-md-none">&nbsp;</label>
                                <div class="d-flex align-items-center justify-content-center bg-white bg-opacity-50 border-0 rounded-3 shadow-none px-3 py-2 cursor-pointer" style={{ minHeight: '45px' }} onClick={dateFilter}>
                                    <i class="bi bi-search"></i>
                                    <span class="fw-medium d-sm-none">Search</span>
                                </div>
                            </div>
                            {user?.userType !== 'SSA' ?
                                <Button variant='light' className="order-xl-3 text-nowrap rounded mt-1 mt-md-0 px-3 py-2"
                                    // onClick={handleShow}
                                    onClick={openAddIssueModal}
                                >
                                    <i className="bi bi-plus-circle text-success me-1"></i> Raise a New Issue
                                </Button>
                                : ''
                            }
                        </div>

                    </div>

                    <div className="info-cards-wrapper row g-2 mt-2">
                        <div className="col-sm-6 col-md-4 col-xl-2">
                            <div className="info-card rounded p-3" style={{ backgroundColor: '#0d6efd', borderBottom: '5px solid #0d6efd' }}>
                                <div className="info-wrapper position-relative">
                                    <span className="fs-2 fw-semibold text-white lh-1">{issueCount?.totalCount}</span><br />
                                    <span className="text-light text-secondary">Total Issues</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-xl-2">
                            <div className="info-card bg-success border-5 border-bottom border-success rounded p-3 pointer" onClick={() => handleClick('open')}>
                                <div className="info-wrapper position-relative">
                                    <span className="fs-2 fw-semibold text-white lh-1">{issueCount?.openCount}</span><br />
                                    <span className="text-light text-secondary">Open Issues</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-xl-2">
                            <div className="info-card bg-info border-5 border-bottom border-info rounded p-3 pointer" onClick={() => handleClick('In Progress')}>
                                <div className="info-wrapper position-relative">
                                    <span className="fs-2 fw-semibold text-white lh-1">{issueCount?.inProgressCount}</span><br />
                                    <span className="text-light text-secondary">In Progress</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-xl-2">
                            <div className="info-card bg-warning border-5 border-bottom border-warning rounded p-3 pointer" onClick={() => handleClick('In Discussion')}>
                                <div className="info-wrapper position-relative">
                                    <span className="fs-2 fw-semibold text-white lh-1">{issueCount?.inDiscussionCount}</span><br />
                                    <span className="text-light text-secondary">In Discussion</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-xl-2">
                            <div className="info-card rounded p-3 pointer" style={{ backgroundColor: '#e3630e', borderBottom: '5px solid #e3630e' }} onClick={() => handleClick('reopen')}>
                                <div className="info-wrapper position-relative">
                                    <span className="fs-2 fw-semibold text-white lh-1">{issueCount?.reopenCount}</span><br />
                                    <span className="text-light text-secondary">Reopen</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-xl-2">
                            <div className="info-card bg-danger border-5 border-bottom border-danger rounded p-3 pointer" onClick={() => handleClick('closed')}>
                                <div className="info-wrapper position-relative">
                                    <span className="fs-2 fw-semibold text-white lh-1">{issueCount?.closedCount}</span><br />
                                    <span className="text-light text-secondary">Closed Issue</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="issues-menu-section list-inline d-flex flex-nowrap gap-1 mb-3">
                    <li className="nav-item">
                        <NavLink to="#" className={`nav-link rounded-3 px-3 py-2 ${activeStatus === 'open' ? 'active' : ''}`} onClick={() => handleClick('open')}><i className="bi bi-inboxes"></i> Open</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="#" className={`nav-link rounded-3 px-3 py-2 ${activeStatus === 'In Progress' ? 'active' : ''}`} onClick={() => handleClick('In Progress')}><i className="bi bi-hourglass-split"></i> In Progress</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="#" className={`nav-link rounded-3 px-3 py-2 ${activeStatus === 'In Discussion' ? 'active' : ''}`} onClick={() => handleClick('In Discussion')}><i className="bi bi-stickies"></i> In Discussion</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="#" className={`nav-link rounded-3 px-3 py-2 ${activeStatus === 'reopen' ? 'active' : ''}`} onClick={() => handleClick('reopen')}><i className="bi bi-inboxes"></i> Reopen</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="#" className={`nav-link rounded-3 px-3 py-2 ${activeStatus === 'closed' ? 'active' : ''}`} onClick={() => handleClick('closed')}><i className="bi bi-archive"></i> Closed</NavLink>
                    </li>
                </div>
                {isLoading ? (
                    <Loader />
                ) : ""}
                {
                    issues.length > 0 ?
                        <div className="custom-wrapper d-lg-flex gap-2">
                            <div className="issues-details-section client-detail-wrapperr w-100">
                                {/* Issue Open Card */}
                                {issues.length > 0 && activeStatus === 'open' ?
                                    <OpenIssues allData={issues} getIssues={getIssues} getCount={getCount} />
                                    : ''}

                                {/* Issue In-Progress Card */}
                                {issues.length > 0 && activeStatus === 'In Progress' ?
                                    <IssuesInProgress allData={issues} getIssues={getIssues} getCount={getCount} />
                                    : ''}
                                {issues.length > 0 && activeStatus === 'In Discussion' ?
                                    <IssuesInProgress allData={issues} getIssues={getIssues} getCount={getCount} />
                                    : ''}

                                {issues.length > 0 && activeStatus === 'reopen' ?
                                    <IssuesReopen allData={issues} getIssues={getIssues} getCount={getCount} />
                                    : ''}
                                {issues.length > 0 && activeStatus === 'closed' ?
                                    <IssuesClosed allData={issues} getIssues={getIssues} getCount={getCount} />
                                    : ''}
                                {/* Issue Closed Card */}
                                {/* <IssuesClosed /> */}

                                {/* Issue Closed Card */}
                                {/* <IssuesReopen /> */}
                            </div>
                        </div>
                        : <div className="custom-wrapper">
                            <div className="issues-details-section client-detail-wrapperr">
                                {noRecordFound()}
                            </div>
                        </div>
                }




                {issues?.length > 0 ?
                    <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
                    : ''
                }
            </div>

            <AddNewIssueModal show={showAddIssueModal} close={closeAddIssueModal} setIssues={setIssues} setShowAddIssueModal={setShowAddIssueModal} startDate={startDate} endDate={endDate} setStatusD={setStatusD} setIsloading={setIsLoading} getIssues={getIssues} getCount={getCount} />
            {/* <AddNewIssueModal show={showAddIssueModal} close={closeAddIssueModal} /> */}

            <ToastContainer />
        </div>
    )
}
