import React, { useEffect, useState } from "react";
import { app, database, auth, secondDatabase } from "../../firebaseConfig";
import {
  collection,
  addDoc,
  doc,
  setDoc,
  getDocs,
  query,
  where,
  onSnapshot,
  orderBy,
  limit,
  documentId
} from "firebase/firestore";

import { useNavigate, Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { UserAuth } from '../../context/AuthContext';
import axios from "axios";
// import { environment } from "../../../src/env";



// loader
import { Loader } from '../../components/loader';
import { useMyContext } from "../../context/MyContext";

const OngoingCallsPage = (props) => {
  // console.log( process.env.REACT_APP_USERNAME,
  // process.env.REACT_APP_PASSWORD,)
  const { values, handleChange } = useMyContext()
  let navigate = useNavigate();
  const { user, userInfoLoaded, currentUserVal } = UserAuth();

  // console.log(JSON.stringify(user));

  const [ongoingInterviewFromFirebase, setOngoingInterview] = useState([]);

  const [progressRoom, setProgressRoom] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const [ongoingInterviewTwilioRoom, setCheckRoomLength] = useState([]);

  const [ongoingInterviews, setOngoingInterviews] = useState([]);



  var startOfToday = new Date();
  startOfToday.setHours(0, 0, 0, 0);
  var endOfToday = new Date();
  endOfToday.setHours(23, 59, 59, 999);
  const queryConstraints = [];
  queryConstraints.push(where('timeStamp', '>=', startOfToday));
  queryConstraints.push(where('timeStamp', '<=', endOfToday));

  queryConstraints.push(orderBy('timeStamp', 'desc'));
  const q = query(collection(secondDatabase, "userDevices"), ...queryConstraints);

  useEffect(() => {

    if (!user) navigate("/dashboard");
    getRoomwithStatus();
  }, [user]);


  // console.log(ongoingInterviewFromFirebase)

  useEffect(() => {

    onSnapshot(q, (querySnapshot) => {
      let interview = [];
      // getRoomwithStatus();
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        data["id"] = doc.id;
        interview.push(doc.id);
      });
      setOngoingInterview(interview);
    });
  }, []);

  // console.log("userDevices ongoing interviews:::::",ongoingInterviewFromFirebase,ongoingInterviewTwilioRoom)

  const displayOngoingCalls = [];
  ongoingInterviewFromFirebase.map((item, index) => {
    if (ongoingInterviewTwilioRoom.includes(item)) {
      displayOngoingCalls.push(item)
    }
  })

  //  console.log(displayOngoingCalls)

  const getRoomwithName = async (identity) => {
    setIsLoading(true);
    const refreshedToken = await currentUserVal?.getIdToken(true);
    const postData = { "identity": identity }
    var headers = {
      'Authorization': `Bearer ${refreshedToken}`,
      'Content-Type': 'application/json'
    };
    axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/twilio/get-room-status`, postData, { headers })
      .then((response) => {
        const status = response?.data?.status;
        if (status == "in-progress" || status == "in-progress,in-progress") {

          navigate("/Adminroom?name=Admin&id=" + btoa(identity), "_blank");
          //   getRoomwithStatus();
        } else {
          alert(status);
          alert("Sorry this Meeting is over !");
          //   getRoomwithStatus();
        }
      })
      .catch((error) => {
        return false;
      });
  };

  const joinRoomAdmin = (roomID) => {
    getRoomwithName(roomID);
  };


  const getRoomwithStatus = async () => {
    setIsLoading(true);
    const refreshedToken = await currentUserVal?.getIdToken(true);
    const postData = {}
    var headers = {
      'Authorization': `Bearer ${refreshedToken}`,
      'Content-Type': 'application/json'
    };
    axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/twilio/room-list`, postData, { headers })
      .then((response) => {
        setCheckRoomLength(response.data?.rooms?.map((item) => item?.sid));
        setProgressRoom(response.data?.rooms?.map((item) => item?.unique_name));
        fetchStreamData(response.data?.rooms?.map((item) => item?.unique_name));
        setIsLoading(false)
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const fetchStreamData = async (RoomsIDs) => {
    //const q = query(collection(database, "'forms"), where("roomId", "==","99201c2b-1686-4efb-8938-b2202d41e9b5"));
    if (RoomsIDs?.length > 0) {
      const q = query(
        collection(database, "userDevices"),
        where(documentId(), "in", RoomsIDs)
      );
      onSnapshot(q, (querySnapshot) => {
        const forms = [];
        querySnapshot.forEach((doc) => {
          //audio.play()
          const id = doc.id;
          const data = doc.data();
          data["id"] = doc.id;

          forms.push({ ...doc.data(), id: doc.id });
        });
        setOngoingInterview(forms);
        setIsLoading(false)
      });
    }
  };

  useEffect(() => {

    if (displayOngoingCalls.length > 0) {
      axios.post("https://dev.virtualemployee.com/open-api/v1/ongoing/get-ongoing-list", { sids: displayOngoingCalls }).then((ongoingInterviews) => {
        setOngoingInterviews(ongoingInterviews.data)
        setIsLoading(false)
      })
      // axios.post(`${process.env.REACT_APP_OPENAPI}/v1/ongoing/get-ongoing-list`,{sids:displayOngoingCalls}).then((ongoingInterviews)=>{
      //   setOngoingInterviews(ongoingInterviews.data)
      //   setIsLoading(false)
      // })
    }
  }, [ongoingInterviewTwilioRoom])
  // console.log(ongoingInterviews)
  let vlisting;
  if (ongoingInterviews.length > 0) {
    vlisting = ongoingInterviews.map((item, index) => {
      return (
        <tr key={index}>
          <td><div className="fw-medium">{index + 1}</div></td>
          <td><div className="text-capitalize">{item.display_name}</div></td>
          <td><div >{item.user_email}</div></td>

          <td>
            <div className="d-flex gap-2">
              <button className="btn btn-warning btn-sm px-3" onClick={() => { joinRoomAdmin(item.room_sid) }}><i className="bi bi-person-lock"></i> View Candidate</button>
            </div>
          </td>
        </tr>
      )
    });
  } else {
    vlisting = <tr style={{ textAlign: "center" }}><td colSpan={"4"}>No candidate found at this moment !!!</td></tr>
  }

  // getCurrentPathname
  useEffect(() => {
    const currentUrl = window.location.href;
    const currentPath = window.location.pathname
    handleChange("getPagePath", currentPath)
  }, []);
  // getCurrentPathname

  return (
    <div className="manager-wrapper py-3">
      <div className="container-fluid">
        <div className="d-flex flex-wrap align-items-center justify-content-between gap-2 mb-3">
          <div className="fs-5 fw-semibold">Ongoing Interviews</div>

        </div>

        {/* Table listing code */}
        <div className='listing-sec bg-white rounded p-3 p-xxl-4'>
          <div className='candidates-wrapper'>
            <div className='candidate-list table-responsive border rounded-3'>
              <table className="table align-middle text-nowrap mb-0">
                <thead className="bg-primary text-light sticky-top">
                  <tr>
                    <th scope="col">Sr. No</th>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {vlisting}
                </tbody>
              </table>
            </div>

            {isLoading ? (
              <Loader />
            ) : null}
          </div>
        </div>





      </div>
    </div>
  );




}

export default OngoingCallsPage;