import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import { BrowserRouter,useLocation,useNavigationType,createRoutesFromChildren,matchRoutes } from 'react-router-dom';
import * as Sentry from "@sentry/react";

// Bootstarap 5.2.3 Imports
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

Sentry.init({
  dsn: "https://4f3ca2f5013e160629c6882a0eb3d187@o4505821957259264.ingest.us.sentry.io/4507820925845504",
  integrations: [
    new Sentry.BrowserTracing({
       routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new Sentry.Replay()
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(// console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

