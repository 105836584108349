import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Loader } from '../components/loader';
import axios from 'axios';
import { getAuth, database, secondDatabase, secauth } from '../firebaseConfig';
import { UserAuth } from '../context/AuthContext';
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
import HRCandidateChart from './chart/HRCandidateChart';
import InterviewScheduledChart from './chart/InterviewScheduledChart';
import RegisteredCandidateChart from './chart/RegisteredCandidateChart';
import InterviewGivenChart from './chart/InterviewGivenChart';
import HRInterviewMedium from './chart/HRInterviewMedium';
import InterviewWithoutHR from './chart/InterviewWithoutHR';
import HRInterview from './chart/HRInterview';
import InterviewMediumReport from './chart/InterviewMediumReport';
import InterviewGivenonScheduled from './chart/InterviewGivenonScheduled';
import Interviewnotscheduled from './chart/Interviewnotscheduled';
import NMInterviewnotScheduled from './chart/NMInterviewnotScheduled';
import { useNavigationContext } from "../context/NavContext";
import { useMyContext } from '../context/MyContext';

am4core.useTheme(am4themes_animated)

export const Reports = () => {
    const { values, handleChange } = useMyContext()
    const navigate = useNavigate();
    const auth = getAuth();
    const { user, userInfoLoaded, currentUserVal } = UserAuth();
    const { activepage, handlePageClick } = useNavigationContext()
    const [loading, setIsLoading] = useState(false);
    const [reportsdata, setReportsdata] = useState([])
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [error, setError] = useState(null);
    const [reportData, setReportData] = useState('')
    const [createdData, setCreatedData] = useState([])
    const [scheduleData, setScheduleData] = useState([])
    const [givenData, setGivenData] = useState([])
    const [registeredData, setRegisteredData] = useState([])
    const [hrInterviewMedium, sethrInterviewMedium] = useState([])
    const [withouthrInterviewMedium, setWithouthrInterviewMedium] = useState([])
    const [totalAdded, settotalAdded] = useState([])
    const [totalRegis, setTotalRegis] = useState([])
    const [totalSchedule, setTotalSchedule] = useState([])
    const [totalGiven, setTotalGiven] = useState([])
    const [allHRinterview, setAllHRinterview] = useState([])
    const [totalHRinterview, setTotalHRInterview] = useState('')
    const [totalwithoutHRinterview, setTotalwithoutHRInterview] = useState('')
    const [totalboth, setTotalBoth] = useState('')
    const [interviewMedium, setinterviewMedium] = useState([])
    const [totalinterviewmed, settotalinterviewmed] = useState('')
    const [allinterviewdatas, setAllinterviewDatas] = useState([])
    const [notinterviewscheduled, setNotinterviewscheduled] = useState([])
    const [countnotscheduled, setCountnotscheduled] = useState('')
    const [completedCount, setCompletedcount] = useState('')
    const [getnminterviewnotscheduled, SetNMinterviewnotscheduled] = useState([])
    const [countNMnotscheduled, setCountNMnotscheduled] = useState('')
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const year = today.getFullYear();
    const formattedDate = year + '-' + month + '-' + day;

    const HRreports = async (dateData) => {
        setIsLoading(true);
        const filterObj = dateData != undefined ? dateData : ''
        const refreshedToken = await currentUserVal?.getIdToken(true);
        // var data = JSON.stringify(filterObj);
        var headers = {
            'Authorization': `Bearer ${refreshedToken}`,
            'Content-Type': 'application/json'
        };
        axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/reports/get-hr-canditates`, filterObj, { headers })
            .then(function (response) {
                const responseData = response.data.result
                setReportsdata(response.data.result)
                setIsLoading(false);
                const filteredcreatedData = responseData.filter(item => item.interview_created_count != 0);
                setCreatedData(filteredcreatedData)
                let sum = 0;
                responseData.forEach(item => { sum += item.interview_created_count; });
                settotalAdded(sum)
                const filteredscheduledData = responseData.filter(item => item.interview_scheduled_count != 0);
                setScheduleData(filteredscheduledData)
                let scheduleSum = 0;
                responseData.forEach(item => { scheduleSum += item.interview_scheduled_count; });
                setTotalSchedule(scheduleSum)
                const filteredregisteredData = responseData.filter(item => item.candidate_count != 0);
                setRegisteredData(filteredregisteredData)
                let regisSum = 0;
                responseData.forEach(item => { regisSum += item.candidate_count; });
                setTotalRegis(regisSum)
                const filteredgivenData = responseData.filter(item => item.interview_given_count != 0);
                setGivenData(filteredgivenData)
                let givenSum = 0;
                responseData.forEach(item => { givenSum += item.interview_given_count; });
                setTotalGiven(givenSum)
            })
            .catch(function (error) {
                setIsLoading(false);
            });
    }

    const HRInterviewMediumData = async (dateData) => {
        setIsLoading(true);
        const filterObj = dateData != undefined ? dateData : ''
        const refreshedToken = await currentUserVal?.getIdToken(true);
        var headers = {
            'Authorization': `Bearer ${refreshedToken}`,
            'Content-Type': 'application/json'
        };
        axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/reports/get-hr-interview-medium`, filterObj, { headers })
            .then(function (response) {
                const responseData = response?.data.result
                sethrInterviewMedium(responseData)
                const sumResultsdata = response?.data.sumResults
                setTotalHRInterview(sumResultsdata)
                setIsLoading(false);
                // const filteredcreatedData = responseData.filter(item => item.interview_created_count != 0);
                // setCreatedData(filteredcreatedData)
            })
            .catch(function (error) {
                setIsLoading(false);
            });
    }

    const WithoutHRmedium = async (dateData) => {
        setIsLoading(true);
        const filterObj = dateData != undefined ? dateData : ''
        const refreshedToken = await currentUserVal?.getIdToken(true);
        var headers = {
            'Authorization': `Bearer ${refreshedToken}`,
            'Content-Type': 'application/json'
        };
        axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/reports/get-hr-interview-medium`, filterObj, { headers })
            .then(function (response) {
                const responseData = response.data?.result
                setWithouthrInterviewMedium(responseData)
                setIsLoading(false);
                const sumResultsdata = response?.data.sumResults
                setTotalwithoutHRInterview(sumResultsdata)
            })
            .catch(function (error) {
                setIsLoading(false);
            });
    }

    const fetchallHRinterview = async (dateData) => {
        setIsLoading(true);
        const filterObj = dateData != undefined ? dateData : ''
        const refreshedToken = await currentUserVal?.getIdToken(true);
        var headers = {
            'Authorization': `Bearer ${refreshedToken}`,
            'Content-Type': 'application/json'
        };
        axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/reports/get-hr-schedule-interview`, filterObj, { headers })
            .then(function (response) {
                const responseData = response.data?.result
                setAllHRinterview(responseData)
                setIsLoading(false);
                const sumResultsdata = response?.data.sumResults.sum_not_schedule
                setTotalBoth(sumResultsdata)
            })
            .catch(function (error) {
                setIsLoading(false);
            });
    }

    const fetchInterviewMedium = async (dateData) => {
        setIsLoading(true);
        const filterObj = dateData != undefined ? dateData : ''
        const refreshedToken = await currentUserVal?.getIdToken(true);
        var headers = {
            'Authorization': `Bearer ${refreshedToken}`,
            'Content-Type': 'application/json'
        };
        axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/reports/get-interview-medium`, filterObj, { headers })
            // axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/reports/get-hr-canditates`, filterObj, { headers })
            .then(function (response) {
                const responseData = response.data?.result
                setinterviewMedium(responseData)
                // console.log('interviewMedium')
                // console.log(interviewMedium)
                setIsLoading(false);
                const sumResultsdata = response?.data.sumResults
                settotalinterviewmed(sumResultsdata)
            })
            .catch(function (error) {
                setIsLoading(false);
            });
    }

    const fetchallInteviewsdata = async (dateData) => {
        setIsLoading(true);
        const filterObj = dateData != undefined ? dateData : ''
        const refreshedToken = await currentUserVal?.getIdToken(true);
        var headers = {
            'Authorization': `Bearer ${refreshedToken}`,
            'Content-Type': 'application/json'
        };
        axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/reports/get-hr-schedule-interview`, filterObj, { headers })
            .then(function (response) {
                const responseData = response?.data.result
                setAllinterviewDatas(responseData)
                setIsLoading(false);
                const sumResultsdata = response?.data.sumResults.sum_on_schedule
                setCompletedcount(sumResultsdata)
            })
            .catch(function (error) {
                setIsLoading(false);
            });
    }

    const fetchnotscheduledbyHR = async (dateData) => {
        setIsLoading(true);
        const filterObj = dateData != undefined ? dateData : ''
        const refreshedToken = await currentUserVal?.getIdToken(true);
        var headers = {
            'Authorization': `Bearer ${refreshedToken}`,
            'Content-Type': 'application/json'
        };
        axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/reports/get-interview-not-schedule-byHr`, filterObj, { headers })
            .then(function (response) {
                const responseData = response?.data.result
                setNotinterviewscheduled(responseData)
                // console.log(responseData)
                setIsLoading(false);
                const sumResultsdata = response?.data.sumResults.sum_not_schedule
                setCountnotscheduled(sumResultsdata)
            })
            .catch(function (error) {
                setIsLoading(false);
            });
    }

    const fetchNMinterviewnotscheduled = async (dateData) => {
        setIsLoading(true);
        const filterObj = dateData != undefined ? dateData : ''
        const refreshedToken = await currentUserVal?.getIdToken(true);
        var headers = {
            'Authorization': `Bearer ${refreshedToken}`,
            'Content-Type': 'application/json'
        };
        axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/reports/get-nminterview-not-schedule`, filterObj, { headers })
            .then(function (response) {
                const responseData = response?.data.result
                SetNMinterviewnotscheduled(responseData)
                setIsLoading(false);
                const sumResultsdata = response?.data.sumResults.sum_not_schedule
                setCountNMnotscheduled(sumResultsdata)
            })
            .catch(function (error) {
                setIsLoading(false);
            });
    }

    useEffect(() => {
        var dateData = {
            "startDate": formattedDate,
            "endDate": formattedDate,
            // "startDate": "2024-03-01", "endDate": "2024-03-17"
        };
        HRInterviewMediumData(dateData)
        WithoutHRmedium(dateData)
        fetchallHRinterview(dateData)
        HRreports(dateData)
        fetchInterviewMedium(dateData)
        fetchallInteviewsdata(dateData)
        fetchnotscheduledbyHR(dateData)
        fetchNMinterviewnotscheduled(dateData)
        setStartDate(formattedDate)
        setEndDate(formattedDate)
        // handlePageClick('activepage','reports')
    }, [auth, currentUserVal]);

    // date
    const handleStartDateChange = (e) => {
        setStartDate(e.target.value)
    }
    const handleEndDateChange = (e) => {
        setEndDate(e.target.value)
    }
    const handleApplyClick = () => {
        if ((startDate == "" || endDate == "")) {
            setError("Please select from and to Date.");
            return;
        } else {
            var dateData = {
                "startDate": startDate,
                "endDate": endDate
            };
            HRreports(dateData)
            HRInterviewMediumData(dateData)
            WithoutHRmedium(dateData)
            fetchallHRinterview(dateData)
            fetchInterviewMedium(dateData)
            fetchallInteviewsdata(dateData)
            fetchnotscheduledbyHR(dateData)
            fetchNMinterviewnotscheduled(dateData)
        }
    }
    // date

    // report type
    const reportType = (e) => {
        setReportData(e.target.value)
    }
    // report type
    const htmlContent = <div className="no-data-wrapper">
        <div className="text-center">
            <img src={require('../assets/charterror.png')} alt="No chart data" className="img-fluid" />
        </div>
    </div>;

    // getCurrentPathname
    useEffect(() => {
        const currentUrl = window.location.href;
        const currentPath = window.location.pathname
        handleChange("getPagePath", currentPath)
    }, []);
    // getCurrentPathname

    return (
        <div className='reportPage py-3'>
            <div className='container-fluid position-relative'>
                {loading ? <Loader /> : ''}

                <div className='row align-items-center g-2 position-relative'>
                    <div className='col-md-4'>
                        <div className="fs-4 fw-semibold">Report Listing</div>
                    </div>
                    <div className='col-md-8'>
                        <div className="date-filter d-flex align-items-center gap-1 bg-white rounded-2 px-3 ms-md-auto" style={{ maxWidth: '400px' }}>
                            <input type="date" className="form-control border-0 ps-0" id="startDate" value={startDate} onChange={handleStartDateChange} required />
                            <input type="date" className="form-control border-0 ps-0" id="endDate" value={endDate} onChange={handleEndDateChange} required />
                            <button type='button' className='btn btn-sm btn-primary px-3 lh-1' onClick={() => handleApplyClick()}>Apply</button>
                        </div>
                    </div>
                    {error && <p className="text-danger errMsg">{error}</p>}
                </div>

                <div className='reports-wrapper'>
                    <div className="card px-3 p-xl-4 py-4 mt-3">
                        {createdData?.length == 0 ? <div><h5 className="text-center">Applicant Added to Any Requisition by HR</h5><h6 className="fw-normal text-muted text-center mb-3">Total Candidates: 0</h6>{htmlContent}</div> : <HRCandidateChart data={reportsdata} type={reportData} totalcount={totalAdded} />}
                        {/* <div className='row g-3'>
                            <div className='col-md-6'>
                                {createdData?.length == 0 ? <div><h5 className="text-center">Applicant Added to Any Requisition by HR</h5><h6 className="fw-normal text-muted text-center mb-3">Total Candidates: 0</h6>{htmlContent}</div> : <HRCandidateChart data={reportsdata} type={reportData} totalcount={totalAdded} />}
                            </div>
                            <div className='col-md-6'>
                                {notinterviewscheduled?.length == 0 ? <div><h5 className="text-center">Applicants with Not Scheduled Interviews</h5><h6 className="fw-normal text-muted text-center mb-3">Total Candidates: 0</h6>{htmlContent}</div> : <Interviewnotscheduled data={notinterviewscheduled} totalcount={countnotscheduled} />}
                            </div>
                        </div> */}
                    </div>
                    <div className="card px-3 p-xl-4 py-4 mt-3">
                        <div><h5 className="text-center">Interview Medium</h5><h6 className="fw-normal text-muted text-center mb-3">Total Candidates: {totalinterviewmed >= 1 ? totalinterviewmed : 0}</h6></div>{totalinterviewmed >= 1 ? <InterviewMediumReport data={interviewMedium} /> : htmlContent}
                    </div>
                    {/* {registeredData?.length == 0 ? <div><h5 className="text-center">Applicant Self-Registered on NM-Interview Following HR Engagement</h5>{htmlContent}</div> : <RegisteredCandidateChart data={reportsdata}  totalcount={totalRegis} />} */}
                    <div className="card px-3 p-xl-4 py-4 mt-3">
                        <div className='row g-3'>
                            <div className='col-md-6'>
                                {registeredData?.length == 0 ? <div><h5 className="text-center">Applicant Self-Registered on NM-Interview Following HR Engagement</h5><h6 className="fw-normal text-muted text-center mb-3">Total Candidates: 0</h6>{htmlContent}</div> : <RegisteredCandidateChart data={reportsdata} totalcount={totalRegis} />}
                            </div>
                            <div className='col-md-6'>
                                {notinterviewscheduled?.length == 0 ? <div><h5 className="text-center">Applicants with Not Scheduled Interviews</h5><h6 className="fw-normal text-muted text-center mb-3">Total Candidates: 0</h6>{htmlContent}</div> : <Interviewnotscheduled data={notinterviewscheduled} totalcount={countnotscheduled} />}
                            </div>
                        </div>
                    </div>
                    {/* <div className="card px-3 p-xl-4 py-4 mt-3">
                        {registeredData?.length == 0 ? <div><h5 className="text-center">Applicant Self-Registered on NM-Interview Following HR Engagement</h5><h6 className="fw-normal text-muted text-center mb-3">Total Candidates: 0</h6>{htmlContent}</div> : <RegisteredCandidateChart data={reportsdata} totalcount={totalRegis} />}
                    </div> */}
                    <div className="card px-3 p-xl-4 py-4 mt-3">
                        {scheduleData?.length == 0 ? <div><h5 className="text-center">Applicants with Interviews Scheduled Through NM-Interview</h5><h6 className="fw-normal text-muted text-center mb-3">Total Candidates: 0</h6>{htmlContent}</div> : <InterviewScheduledChart data={reportsdata} totalcount={totalSchedule} />}
                    </div>
                    {/* added27032024 */}
                    <div className="card p-3 mt-3">
                        <div className='row'>
                            <div className='col-sm-6'>
                                <div><h5 className="text-center pt-2 pb-1">Applicants Completed Interview on Scheduled Date</h5><h6 className="text-center pb-3">Total Candidates: {completedCount >= 1 ? completedCount : 0}</h6></div>{allinterviewdatas?.length >= 1 ? <InterviewGivenonScheduled data={allinterviewdatas} /> : htmlContent}
                            </div>
                            <div className='col-sm-6'>
                                <div><h5 className="text-center pt-2 pb-1">Applicants Completed Interview not on Scheduled Date</h5><h6 className="text-center pb-3">Total Candidates: {totalboth >= 1 ? totalboth : 0}</h6></div>{allHRinterview?.length >= 1 ? <HRInterview data={allHRinterview} /> : htmlContent}
                            </div>
                        </div>
                    </div>
                    {/* added27032024 */}
                    <div className="card px-3 p-xl-4 py-4 mt-3">
                        <div className='row g-3'>
                            <div className='col-md-12'>
                                {getnminterviewnotscheduled?.length == 0 ? <div><h5 className="text-center">NM Applicants with Not Scheduled Interviews</h5><h6 className="fw-normal text-muted text-center mb-3">Total Candidates: 0</h6>{htmlContent}</div> : <NMInterviewnotScheduled data={getnminterviewnotscheduled} totalcount={countNMnotscheduled} />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}