import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import imgDefault from '../assets/Avatar.png';
import { getAuth, secauth } from '../firebaseConfig';
import axios from 'axios';
import {
  signOut,
  onAuthStateChanged,
} from 'firebase/auth';
import { useMyContext } from '../context/MyContext';


export const MyAccounts = () => {
  const { values, handleChange } = useMyContext()
  let navigate = useNavigate();
  const auth = getAuth();
  const [user, setUser] = useState({});
  const [userType, setUserType] = useState(null);
  const [userInfoLoaded, setUserInfoLoaded] = useState(true);
  const [accessToken, setAccessToken] = useState(null);
  const [lastLogin, setLastLogin] = useState(null);

  const getUserRole = (idToken, email) => {
    var data = JSON.stringify({
      "operation": "getUsers",
      "email": email
    });
    var headers = {
      'Authorization': `Bearer ${idToken}`,
      'Content-Type': 'application/json'
    };
    axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/dashboard/get-my-details`, data, { headers })
      .then(function (response) {
        let userDatares = response.data;
        let data = userDatares.data?.length > 0 ? userDatares.data?.[0] : {};
        // localStorage.setItem("userRole", data?.userType || null);
        localStorage.setItem("userRole", response?.data?.data.userType || null);
        // setUserType(data?.userType || null);
        setUserType(response?.data?.data.userType || null);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(secauth, async (currentUser) => {

      if (currentUser) {
        const idToken = await currentUser.getIdToken();
        getUserRole(currentUser?.accessToken, currentUser?.email);
        let lastSignGMT = currentUser?.metadata?.lastSignInTime;
        if (lastSignGMT) {
          let myDate = new Date(lastSignGMT);
          setLastLogin(myDate.toLocaleString());
        }
        const userInfo = {
          uid: currentUser?.uid,
          email: currentUser?.email,
          displayName: currentUser?.displayName,
          accessToken: currentUser?.accessToken,
          idToken: currentUser?.accessToken,
        };
        setUser(userInfo);
      }
      else {
        // console.log("not logged in");
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);


  useEffect(() => {
    let unsubscribe;
    try {
      unsubscribe = onAuthStateChanged(auth, (currentUser) => {
        setAccessToken(currentUser?.accessToken);
        if (currentUser?.accessToken && currentUser?.email)
          getUserRole(currentUser?.accessToken, currentUser?.email);
        let lastSignGMT = currentUser?.metadata?.lastSignInTime;
        if (lastSignGMT) {
          let myDate = new Date(lastSignGMT);
          setLastLogin(myDate.toLocaleString());
        }

        setUser(currentUser);
        setUserInfoLoaded(false)
      });
    } catch (error) {
      // console.log("error");  
    } finally {
      // setUserInfoLoaded(false);  

    }

    return () => {
      unsubscribe();
    };
  }, []);

  // google
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(secauth, async (currentUser) => {

      if (currentUser) {
        const idToken = await currentUser.getIdToken();
        getUserRole(currentUser?.accessToken, currentUser?.email);
        let lastSignGMT = currentUser?.metadata?.lastSignInTime;
        if (lastSignGMT) {
          let myDate = new Date(lastSignGMT);
          setLastLogin(myDate.toLocaleString());
        }
        const userInfo = {
          uid: currentUser?.uid,
          email: currentUser?.email,
          displayName: currentUser?.displayName,
          accessToken: currentUser?.accessToken,
          idToken: currentUser?.accessToken,
        };
        setUser(userInfo);
      }
      else {
        // console.log("not logged in");
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);
  // google

  const memberListing = (e) => {
    e.preventDefault();
    navigate('/members-list', { state: "HR" });
  }

  // getCurrentPathname
  useEffect(() => {
    const currentUrl = window.location.href;
    const currentPath = window.location.pathname
    handleChange("getPagePath",currentPath)
  }, []);
  // getCurrentPathname

  return (
    <div className='myAccount_page py-3'>
      <div className='container-fluid'>
        <div className="heading-wrapper text-center mx-auto mb-2 pb-1" style={{ maxWidth: '700px' }}>
          <div className="main-heading fs-3 fw-semibold">My Account</div>
          <div className="subheading text-muted small"></div>
        </div>
        <div className="row g-3">
          <div className="col-md-6 col-xl-4">
            <div className="card border-0 h-100 text-center">
              <div className="card-body py-4 px-3">
                <div className="user-img d-flex align-items-center justify-content-center rounded-circle border border-2 shadow overflow-hidden mx-auto mb-3" style={{ height: "100px", width: "100px" }}>
                  {/* <div className='user-shortname fs-3'>VA</div> */}
                  <img className="img-fluid w-100 h-100" src={imgDefault} alt="User" />
                </div>
                <div className="name fs-5 fw-medium text-capitalize">{user?.displayName}</div>
                <div className="d-flex justify-content-center gap-2 text-muted small mb-2"><i className="bi bi-envelope"></i>{user?.email}</div>
                <div className="w-100 mb-3">
                  <span className="badge fw-medium rounded-pill text-bg-secondary px-3 py-2" style={{ letterSpacing: '1px' }}>Last Login-{lastLogin}</span>
                </div>
                <div className="btn-wrapper">
                  <Link to={'#'} type='button' className="btn btn-success focus-ring focus-ring-success py-2 px-4"><i className="bi bi-box-arrow-right me-1" ></i> Sign out everywhere</Link>
                </div>
              </div>
            </div>
          </div>



          {userType && userType == "SA" || userType == "SSA" ? <div className="col-lg-4 col-md-6 mb-4 mb-md-0">
            <div className="card border-0 h-100 text-center">
              <div className="card-body d-flex flex-column justify-content-center p-3 p-xl-4">
                <div className="icon d-flex align-items-center justify-content-center bg-primary bg-opacity-25 border border-2 border-primary rounded-circle mx-auto mb-3" style={{ width: '50px', height: '50px' }}>
                  <i className="bi bi-people-fill fs-4 text-primary"></i>
                </div>
                <div className="fs-5 fw-medium mb-1">Add HR Team</div>
                <div className="text-muted small">You can add HR members from this button.</div>
                <div className="btn-wrapper mt-3">
                  <Link href={"#"} type='button' className="btn btn-primary focus-ring focus-ring-primary py-2 px-4" onClick={memberListing}>Add Member <i className="bi bi-chevron-right"></i></Link>
                </div>
              </div>
            </div>
          </div> : null}

          {/* <div className="col-lg-4 col-md-6">
                        <div className="card border-0 h-100 text-center">
                            <div className="card-body d-flex flex-column justify-content-center p-3 p-xl-4">
                                <div className="icon d-flex align-items-center justify-content-center bg-primary bg-opacity-25 border border-2 border-primary rounded-circle mx-auto mb-3" style={{ width: '50px', height: '50px' }}>
                                    <i className="bi bi-person-plus-fill fs-4 text-primary"></i>
                                </div>
                                <div className="fs-5 fw-medium mb-1">Add other resource</div>
                                <div className="text-muted small">(Client Account Manager) role, as they represent the company's front face and are accountable for promptly joining live calls once clients begin filling out lead forms.</div>
                                <div className="btn-wrapper mt-3">
                                    <Link href={"#"} type='button' className="btn btn-primary focus-ring focus-ring-primary py-2 px-4" onClick={memberListing}>Add Member <i className="bi bi-chevron-right"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div> */}
        </div>
      </div>
    </div>
  )
}
