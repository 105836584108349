import React, { useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';

export const SubmitRequisitionModal = ({ show, close, isCreateRequisition }) => {
    const [isChecked, setIsChecked] = useState(false);

    // Function to handle checkbox change event
    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    const submitReview = (e) => {
        e.preventDefault();
        setIsChecked(false)
        isCreateRequisition()
        close()
    }

    return (
        <Modal show={show} centered onHide={close} size='xl' scrollable backdrop="static" keyboard={false} dialogClassName="mw-auto" contentClassName='border-0 overflow-hidden'>
            <Modal.Body className="text-body px-md-4 px-lg-5 py-4">
                <div className="fs-3 fw-bold text-dark lh-sm mt-3 mb-3">Submit Requisition</div>
                <Form className="mb-3">
                    <div className='fw-medium mb-3'>
                        We recommend thoroughly reviewing all details before submitting your application. You must include a detailed job description,
                        with a strong focus on the technologies you expect the candidate to work with.
                    </div>
                    <div className='fw-medium mb-4'>
                        Once submitted, the application will be forwarded to the higher manager for approval.
                        Be cautious, as there should be no mistakes in the requisition from your end.
                    </div>
                    <Form.Check id="checkCondition" className="text-dark ms-2">
                        <Form.Check.Input type="checkbox" className="fs-18 border border-2 border-dark rounded-1 shadow-none" id="checkCondition" checked={isChecked} onChange={handleCheckboxChange} />
                        <Form.Check.Label>
                            Yes, I have reviewed all the details of my requisition thoroughly. I have included everything I need,
                            and there are no mistakes in English or any missing information as per the requirements.
                        </Form.Check.Label>
                    </Form.Check>
                    <div className="btn_wrapper d-flex justify-content-center gap-3 mt-4 pt-xl-3">
                        <Button type="button" variant="secondary" className="fs-18 py-2 px-5" onClick={close}>Cancel</Button>
                        <Button type="submit" variant="success" className="fs-18 py-2 px-5" disabled={!isChecked} onClick={submitReview}>Submit</Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )
}
